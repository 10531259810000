import {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
} from 'react';
import { getCookie, setCookie, deleteUserCookiesFromBrowser } from '@utils/api';

import { baseURL } from '@utils/api';

const TokenContext = createContext({});

export const ACCESS_TOKEN = 'bd_access_token';
export const REFRESH_TOKEN = 'bd_refresh_token';

// set and get the tokens from cookies
export const setToken = token => {
	setCookie(ACCESS_TOKEN, token);
};
export const getToken = () => {
	const token = getCookie(ACCESS_TOKEN);
	return token;
};
export const setRefreshToken = token => {
	setCookie(REFRESH_TOKEN, token);
};
export const getRefreshToken = () => {
	return getCookie(REFRESH_TOKEN);
};

export const TokenProvider = ({ children }) => {
	const [worker, setWorker] = useState(null);

	const decodeToken = token => {
		if (!token) {
			console.warn('Token is undefined or null');
			return null;
		}

		try {
			const jwtPayload = token.split('.')[1];
			if (!jwtPayload) {
				console.warn('Invalid token format');
				return null;
			}

			const payload = base64UrlDecode(jwtPayload);
			return JSON.parse(payload);
		} catch (error) {
			console.warn('Error decoding token:', error);
			handleTokenError();
			return null;
		}
	};

	const handleTokenError = () => {
		deleteUserCookiesFromBrowser();

		// Check if we're already on the login page to avoid redirect loops
		const currentURL = window.location.pathname;
		const loginURL = '/login';

		if (currentURL !== loginURL) {
			window.location = loginURL;
		}
	};

	const refreshAuth = async (retryCount = 3) => {
		const originalAccessToken = getToken();
		const refreshToken = getRefreshToken();
		if (!originalAccessToken || !refreshToken) return;

		try {
			const response = await fetch(baseURL + '/auth/refresh', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					access_token: originalAccessToken,
					refresh_token: refreshToken,
				}),
			});

			if (response?.status === 200) {
				const data = JSON.parse(await response.text());
				const newAccessToken = data.token ?? null;

				if (!newAccessToken) {
					throw new Error(
						'Did not receive a new access token from the refresh token request!'
					);
				}

				setToken(newAccessToken);
			} else {
				handleTokenError();
			}
		} catch (error) {
			if (retryCount > 0) {
				await new Promise(resolve => setTimeout(resolve, 1000));
				return refreshAuth(retryCount - 1);
			}
			console.error('Error refreshing user token', error);
			deleteUserCookiesFromBrowser();
			throw error;
		}
	};

	const createWorker = useCallback(() => {
		setWorker(prev => {
			if (prev) clearTimeout(prev);

			const token = getToken();
			if (!token) {
				return null;
			}

			const decodedToken = decodeToken(token);
			if (!decodedToken?.exp) {
				console.warn('Token missing expiration time');
				return null;
			}

			const timeUntilExpiry = decodedToken.exp * 1000 - Date.now();
			if (timeUntilExpiry <= 0) {
				console.info('Token already expired');
				refreshAuth();
				return null;
			}

			return setTimeout(async () => {
				refreshAuth();
			}, timeUntilExpiry);
		});
	}, []); // Empty dependency array since all dependencies are stable

	const run = useCallback(async () => {
		const token = getToken();
		if (!token) {
			return;
		}

		const payload = decodeToken(token);
		if (!payload?.exp) {
			console.warn('Invalid token payload or missing expiration');
			return;
		}

		const currentTime = Date.now() / 1000;

		if (payload.exp > currentTime) {
			if (worker) return;
			await refreshAuth();
			createWorker();
			return;
		}

		if (payload.exp <= currentTime) {
			await refreshAuth();
			createWorker();
			return;
		}
	}, [worker, createWorker]);

	useEffect(() => {
		run();

		// Check every 5 minutes
		const intervalCheck = setInterval(
			() => {
				console.info('Checking token expiration');
				run();
			},
			5 * 60 * 1000
		);

		return () => {
			if (worker) {
				clearTimeout(worker);
				setWorker(null);
			}
			clearInterval(intervalCheck);
		};
	}, [run]);

	const value = {
		setToken,
		getToken,
		setRefreshToken,
		getRefreshToken,
		startWorker: () => refreshAuth(),
	};

	return (
		<TokenContext.Provider value={value}>{children}</TokenContext.Provider>
	);
};

export const useToken = () => {
	const context = useContext(TokenContext);
	if (!context) {
		throw new Error('useToken must be used within a TokenProvider');
	}
	return context;
};

// helpers
function b64DecodeUnicode(str) {
	return decodeURIComponent(
		window.atob(str).replace(/(.)/g, (m, p) => {
			let code = p.charCodeAt(0).toString(16).toUpperCase();
			if (code.length < 2) {
				code = '0' + code;
			}
			return '%' + code;
		})
	);
}

function base64UrlDecode(str) {
	if (typeof str !== 'string' && str == null) {
		throw new Error('Input must be a non-null string');
	}

	let output = String(str).replace(/-/g, '+').replace(/_/g, '/');
	switch (output.length % 4) {
		case 0:
			break;
		case 2:
			output += '==';
			break;
		case 3:
			output += '=';
			break;
		default:
			throw new Error('Base64 string is not of the correct length');
	}

	try {
		return b64DecodeUnicode(output);
	} catch (err) {
		return window.atob(output);
	}
}
