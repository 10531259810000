import { useFormContext } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

import { useNewProject } from '@contexts/NewProject.context';
import { StyledModal } from '@components/modal/ModalStyle';
import ProgressBar from 'react-bootstrap/ProgressBar';

const SubHeading = styled.h5`
	margin-bottom: 0.5rem;
`;

const UploadInfo = styled.div`
	font-size: 1rem;
	font-weight: 300;
	margin-top: 0.75rem;
	color: #b0b0b0;
`;

const UploadingProjectModal = ({}) => {
	const { progress, isSubmitting } = useNewProject();
	const { getValues } = useFormContext();
	const projectName = getValues('projectName');

	if (!isSubmitting) return null;
	return (
		<StyledModal show={true} centered size="lg">
			<Modal.Header>
				<Modal.Title>Project: {projectName}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<SubHeading>
					Uploading ({Math.floor(progress == -1 ? 0 : progress)}%)
				</SubHeading>
				<ProgressBar now={progress} variant="success" />

				<UploadInfo>
					Please do not close or refresh the page while your data is
					being uploaded.
				</UploadInfo>
			</Modal.Body>
		</StyledModal>
	);
};
export default UploadingProjectModal;
