import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { StyledModal } from '../ModalStyle';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import ModelTypeField from './components/ModelTypeField';
import ClassesFields from './components/ClassesFields';
import Alert from '@components/alert/Alert';
import ControlledInput from '@components/form/ControlledInput';

import { PROJECT_MODE, MODEL_TYPE } from '@utils/constants';
import { useToast } from '@contexts/Toast.context';
import { newTask } from '@utils/api';

const CreateModelModal = ({ setShowModal, onSuccess, projectMode }) => {
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const [isLoading, setIsLoading] = useState(false);

	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			modelType: '',
			modelName: '',
			description: '',
			classes: [{ label: '', object_size: '' }],
		},
	});
	const modelType = watch('modelType');
	const classes = watch('classes');

	const subtitleText = () => {
		switch (projectMode) {
			case PROJECT_MODE.ORTHOPHOTO:
				return 'For Orthophoto Projects';
			case PROJECT_MODE.SINGLE_IMAGE:
				return 'For Single Image Projects';
			default:
				return '';
		}
	};

	const {
		mutate: createModel,
		isLoading: createModelLoading,
		error: createModelError,
	} = useMutation({
		mutationFn: task => {
			setIsLoading(true);
			return newTask(task);
		},
		onSuccess: async res => {
			await queryClient.invalidateQueries('tasks');
			onSuccess && onSuccess(res);
			addToast({
				id: `create_custom_model-${new Date().getTime()}`,
				title: `Model "${res.description}" created and selected`,
				bg: 'success',
			});
			setIsLoading(false);
			setShowModal(false);
		},
		onError: error => {
			setIsLoading(false);
			console.error('Could not create new model', error);
		},
	});

	const onSubmit = data => {
		const { modelName, description, classes } = data;
		createModel({
			description: modelName, // Iknow this is confusing, but the API expects modelName to be description
			hover_description: description,
			classes,
			segmentation: modelType === MODEL_TYPE.SEGMENTATION,
			image_mode: projectMode,
		});
	};

	return (
		<StyledModal show={true} onHide={() => setShowModal(false)} size="lg">
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>
					<div>Creating a Custom Model</div>
					<div className="h6 text-muted">{subtitleText()}</div>
				</Modal.Title>
			</Modal.Header>

			<Form onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>
					<div className="d-grid gap-4">
						<ModelTypeField control={control} />

						{modelType && (
							<>
								{/* <ModelNameField control={control} /> */}

								<div className="d-grid gap-4 my-4">
									<ControlledInput
										control={control}
										controllerProps={{
											rules: {
												required: true,
											},
										}}
										name="modelName"
										label="Model Name"
										id="model-name-input"
										type="text"
										required
										autoFocus
										noWrap
									/>

									<ControlledInput
										control={control}
										name="description"
										label="Model Description"
										id="model-description-input"
										type="text"
										maxLength="60"
										helperText="Short description of your model (max 60 characters)."
										noWrap
									/>
								</div>

								<ClassesFields
									control={control}
									errors={errors}
									modelType={modelType}
								/>
							</>
						)}
					</div>

					{createModelError && (
						<Alert
							variant="danger"
							heading="Failed to create model"
							className="mt-4">
							<p>{createModelError.message}</p>
						</Alert>
					)}
				</Modal.Body>
				<Modal.Footer>
					<div>
						{classes?.length > 1 && (
							<p className="small text-muted mb-2">
								Adding multiple labels automatically makes this
								a multimodel.
							</p>
						)}
						<div className="d-flex gap-2 justify-content-end">
							<Button
								variant="secondary"
								onClick={() => {
									setShowModal(false);
								}}>
								Cancel
							</Button>
							<Button
								type="submit"
								variant="success"
								disabled={isLoading || !modelType}>
								{isLoading || createModelLoading
									? 'Creating model...'
									: 'Create Model'}
							</Button>
						</div>
					</div>
				</Modal.Footer>
			</Form>
		</StyledModal>
	);
};
export default CreateModelModal;
