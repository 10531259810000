import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Dropdown from 'react-bootstrap/Dropdown';

import {
	AiOutlineMore,
	AiFillDelete,
	AiOutlineCalculator,
} from 'react-icons/ai';
import { deleteMasterFeatures } from '@utils/api';
import { useToast } from '@contexts/Toast.context';
import { useProject } from '@contexts/Project.context';

const DropdownToggle = styled(Dropdown.Toggle)`
	background: none;
	padding: 0;
	font-size: 1.2em;
	&.dropdown-toggle::after {
		display: none;
	}
`;

const ModelResultDropdown = ({ setHexagonColorFilterActive }) => {
	const queryClient = useQueryClient();

	const iconClass = 'mt-1 mb-2 me-2';
	const { addToast } = useToast();

	const { project, pickedTask, hexagonInfo, isDemo, dispatch } = useProject();

	const project_uuid = project?.uuid;
	const hexagonsExist = !!hexagonInfo;

	const deleteMutation = useMutation({
		mutationFn: ({ project_uuid, model_uuid }) => {
			return deleteMasterFeatures(project_uuid, model_uuid);
		},
		onSuccess: res => {
			console.log(res);

			addToast({
				id: `delete_model_result_success-${new Date().getTime()}`,
				title: 'Model result deleted successfully',
				bg: 'success',
			});

			queryClient.invalidateQueries({
				queryKey: [pickedTask.model_uuid],
			});
			queryClient.invalidateQueries({ queryKey: ['models_detected_on'] });

			dispatch({
				type: 'setFeatures',
				payload: [],
			});
		},
		onError: error => {
			console.error('Error deleting model result', error);
			addToast({
				id: `delete_model_result_error-${new Date().getTime()}`,
				title: 'Error deleting model result',
				bg: 'danger',
			});
		},
	});

	const handleDelete = async () => {
		dispatch({
			type: 'setConfirmModalContent',
			payload: {
				title: 'Confirm Deletion',
				message: (
					<p>
						Are you sure you want to delete the model result for{' '}
						<strong>{pickedTask?.description}</strong>
					</p>
				),
				onConfirm: () =>
					deleteMutation.mutate({
						project_uuid,
						model_uuid: pickedTask.model_uuid,
					}),
			},
		});
	};

	return (
		<>
			<Dropdown>
				<DropdownToggle variant="dark">
					<AiOutlineMore />
				</DropdownToggle>
				<Dropdown.Menu variant="dark">
					{hexagonsExist && (
						<>
							<Dropdown.Item
								as="button"
								onClick={() => {
									setHexagonColorFilterActive(true);
									dispatch({
										type: 'setToolBarVisible',
										payload: false,
									});
								}}>
								<AiOutlineCalculator
									className={iconClass}
									aria-hidden="true"
								/>
								Color Filter
							</Dropdown.Item>

							<Dropdown.Divider />
						</>
					)}

					<Dropdown.Item
						as="button"
						onClick={handleDelete}
						disabled={isDemo}>
						<AiFillDelete
							className={iconClass}
							aria-hidden="true"
						/>
						Delete Results
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};
export default ModelResultDropdown;
