import PopoverPicker from '@components/popoverPicker/PopoverPicker';
import styled from 'styled-components';
import Checkbox from '../sidebars/sidebarElements/Checkbox';
import { useProject } from '@contexts/Project.context';
import useDebounce from '@hooks/useDebounce';

const FilterContainer = styled.div`
	padding: 20px 20px 200px;
`;
const SidebarRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 50px;
	&:hover {
		cursor: pointer;
		color: white;
	}
`;
const PopoverHolder = styled.div`
	position: relative;
`;

const ModifyTrainingData = () => {
	const { trainingDataClassNameInfo, dispatch } = useProject();
	if (!trainingDataClassNameInfo) return null;

	const dispatchVisibility = (
		trainingDataClassNameInfo,
		className,
		visible
	) => {
		dispatch({
			type: 'setTrainingDataClassNameInfo',
			payload: {
				...trainingDataClassNameInfo,
				[className]: {
					...trainingDataClassNameInfo[className],
					visible: visible,
				},
			},
		});
	};

	return (
		<FilterContainer>
			{Object.entries(trainingDataClassNameInfo).map(
				([className, { color, visible }], index) => (
					<div key={`td-${index}`}>
						<SidebarRow>
							<Checkbox
								canEdit={false}
								label={
									className === 'null'
										? 'Unlabeled'
										: className
								}
								defaultState={visible}
								handleCheck={() => {
									dispatchVisibility(
										trainingDataClassNameInfo,
										className,
										true
									);
								}}
								handleUncheck={() => {
									dispatchVisibility(
										trainingDataClassNameInfo,
										className,
										false
									);
								}}
							/>
							<PopoverHolder>
								<ModifyPoint
									className={className}
									color={color}
								/>
							</PopoverHolder>
						</SidebarRow>

						<hr className="my-3" />
					</div>
				)
			)}
		</FilterContainer>
	);
};

export default ModifyTrainingData;

const ModifyPoint = ({ className, color }) => {
	if (!color) return null;

	const { trainingDataClassNameInfo, dispatch } = useProject();

	const changeColor = useDebounce(color => {
		dispatch({
			type: 'setTrainingDataClassNameInfo',
			payload: {
				...trainingDataClassNameInfo,
				[className]: {
					...trainingDataClassNameInfo[className],
					color: color,
				},
			},
		});
	}, 300); // 300ms debounce time

	return (
		<PopoverPicker
			color={color}
			onChange={changeColor}
			popDirection="right"
		/>
	);
};
