import { useEffect, useRef, useState } from 'react';
import { Polygon } from 'ol/geom';
import Feature from 'ol/Feature';
import { Spinner } from 'react-bootstrap';
import { Style, Fill, Stroke } from 'ol/style';
import styled, { keyframes } from 'styled-components';

import { useNewProject } from '@contexts/NewProject.context';
import AlertComponent from '@components/alert/Alert';
import { getMap } from './layers';
import {
	smoothFootprintCorners,
	getFootprintExtent,
	formatArea,
	formatOverlap,
} from '../../utils/helpers';

const Alert = styled(AlertComponent)`
	grid-column: 1 / -1;
	margin: 0;
	z-index: 3;
`;

const StyledProjectInfo = styled.div`
	padding-block: 0.5rem;
	padding-inline: 1rem;
	border-radius: 0.375rem;

	background: ${({ theme }) => theme.colors.modal};
`;

const Wrapper = styled.div`
	position: relative;
	border-radius: 0.375rem;
	overflow: hidden;

	.ol-zoom {
		top: auto;
		bottom: 0.5rem;
		left: 0.5rem;
	}
`;

const CoverOverlay = styled.div`
	display: grid;
	place-items: center;

	background: #000000bb;

	position: absolute;
	overflow: hidden;
	z-index: 1;
	inset: 0;
`;

const StyledSpinner = styled(Spinner)`
	scale: 3;
	font-size: 0.8rem;
`;

const slideIn = keyframes`
  from {
    transform: translateY(-.25rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ContentOverlayWrapper = styled.div`
	position: absolute;
	overflow: hidden;
	inset: 0;
	z-index: 2;
	pointer-events: none;

	height: fit-content;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: min-content min-content;
	align-items: start;
	gap: 1rem;
	column-gap: 40px;
	padding: 0.5rem;

	animation: ${slideIn} 0.4s ease-out;

	& * {
		pointer-events: auto;
	}
`;

const isMultiFootprint = footprint =>
	footprint.every(item => Array.isArray(item));

const convertFootprintToPolygon = footprint =>
	new Polygon([footprint.map(({ x, y }) => [x, y])]);

const addFootprintFeatures = (vectorSource, footprintsArray) =>
	footprintsArray.forEach(footprint => {
		const smoothedFootprint = smoothFootprintCorners(
			footprint,
			0.025,
			0.05
		);

		vectorSource.addFeature(
			new Feature({
				featureType: 'footprintPolygon',
				geometry: convertFootprintToPolygon(smoothedFootprint),
				style: new Style({
					stroke: new Stroke({
						color: '#000000bb',
						width: 2,
					}),

					fill: new Fill({
						color: '#00000066',
					}),
				}),
			})
		);
	});

const formatWarning = warning => {
	const splitWarning = warning.split('Consider removing duplicates.');

	if (splitWarning.length === 1) {
		// The phrase "Consider removing duplicates." does not exist in the warning
		return warning;
	}

	const formattedWarning = splitWarning.map((part, index) => {
		if (index === splitWarning.length - 1) {
			return part; // Don't add <br /> after the last part
		}
		return (
			<span key={index}>
				{part}Consider removing duplicates.
				<br />
			</span>
		);
	});

	return formattedWarning;
};

const Warning = ({ show, title, children }) => {
	if (!show) return null;
	return (
		<Alert heading={title} variant="warning">
			{children}
		</Alert>
	);
};

const ProjectInfo = ({ label, info }) => {
	if (!info) return null;
	return (
		<StyledProjectInfo>
			<span className="text-muted">{label}</span>
			{info}
		</StyledProjectInfo>
	);
};

const MapComponent = () => {
	const { exif, isTif } = useNewProject();
	const {
		isLoading: exifIsLoading,
		error: exifError,
		exifFootprint,
	} = exif ?? {};
	let { footprint_polygon: footprint } = exifFootprint ?? {};
	const {
		footprint_area: footprintArea,
		overlap_score: overlapScore,
		warning,
	} = exifFootprint ?? {};

	const area = footprintArea ? formatArea(footprintArea) : null;
	const overlap = isFinite(overlapScore) ? formatOverlap(overlapScore) : null;

	const mapRef = useRef(null);
	const [map, setMap] = useState(null);
	const isFirstRender = useRef(true);

	const [animateKey, setAnimateKey] = useState(0);

	useEffect(() => {
		// Add map on first render
		if (isFirstRender.current) {
			setMap(getMap(mapRef.current));
			isFirstRender.current = false;
		}

		// Remove map on unmount
		return () => {
			try {
				if (!map) return;
				map.dispose();
				map.setTarget(null);
			} catch (err) {
				console.warn('Could not remove map on cleanup', err);
			}

			isFirstRender.current = true;
			setMap(null);
		};
	}, []);

	useEffect(() => {
		setAnimateKey(prevKey => prevKey + 1);

		// If no map or no footprint, reset extent to default and return
		if (!map || !footprint?.length) {
			map?.getView().fit([
				-20037508.342789244, -20037508.342789244, 20037508.342789244,
				20037508.342789244,
			]);
			return;
		}

		// Get the vector source and view from the map
		const vectorSource = map.getLayers().getArray()[1].getSource();
		const view = map.getView();

		// convert footprint to array with 1 footprint if single footprint
		if (!isMultiFootprint(footprint)) footprint = [footprint];

		// Set the view to the extent of the footprint polygon + padding
		const extent = getFootprintExtent(footprint);
		const topPadding = (area ?? overlap) ? 80 : 20;
		view.fit(extent, { padding: [topPadding, 20, 20, 20] });

		// Add the footprint polygon to the map
		addFootprintFeatures(vectorSource, footprint);

		// Cleanup
		return () => {
			vectorSource.clear();
		};
	}, [exifFootprint, map]);

	return (
		<Wrapper ref={mapRef}>
			{!exifFootprint && (
				<CoverOverlay>
					{exifIsLoading && (
						<StyledSpinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</StyledSpinner>
					)}
				</CoverOverlay>
			)}

			<ContentOverlayWrapper key={animateKey}>
				<Warning title="Warning" show={exifError && !footprintArea}>
					We could not preprocess{' '}
					{isTif ? 'this orthophoto file' : 'the images'}. This could
					cause the project to fail. Despite this warning you can
					create the project, but consider the implications first.
				</Warning>

				<Warning title="Warning" show={warning}>
					{warning && formatWarning(warning)}
				</Warning>

				<ProjectInfo label="Area: " info={area} />
				<ProjectInfo label="Overlap Score: " info={overlap} />
			</ContentOverlayWrapper>
		</Wrapper>
	);
};
export default MapComponent;
