import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import {
	show as showIntercom,
	hide as hideIntercom,
	onHide as onIntercomHide,
	onShow as onIntercomShow,
} from '@intercom/messenger-js-sdk';
import Button from 'react-bootstrap/Button';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { useAuth } from '@contexts/User.context';
import { getCookie } from '@utils/api';
import { useToast } from '@contexts/Toast.context';
import { openCookieConsent } from '@utils/helpers';

const INTERCOM_APP_ID = import.meta.env.VITE_APP_INTERCOM_APP_ID;

const StyledChatbotToggle = styled(Button)`
	&[aria-pressed='true'] {
		background-color: var(--bs-btn-hover-bg);
	}
`;

// Possible Intercom Methods -> https://developers.intercom.com/installing-intercom/web/methods

const ChatbotToggle = () => {
	const { userCookieConsent } = useAuth();
	const hasIntercomConsent = userCookieConsent?.includes('preferences:true');

	const [isCookieSet, setIsCookieSet] = useState(false);

	// Function to check if the cookie is set / Intercom is initialized

	const checkCookie = useCallback(
		(attempts = 5) => {
			const cookieName = `intercom-device-id-${INTERCOM_APP_ID}`;
			const cookieExists = getCookie(cookieName);

			if (cookieExists && hasIntercomConsent) {
				setIsCookieSet(true);
				return; // Stop checking if cookie exists
			} else if (attempts > 0) {
				setTimeout(() => checkCookie(attempts - 1), 500);
			} else {
				setIsCookieSet(false);
			}
		},
		[INTERCOM_APP_ID, hasIntercomConsent]
	);

	useEffect(() => {
		checkCookie();
	}, [checkCookie]);

	if (!hasIntercomConsent) return <MissingConsent />;

	// If no cookie is set, do not render the chatbot toggle button, because Intercom will not work
	if (!isCookieSet) return;

	return <HasConsent />;
};

export default ChatbotToggle;

const MissingConsent = () => {
	const { addToast } = useToast();
	const cookiesNotAllowedWarning = () => {
		addToast({
			title: 'Cookie consent is required to use the support chat',
			message: (
				<a href="#" onClick={openCookieConsent} className="inherit">
					Update your cookie preferences by clicking here
				</a>
			),
			bg: 'warning',
			id: `cookie-consent-warning-${new Date().getTime()}`,
		});
	};

	return <ToggleButton onClick={cookiesNotAllowedWarning} isOpen={false} />;
};

const HasConsent = () => {
	const [isOpen, setIsOpen] = useState(false);

	onIntercomHide(() => setIsOpen(false));
	onIntercomShow(() => setIsOpen(true));
	const toggleIntercom = () => (isOpen ? hideIntercom() : showIntercom());

	return <ToggleButton onClick={toggleIntercom} isOpen={isOpen} />;
};

const ToggleButton = ({ onClick, isOpen }) => {
	return (
		<StyledChatbotToggle
			variant="dark"
			title="Support"
			onClick={onClick}
			aria-pressed={isOpen}>
			<BsFillChatDotsFill />
		</StyledChatbotToggle>
	);
};
