import { useState } from 'react';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router';

import {
	AiOutlineDelete,
	AiOutlineEdit,
	AiOutlineMore,
	AiFillFolderOpen,
} from 'react-icons/ai';

import EditModal from './EditModal';
import DeleteModal from './DeleteModal';

import { useAuth } from '@contexts/User.context';
import { billingDateRange } from '@routes/adminRoutes/utils/helpers';

const DropdownToggle = styled(Dropdown.Toggle)`
	background: none;
	padding: 0;
	font-size: 1.2em;
	&.dropdown-toggle::after {
		display: none;
	}
`;

const DropdownMenu = ({ data }) => {
	const { roleAdmin } = useAuth();
	const iconClass = 'mt-1 mb-2 me-2';

	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	return (
		<>
			<Dropdown>
				<DropdownToggle variant="dark">
					<AiOutlineMore />
				</DropdownToggle>
				<Dropdown.Menu variant="dark">
					{roleAdmin && (
						<Dropdown.Item
							as={Link}
							to={
								`/admin/projects/?search=${data.id}&showArchived=true&mode=billing&dateRange=` +
								billingDateRange()
							}>
							<AiFillFolderOpen
								className={iconClass}
								aria-hidden="true"
							/>
							Monthly billing
						</Dropdown.Item>
					)}
					<Dropdown.Item
						as={Link}
						to={`/admin/projects/?search=${data.id}`}>
						<AiFillFolderOpen
							className={iconClass}
							aria-hidden="true"
						/>
						All projects
					</Dropdown.Item>

					<Dropdown.Item
						as="button"
						onClick={() => setShowEditModal(true)}>
						<AiOutlineEdit
							className={iconClass}
							aria-hidden="true"
						/>
						Edit
					</Dropdown.Item>

					<Dropdown.Divider className="border-secondary" />

					<Dropdown.Item
						as="button"
						onClick={() => setShowDeleteModal(true)}>
						<AiOutlineDelete
							className={iconClass}
							aria-hidden="true"
						/>
						Delete
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>

			{showEditModal && (
				<EditModal
					data={data}
					show={showEditModal}
					setShow={() => setShowEditModal(false)}
				/>
			)}

			{showDeleteModal && (
				<DeleteModal
					data={data}
					show={showDeleteModal}
					setShow={() => setShowDeleteModal(false)}
				/>
			)}
		</>
	);
};
export default DropdownMenu;
