import { useInfiniteQuery } from '@tanstack/react-query';
import { Button, Spinner } from 'react-bootstrap';

import { getModelsFromAllUsers } from '@utils/api';
import { PROJECT_MODE } from '@utils/constants';

import { useSearchParams } from 'react-router';
import useSetSearchParams from '@hooks/useSetSearchParams.hook';
import SearchField from '@components/filters/SearchField';
import ModelTypesTabs from '@components/models/ModelTypesTabs';
import ModelsTable from './ModelsTable';

const Models = () => <ModelTypesTabs modelsContentComponent={ModelsContent} />;
export default Models;

const ModelsContent = ({ projectMode }) => {
	const [searchParams] = useSearchParams();

	const search = searchParams.get('search');
	const activeTab = searchParams.get('tab') || PROJECT_MODE.ORTHOPHOTO;

	const { data, hasNextPage, isFetchingNextPage, fetchNextPage, status } =
		useInfiniteQuery({
			queryKey: ['modelsForAllUsers', projectMode, search],
			queryFn: ({ pageParam = 0 }) =>
				getModelsFromAllUsers({
					image_mode: projectMode,
					limit: 50,
					offset: pageParam ?? 0,
					search,
				}),
			initialPageParam: 0,
			getNextPageParam: lastPage => {
				const { total, limit, offset } = lastPage.pagination ?? {
					total: 0,
					limit: 0,
					offset: 0,
				};

				const newOffset = offset + limit;

				if (newOffset < total) {
					return newOffset;
				}

				return undefined;
			},
			enabled: projectMode === activeTab,
		});

	const pages = data?.pages ?? [];
	const models = pages?.flatMap(page => page.models) ?? [];
	const totalModels = pages?.[0]?.pagination?.total ?? 0;

	const isLoading = status === 'loading' || status === 'pending';
	const error = status === 'error';

	return (
		<div className="mt-2">
			<Filters />

			{isLoading && (
				<div>
					<span className="me-2">Loading models</span>{' '}
					<Spinner size="sm" />
				</div>
			)}

			{error && (
				<div className="alert alert-danger">Error loading models</div>
			)}

			{!isLoading && !error && (
				<ModelsTable models={models} loading={isLoading} />
			)}

			<footer className="text-center col-md-12 mt-4">
				<p className="text-muted small">
					Showing {models.length} of {totalModels} models
				</p>
				{hasNextPage && (
					<Button
						variant="success"
						className="col-6"
						disabled={!hasNextPage || isFetchingNextPage}
						onClick={fetchNextPage}>
						<span>Load more</span>{' '}
						{isFetchingNextPage && (
							<Spinner
								animation="border"
								variant="light"
								size="sm"
							/>
						)}
					</Button>
				)}
			</footer>
		</div>
	);
};

const Filters = () => {
	const customSetSearchParams = useSetSearchParams();

	return (
		<>
			<SearchField
				setSearch={val => {
					customSetSearchParams({ search: val });
				}}
				label="Search for model or user"
				searchParamsId="search"
			/>
		</>
	);
};
