import { useCallback, useState, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Style, Text } from 'ol/style';
import { unByKey } from 'ol/Observable';
import { Draw } from 'ol/interaction';

import { useProject } from '@contexts/Project.context';
import { toLonLat } from 'ol/proj';

import { getMasterFeatures } from '@utils/api';
import { useToast } from '@contexts/Toast.context';
import { deleteLayerByCustomId, getLayerByCustomId } from '@utils/map/helpers';
import { createTooltip } from '@utils/map/tooltip.overlay';
import { MODEL_TYPE } from '@utils/constants';

import Checkbox from '../sidebars/sidebarElements/Checkbox';

// Helper function to format confidence
const formatConfidence = confidence => {
	if (confidence == null) return null;
	return confidence == 1
		? `Confidence: 100%`
		: `Confidence: ${(confidence * 100).toFixed(2)}%`;
};

// Helper function to format other properties
const formatProperty = (label, value, unit) => {
	return value != null ? `${label}: ${value.toFixed(2)}${unit}` : null;
};

// Helper function to format coordinates
const formatCoordinates = coordinates =>
	coordinates.map(coord => coord.toFixed(5));

/**
 * Calculates the average coordinates of an array of points.
 *
 * @param {Array<Array<number>>} pointArray - The array of points, where each point is represented as an array of two numbers [x, y].
 * @returns {Array<number>} The average coordinates [x, y].
 */
const getPointAverage = pointArray => {
	const x =
		pointArray.reduce((acc, point) => acc + point[0], 0) /
		pointArray.length;
	const y =
		pointArray.reduce((acc, point) => acc + point[1], 0) /
		pointArray.length;

	return [x, y];
};

/**
 * Retrieves the feature geometry based on the given geometry type.
 *
 * @param {Object} geometry - The geometry object.
 * @returns {Point|null} - The feature geometry or null if the geometry type is unknown.
 */
const getFeatureGeometry = geometry => {
	switch (geometry.type) {
		case 'Point':
			return new Point(geometry.coordinates);

		case 'Polygon':
			return new Point(getPointAverage(geometry.coordinates[0]));

		default:
			console.warn('Unknown geometry type: ', geometry.type);
			return null;
	}
};

/**
 * Retrieves the first detection feature from an array of features.
 *
 * @param {Array} featureArray - The array of features to filter.
 * @returns {Object|null} - The first detection feature found, or null if none found.
 */
const getDetectionFeatureAtPixel = featureArray => {
	if (!featureArray?.length) return null;

	return featureArray?.find(
		feature => feature.get('featureType') == 'detection'
	);
};

/**
 * Retrieves the master features data from the API.
 * If the type is 'height', it will first try to get the 'height' data, and if no results are found, it will try to get the 'points' data.
 * Otherwise, it will get the data based on the given type.
 */
const getData = async ({ type, project_uuid, task_id }) => {
	let result;

	if (type === 'height') {
		result = await getMasterFeatures(project_uuid, task_id, 'height');
		if (!result || result.length === 0 || result === 'File not found') {
			// If no results for 'height', try 'points'
			result = await getMasterFeatures(project_uuid, task_id, 'points');
		}
	} else {
		result = await getMasterFeatures(project_uuid, task_id, type);
	}

	return result;
};

/**
 * Checks if the given event has a Draw interaction.
 *
 * @param {Event} event - The event object.
 * @returns {boolean} - True if the event has a Draw interaction, false otherwise.
 */
const hasDrawInteraction = event => {
	const currentInteractions = event.map.getInteractions().getArray();
	return currentInteractions.some(interaction => interaction instanceof Draw);
};

/**
 * responsible for rendering the object Info layer,
 */
const ObjectInfoLayer = ({ infoLayerMinZoom = 22, infoLayerMaxZoom = 18 }) => {
	const { addToast } = useToast();

	const { mapObject, toolBarVisible, project, pickedTask, dispatch } =
		useProject();
	const taskId = pickedTask?.model_uuid;
	const masterFeatureType =
		pickedTask?.task_type === MODEL_TYPE.SEGMENTATION
			? 'polygon'
			: 'height';

	const { data } = useQuery({
		queryKey: [taskId, 'heights_master_features', project.uuid],
		queryFn: () =>
			getData({
				type: masterFeatureType,
				project_uuid: project.uuid,
				task_id: taskId,
			}),
		enabled: !!project?.uuid && !!taskId,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const features = data?.features;

	const layerId = 'objectInfoLayer';

	const adding = useRef(false);
	const [objectInfoLayer, setObjectInfoLayer] = useState(null);

	const tooltipRef = useRef(null);
	const tooltip = useRef(null);
	const [tooltipContent, setTooltipContent] = useState(null);
	const [interactionKeys, setInteractionKeys] = useState(null);

	const [disabled, setDisabled] = useState(true);

	const removeObjectInfoInteractions = () => {
		if (interactionKeys) {
			console.log('Removing point info interactions');
			interactionKeys.forEach(unByKey);
			setInteractionKeys(null);
		}

		if (tooltip.current) {
			mapObject?.removeOverlay(tooltip.current);
			tooltip.current = null;
		}
	};

	const addObjectInfoInteractions = () => {
		if (interactionKeys) {
			removeObjectInfoInteractions();
		}

		if (!tooltip.current) {
			tooltip.current = createTooltip({
				mapRef: mapObject,
				tooltipRef: tooltipRef.current,
				offset: [1, -15],
				id: 'point-info-tooltip',
			});
		}

		// Create a tooltip if mouse is over a detection feature
		const pointerMove = mapObject.on('pointermove', e => {
			const mapElement = mapObject?.getTargetElement();
			const _tooltip = tooltip.current;
			if (e.dragging || !toolBarVisible || !_tooltip) return;

			// DetectionFeatureAtPixel = a feature that exists in a model result layer and not the same at the feature array in this file
			const detectionFeatureAtPixel = getDetectionFeatureAtPixel(
				mapObject.getFeaturesAtPixel(e.pixel)
			);

			if (detectionFeatureAtPixel && !hasDrawInteraction(e)) {
				mapElement.style.cursor = 'pointer';
				mapElement.title = 'Click to copy coordinates';
			} else {
				mapElement.style.cursor = '';
				mapElement.title = '';
			}

			// Hide the tooltip if the zoom level is too high or too low, or if no detection feature is found
			const mapZoom = mapObject.getView().getZoom();
			if (
				!detectionFeatureAtPixel ||
				mapZoom > infoLayerMinZoom ||
				mapZoom < infoLayerMaxZoom ||
				hasDrawInteraction(e)
			) {
				_tooltip.setPosition(null);
				return;
			}

			// set formatted data variables
			const featureData = detectionFeatureAtPixel.get('data');
			const featureClassname = `${featureData.classname}`;
			const featureConfidence = formatConfidence(featureData?.confidence);
			const featureArea = formatProperty('Area', featureData?.area, 'm²');
			const featureDiameter = featureData?.radius
				? formatProperty('Diameter', featureData.radius * 2, 'm')
				: '';

			// The height property is not available in the detection feature, so we need to find it in a matching feature from this file
			const matchingFeature = features.find(
				feature =>
					feature.properties?.annotation_uuid ===
					featureData?.annotation_uuid
			);
			featureData.height = matchingFeature?.properties?.height;
			const featureHeight = formatProperty(
				'Height',
				featureData?.height,
				'm'
			);

			const renderListItem = content => {
				return content ? (
					<li className="list-group-item">{content}</li>
				) : null;
			};

			const featureCoordinates = detectionFeatureAtPixel
				.getGeometry()
				.getCoordinates();

			// set tooltip position and content
			_tooltip.setPosition(featureCoordinates);
			setTooltipContent(
				<div>
					<span className="mb-1 d-block text-capitalize">
						{featureClassname}
					</span>
					<ul className="m-0 p-0 small lh-sm">
						{renderListItem(featureConfidence)}
						{renderListItem(featureHeight)}
						{renderListItem(featureArea)}
						{renderListItem(featureDiameter)}
					</ul>
				</div>
			);
		});

		// Copy coordinates to clipboard when a detection feature is clicked
		const pointerClick = mapObject.on('click', async e => {
			// Return if the map is being dragged or the zoom level is too low
			const mapZoom = mapObject.getView().getZoom();
			if (
				e.dragging ||
				mapZoom < infoLayerMaxZoom ||
				hasDrawInteraction(e)
			) {
				return;
			}

			const detectionFeatureAtPixel = getDetectionFeatureAtPixel(
				mapObject.getFeaturesAtPixel(e.pixel)
			);

			if (!detectionFeatureAtPixel) return;

			// Get the coordinates from the detection feature at the pixel
			const coordinates = detectionFeatureAtPixel
				.getGeometry()
				?.getCoordinates();

			// Convert the coordinates from Projected Coordinate System (XY) to Geographic Coordinate System (LonLat)
			// Reverse the coordinates beacuse we want [lat, lon] instead of [lon, lat]
			const convertedCoordinates = toLonLat(coordinates).reverse();

			// Format the converted coordinates and join them into a string
			const formattedFeatureCoordinates =
				formatCoordinates(convertedCoordinates).join(', ');

			try {
				await navigator.clipboard.writeText(
					formattedFeatureCoordinates
				);
				addToast({
					id: `copy_coords_success-${new Date().getTime()}`,
					title: 'Coordinates copied to clipboard',
					message: formattedFeatureCoordinates,
					bg: 'success',
				});
			} catch (err) {
				console.warn('Failed to copy coordinates: ', err);
				addToast({
					id: `copy_coords_error-${new Date().getTime()}`,
					title: 'Coordinates could not be copied to clipboard',
					message: (
						<>
							Copy them manually:
							<br />
							{formattedFeatureCoordinates}
						</>
					),
					autohide: false,
					bg: 'danger',
				});
			}
		});

		// Disable the object info layer when the zoom level is too high or too low
		const resolutionChange = mapObject
			.getView()
			.on('change:resolution', () => {
				const mapZoom = mapObject.getView().getZoom();
				const _tooltip = tooltip.current;

				if (mapZoom > infoLayerMinZoom) {
					setDisabled(false);
				} else {
					setDisabled(true);
				}

				if (
					_tooltip &&
					(mapZoom > infoLayerMinZoom || mapZoom < infoLayerMaxZoom)
				) {
					_tooltip.setPosition(null);
				}
			});

		setInteractionKeys([pointerMove, pointerClick, resolutionChange]);
	};

	const removeObjectInfoLayer = () => {
		if (mapObject) {
			const deleted = deleteLayerByCustomId(mapObject, layerId);
			if (deleted) {
				console.log('Removed object info layer');
			}
		}

		setObjectInfoLayer(null);
	};

	const updateObjectInfoLayer = useCallback(() => {
		// if the map object exists create and add the layer
		if (mapObject && !adding.current) {
			adding.current = true;

			let layer = null;

			const existingLayer = getLayerByCustomId(mapObject, layerId);

			if (features?.length) {
				const objectInfoLayerSource = new VectorSource({
					features: features.map(feature => {
						return new Feature({
							geometry: getFeatureGeometry(feature.geometry),
							type: 'detectionObjectInfo',
							data: feature.properties,
						});
					}),
				});

				if (existingLayer) {
					console.log(
						`Object Info Layer already exists. Adding it to state.`
					);
					layer = existingLayer;
					// Update source
					layer.setSource(objectInfoLayerSource);
				} else {
					layer = new VectorLayer({
						zIndex: 12,
						minZoom: infoLayerMinZoom,
						source: objectInfoLayerSource,
						name: 'Object details',
						properties: {
							customLayerId: layerId,
						},
						visible: true,
						style: textStyle,
					});

					// add the layer to the map
					mapObject.addLayer(layer);
					console.log('Added Object Info Layer');

					dispatch({ type: 'setExportData', payload: data });
				}
			} else if (existingLayer) {
				// No data related to the layer and it is not needed anymore. Delete it.
				deleteLayerByCustomId(mapObject, layerId);
				removeObjectInfoInteractions();
			}

			setObjectInfoLayer(layer);
			adding.current = false;
		}
	}, [features, tooltipRef]);

	useEffect(() => {
		updateObjectInfoLayer();

		return () => {
			removeObjectInfoLayer();
			removeObjectInfoInteractions();
		};
	}, [updateObjectInfoLayer]);

	useEffect(() => {
		if (!toolBarVisible && interactionKeys) {
			removeObjectInfoInteractions();
		} else if (toolBarVisible && objectInfoLayer) {
			addObjectInfoInteractions();
		}
	}, [toolBarVisible]);

	useEffect(() => {
		if (!tooltipRef.current || tooltip.current) return;

		addObjectInfoInteractions();
	}, [tooltipRef, objectInfoLayer]);

	if (!objectInfoLayer) return null;

	return (
		<div id="objectInfoLayer">
			<Checkbox
				label={objectInfoLayer.get('name')}
				layer={objectInfoLayer}
				defaultState={objectInfoLayer?.getVisible()}
				handleCheck={() => {
					objectInfoLayer?.setVisible(true);
				}}
				handleUncheck={() => {
					objectInfoLayer?.setVisible(false);
				}}
				canEdit={false}
				disabled={disabled}
			/>
			<div className="ol-tooltip" ref={tooltipRef}>
				{tooltipContent && tooltipContent}
			</div>
		</div>
	);
};

export default ObjectInfoLayer;

const textStyle = feature => {
	const featureData = feature.get('data');
	const featureClassname = `${featureData.classname}`;
	const featureInfo = [
		formatConfidence(featureData?.confidence),
		formatProperty('Height', featureData?.height, 'm'),
		formatProperty('Area', featureData?.area, 'm²'),
		featureData?.radius &&
			formatProperty('Diameter', featureData.radius * 2, 'm'),
	];

	const capitalizeFirstLetter = string => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const featureInfoRichText = featureInfo.reduce(
		(accumulator, info) => {
			if (!info) return accumulator;

			accumulator.push(info, '', '\n', '');
			return accumulator;
		},
		[
			capitalizeFirstLetter(featureClassname),
			'1.2rem/1.75 Montserrat',
			'\n',
			'',
		]
	);

	return new Style({
		text: new Text({
			text: featureInfoRichText,
			font: '1rem Montserrat',
			fill: new Fill({
				color: '#fff',
			}),
			backgroundFill: new Fill({
				color: 'rgba(0, 0, 0, 0.6)',
			}),
			offsetX: 16, // Adjust this value to simulate horizontal padding
			textAlign: 'left',
			padding: [8, 8, 8, 8], // Padding for the text background
		}),
	});
};
