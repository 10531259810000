import Modify from 'ol/interaction/Modify';
import { never, primaryAction } from 'ol/events/condition';
import Circle from 'ol/geom/Circle';

import { calculateGeometryCenter } from '../helpers';

export const setModifyInteraction = ({
	source,
	updateModifiedObjects = null,
}) => {
	const defaultStyle = new Modify({ source: source })
		.getOverlay()
		.getStyleFunction();

	const interaction = new Modify({
		source: source,
		insertVertexCondition: never,
		condition: function (event) {
			return primaryAction(event);
		},
		deleteCondition: never,
		pixelTolerance: 10,
		// Style for the modify handles
		style: feature => modifyInteractionStyle(feature, defaultStyle),
	});

	interaction.on('modifystart', event => onModifyStart(event, interaction));

	interaction.on('modifyend', event =>
		onModifyEnd(event, updateModifiedObjects)
	);

	return interaction;
};

const onModifyStart = (event, interaction) => {
	const currentFeature = event.features.item(0);
	const modifyGeometry = currentFeature.getGeometry().clone();

	const isInside = currentFeature.get('isInside');

	// If not inside then abort the current feature modification
	if (!isInside) {
		interaction.setActive(false);
		currentFeature.setGeometry(modifyGeometry);
		currentFeature.unset('modifyGeometry', true);
		interaction.setActive(true);
		return;
	}

	currentFeature.set(
		'modifyGeometry',
		{
			geometry: modifyGeometry,
			originalGeometry: modifyGeometry,
		},
		true
	);
};

const onModifyEnd = (event, updateModifiedObjects) => {
	const currentFeature = event.features.item(0);
	const modifyGeometry = currentFeature.get('modifyGeometry');
	const isInside = currentFeature.get('isInside');

	if (modifyGeometry) {
		currentFeature.setGeometry(modifyGeometry.geometry);

		if (!isInside) {
			console.log(
				'Feture not inside tile. Moving back to original position'
			);
			currentFeature.setGeometry(modifyGeometry.originalGeometry);
		}

		currentFeature.unset('modifyGeometry', true);
	}

	if (isInside) {
		updateModifiedObjects(currentFeature);
	}
};

/**
 * Modify interaction style
 *
 * @param {*} feature - The feature to be modified
 * @param {*} defaultStyle - Default style that contains the modify source
 * @returns - The modified style
 */
const modifyInteractionStyle = (feature, defaultStyle) => {
	// Iterate over each feature in the 'features' property of the main feature
	feature.get('features').forEach(function (modifyFeature) {
		// Get the modifyGeometry property of the current feature
		const modifyGeometry = modifyFeature.get('modifyGeometry');
		if (!modifyGeometry) return;

		// Get the coordinates of the main feature's geometry
		const point = feature.getGeometry().getCoordinates();
		let {
			point: modifyPoint,
			geometry: initialGeometry,
			center,
			minRadius,
		} = modifyGeometry;

		// If modifyPoint is not defined, initialize it
		if (!modifyPoint) {
			// Save the initial geometry and vertex position
			modifyPoint = point;
			modifyGeometry.point = modifyPoint;
			modifyGeometry.geometry0 = initialGeometry;

			// Calculate and save the center and minimum radius of the geometry
			const result = calculateGeometryCenter(initialGeometry);
			modifyGeometry.center = result.center;
			modifyGeometry.minRadius = result.minRadius;

			// Update destructured variables
			center = result.center;
			minRadius = result.minRadius;
		}

		if (initialGeometry instanceof Circle) {
			// Calculate the distance from the center to the drag point
			const distanceToCenter = Math.hypot(
				modifyPoint[0] - center[0],
				modifyPoint[1] - center[1]
			);

			// Get the radius of the circle
			const radius = initialGeometry.getRadius();

			// Adjust the threshold based on the size of the circle
			const threshold = radius * 0.05; // 5% of the radius

			// Prevent scaling if dragging from the center
			if (distanceToCenter < threshold) {
				return;
			}

			let dx, dy;
			// Calculate the difference in x and y coordinates from the center
			dx = modifyPoint[0] - center[0];
			dy = modifyPoint[1] - center[1];
			// Calculate the initial radius
			const initialRadius = Math.sqrt(dx * dx + dy * dy);
			if (initialRadius > minRadius) {
				// Calculate the initial angle
				const initialAngle = Math.atan2(dy, dx);
				// Calculate the difference in x and y coordinates from the center for the current point
				dx = point[0] - center[0];
				dy = point[1] - center[1];
				// Calculate the current radius
				const currentRadius = Math.sqrt(dx * dx + dy * dy);
				if (currentRadius > 0) {
					// Calculate the current angle
					const currentAngle = Math.atan2(dy, dx);
					// Clone the initial geometry
					const geometry = modifyGeometry.geometry0.clone();
					// Scale the geometry based on the ratio of current radius to initial radius
					geometry.scale(
						currentRadius / initialRadius,
						undefined,
						center
					);
					// Rotate the geometry based on the difference between current angle and initial angle
					geometry.rotate(currentAngle - initialAngle, center);
					// Update the modifyGeometry with the new geometry
					modifyGeometry.geometry = geometry;
				}
			}
		} else {
			// Calculate the width and height adjustments based on the current point and center
			const initialWidth = Math.abs(modifyPoint[0] - center[0]);
			const initialHeight = Math.abs(modifyPoint[1] - center[1]);

			const currentWidth = Math.abs(point[0] - center[0]);
			const currentHeight = Math.abs(point[1] - center[1]);

			// Calculate the scaling factors for width and height
			const scaleX = currentWidth / initialWidth;
			const scaleY = currentHeight / initialHeight;

			// Apply scaling to the geometry
			const geometry = modifyGeometry.geometry0.clone();

			// Scale the geometry separately in the x and y directions
			geometry.scale(scaleX, scaleY, center);

			// Update the modifyGeometry with the new geometry
			modifyGeometry.geometry = geometry;
		}
	});

	// Return the default style for the feature
	return defaultStyle(feature);
};
