import Button from 'react-bootstrap/Button';
import { TbPhotoPin, TbMapPins } from 'react-icons/tb';
import styled from 'styled-components';

import { useNewProject } from '@contexts/NewProject.context';
import { PROJECT_MODE } from '@utils/constants';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;

	button {
		transition: all 0.4s;
		position: relative;
		padding-top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&.active {
			background-color: ${({ theme }) => theme.colors.green};
			svg,
			p {
				color: #ffffff;
			}
		}

		svg {
			transition: all 0.4s;
			width: 35px;
			height: 35px;
			color: ${({ theme }) => theme.colors.textAccent};
		}
		h3 {
			margin: 0;
			transition: all 0.4s;
			font-weight: 300;
			font-size: 1.2rem;
		}

		p {
			color: ${({ theme }) => theme.colors.textAccent};
		}

		@media screen and (min-width: 992px) {
			padding-bottom: 4rem;

			svg {
				width: 40px;
				height: 40px;
			}

			h3 {
				font-size: 1.5rem;
			}

			p {
				transition: all 0.2s;
				position: absolute;
				top: 68px;
				width: 64%;
				left: 18%;
				span {
					max-width: 300px;
					display: inline-block;
					margin: 0 auto;
				}
			}

			${({ $modeselected }) =>
				$modeselected &&
				`
					&:hover{
						p{
							opacity: 1;
							transition: all 0.8s;
						}
					}
					&:not(:hover){
						padding-bottom: 0rem;

						p{
							opacity: 0;
							margin-top: -1.5em;
							margin-bottom: 0;
						}
					}


			`}
		}
	}

	.project-choise-title {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: center;
		gap: 0.5em;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
`;

const SelectProjectType = () => {
	const { mode, setMode } = useNewProject();
	const { ORTHOPHOTO, SINGLE_IMAGE } = PROJECT_MODE;

	return (
		<Wrapper aria-label="Choose project type" $modeselected={!!mode}>
			<Button
				variant="dark"
				active={mode == ORTHOPHOTO}
				onClick={() => {
					if (mode == ORTHOPHOTO) return;
					setMode(ORTHOPHOTO);
				}}>
				<div className="project-choise-title">
					<TbPhotoPin strokeWidth={1} />
					<h3>Orthophoto</h3>
				</div>
				<p>
					<span>
						Use an existing orthophoto or stitch together JPGs to
						make a new one
					</span>
				</p>
			</Button>
			<Button
				variant="dark"
				active={mode == SINGLE_IMAGE}
				onClick={() => {
					if (mode == SINGLE_IMAGE) return;
					setMode(SINGLE_IMAGE);
				}}>
				<div className="project-choise-title">
					<TbMapPins strokeWidth={1} />
					<h3>Single Images</h3>
				</div>
				<p>
					<span>
						Positions individual images as markers on the map
					</span>
				</p>
			</Button>
		</Wrapper>
	);
};

export default SelectProjectType;
