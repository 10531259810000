import { api } from './index.js';
const route = '/evaluation';

export const getEvaluationPredictions = async ({ model_uuid }) => {
	const { data } = await api.get(
		`${route}/get_evaluation_predictions?model_uuid=${model_uuid}`
	);
	return data;
};

export const getEvalutationResultsFromDB = async ({ model_uuid }) => {
	const { data } = await api.get(
		`${route}/get_evaluation_results_from_db?model_uuid=${model_uuid}`
	);
	return data;
};

export const getTrainingStatsForTraining = async ({ model_uuid }) => {
	const { data } = await api.get(
		`${route}/get_training_stats_for_training?model_uuid=${model_uuid}`
	);
	return data;
};

export const replaceModelFromTraining = async ({ model_uuid, training_id }) => {
	const { data } = await api.get(
		`${route}/replace_model_from_training?model_uuid=${model_uuid}&training_id=${training_id}`
	);
	return data;
};

export const getAllTrainingsForModel = async ({ model_uuid }) => {
	const { data } = await api.get(
		`${route}/get_all_trainings_for_model?model_uuid=${model_uuid}`
	);
	return data;
};

export const setTrainingDescription = async ({
	model_uuid,
	training_id,
	description,
}) => {
	const { data } = await api.get(
		`${route}/set_training_description?model_uuid=${model_uuid}&training_id=${training_id}&description=${description}`
	);
	return data;
};
