import { Link } from 'react-router';

import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import Table from 'react-bootstrap/Table';

import TaskTypeIcon from '@components/taskTypeIcon/TaskTypeIcon';
import DateFormatted from '@components/dateFormatted/DateFormatted';
import CopyButton from '@routes/adminRoutes/sharedComponents/CopyButton';

const defaultData = [];
const columnHelper = createColumnHelper();

const columns = [
	columnHelper.accessor('task_description', {
		header: () => <span>Name</span>,
		cell: data => <NameHeader data={data} />,
	}),
	columnHelper.accessor('creator_name', {
		header: () => <span>User</span>,
		cell: data => (
			<CopyButton value={data.getValue()}>{data.getValue()}</CopyButton>
		),
	}),
	columnHelper.accessor('training_date', {
		header: () => <span>Last trained</span>,
		cell: data => <DateFormatted inputDate={data.getValue()} />,
	}),
];

const ModelsTable = ({ models }) => {
	if (!models || models.length === 0) {
		return <p>No models found</p>;
	}

	const table = useReactTable({
		data: models || defaultData,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div>
			<Table responsive hover>
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<TableHeaderGroup
							key={headerGroup.id}
							headerGroup={headerGroup}
						/>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => (
						<TableRow key={row.id} row={row} />
					))}
				</tbody>
			</Table>
		</div>
	);
};
export default ModelsTable;

const TableHeaderGroup = ({ headerGroup }) => {
	return (
		<tr>
			{headerGroup.headers.map(header => (
				<TableHeader key={header.id} header={header} />
			))}
		</tr>
	);
};

const TableHeader = ({ header }) => {
	return (
		<th>
			{!header.isPlaceholder &&
				flexRender(header.column.columnDef.header, header.getContext())}
		</th>
	);
};

const TableRow = ({ row }) => {
	return (
		<tr>
			{row.getVisibleCells().map(cell => (
				<TableCell key={cell.id} cell={cell} />
			))}
		</tr>
	);
};

const TableCell = ({ cell }) => {
	return <td>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
};

const NameHeader = ({ data }) => {
	return (
		<div className="d-flex align-items-center gap-3">
			<TaskTypeIcon task_type={data?.row?.original?.task_type} />
			<TitleLink
				title={data.getValue()}
				modelUuid={data?.row?.original?.model_uuid}
				description={data?.row?.original?.model_description}
			/>
		</div>
	);
};

export const TitleLink = ({ title, modelUuid, description }) => {
	return (
		<div className="d-flex flex-column">
			<Link to={`/admin/models/${modelUuid}/`} className="inherit">
				{title}
			</Link>
			{description && (
				<p className="text-muted mb-0 small">{description}</p>
			)}
		</div>
	);
};
