import InfoTooltip from '@components/infoTooltip/InfoTooltip';

export const IsTrainedHeader = ({ tooltipContainerId }) => (
	<span>
		Ready to use{' '}
		<InfoTooltip
			data-tooltip-html="Whether the model is trained enough<br /> and ready to use"
			placement="right"
			tooltipContainerId={tooltipContainerId}
			data-tooltip-variant="light"
		/>
	</span>
);
