import { getCookie } from '@utils/api';
import { useEffect, useCallback } from 'react';

export const useCookieChange = (cookieName, callback) => {
	const cookieObserver = useCallback(() => {
		const observer = new MutationObserver(() => {
			const newCookieValue = getCookie(cookieName);

			callback(newCookieValue);
		});

		observer.observe(document, {
			subtree: true,
			childList: true,
		});

		return () => observer.disconnect();
	}, [cookieName, callback]);

	useEffect(() => {
		return cookieObserver();
	}, [cookieObserver]);
};
