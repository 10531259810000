import { useInfiniteQuery } from '@tanstack/react-query';
import { Button, Spinner } from 'react-bootstrap';
import { useSearchParams } from 'react-router';

import { getAvailableClasses } from '@utils/api';
import { useAuth } from '@contexts/User.context';
import useSetSearchParams from '@hooks/useSetSearchParams.hook';
import { PROJECT_MODE } from '@utils/constants';

import View from '@components/layout/views/View';
import ModelsTable from './components/ModelsTable';
import Loader from '@components/loading/Loader';
import SearchField from '@components/filters/SearchField';
import ModelTypesTabs from '@components/models/ModelTypesTabs';
import CreateModel from './components/CreateModel';

const Models = () => {
	return (
		<View title="Your Custom Models">
			<ModelTypesTabs modelsContentComponent={ModelsContent} />
		</View>
	);
};
export default Models;

const ModelsContent = ({ projectMode }) => {
	const { currentUser } = useAuth();
	const [searchParams] = useSearchParams();

	const search = searchParams.get('search');
	const activeTab = searchParams.get('tab') || PROJECT_MODE.ORTHOPHOTO;

	const { data, hasNextPage, isFetchingNextPage, fetchNextPage, status } =
		useInfiniteQuery({
			queryKey: [
				'tasks',
				currentUser?.uuid,
				currentUser?.active_org_id,
				projectMode,
				search,
			],
			queryFn: ({ pageParam = 0 }) =>
				getAvailableClasses({
					image_mode: projectMode,
					limit: 50,
					offset: pageParam ?? 0,
					search,
				}),
			initialPageParam: 0,
			getNextPageParam: lastPage => {
				const { total, limit, offset } = lastPage ?? {
					total: 0,
					limit: 0,
					offset: 0,
				};

				const newOffset = offset + limit;

				if (newOffset < total) {
					return newOffset;
				}

				return undefined;
			},
			enabled: !!currentUser?.uuid && projectMode === activeTab,
		});

	const pages = data?.pages ?? [];
	const models = pages?.flatMap(page => page.models) ?? [];

	const isLoading = status === 'loading' || status === 'pending';
	const error = status === 'error';

	return (
		<div className="mt-2">
			<div className="d-flex justify-content-between align-items-center gap-2 mb-4">
				<Filters />
				<div>
					<CreateModel projectMode={projectMode} />
				</div>
			</div>

			{isLoading && <Loader message="Loading models" />}

			{error && (
				<div className="alert alert-danger">Error loading models</div>
			)}

			{!isLoading && <ModelsTable customModels={models} />}

			{hasNextPage && (
				<footer className="text-center col-md-12 mt-4">
					<Button
						variant="success"
						className="col-6"
						disabled={isFetchingNextPage}
						onClick={fetchNextPage}>
						<span>Load more models</span>{' '}
						{isFetchingNextPage && (
							<Spinner
								animation="border"
								variant="light"
								size="sm"
							/>
						)}
					</Button>
				</footer>
			)}
		</div>
	);
};

const Filters = () => {
	const customSetSearchParams = useSetSearchParams();

	return (
		<>
			<SearchField
				setSearch={val => {
					customSetSearchParams({ search: val });
				}}
				label="Search for model"
				searchParamsId="search"
				noWrap
			/>
		</>
	);
};
