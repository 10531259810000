import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';

import { RiResetLeftLine } from 'react-icons/ri';

import { StyledModal } from '@components/modal/ModalStyle';
import Alert from '@components/alert/Alert';

import { useModelContext } from '@contexts/Model.context';
import { useToast } from '@contexts/Toast.context';
import { revertModelToVersion } from '@api';

const ButtonContainer = styled.div`
	margin-top: -15px;
	margin-bottom: 10px;

	.btn-link {
		color: ${props => props.theme.colors.textAccent};
	}
`;

const RollBackModelVersion = ({ version }) => {
	const [show, setShow] = useState(false);
	const { hasOngoingJob } = useModelContext();

	if (hasOngoingJob) {
		return null;
	}

	return (
		<>
			<ButtonContainer>
				<Button
					variant="link"
					onClick={() => setShow(true)}
					className="d-flex align-items-center gap-1">
					<RiResetLeftLine />
					<span>Rollback model to this version</span>
				</Button>
			</ButtonContainer>
			{show && (
				<RollbackModelVersionModal
					handleCancel={() => setShow(false)}
					version={version}
				/>
			)}
		</>
	);
};
export default RollBackModelVersion;

const RollbackModelVersionModal = ({ handleCancel, version }) => {
	const { modelName, modelUuid } = useModelContext();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const { mutate: deleteModelVersion, error } = useMutation({
		mutationFn: () =>
			revertModelToVersion({
				model_uuid: modelUuid,
				training_id: version,
			}),
		onSuccess: res => {
			console.log('Model successfully rolled back to version', res);
			addToast({
				id: `rollback_model_version_success-${new Date().getTime()}`,
				title: `Model successfully rolled back to version ${version}`,
				bg: 'success',
			});
			queryClient.invalidateQueries(['model', modelUuid]);
			queryClient.invalidateQueries(['allTrainings', modelUuid]);
			handleCancel();
		},
		onError: err => {
			console.error('Could not rollback model version', err);
		},
	});

	return (
		<StyledModal show={true} onHide={handleCancel} centered>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>Rollback model to version {version}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					Are you sure you want to rollback model{' '}
					<strong>{modelName}</strong> to version{' '}
					<strong>{version}</strong>?
				</p>
				<p>
					This will delete all annotations made past this version and
					can not be undone.
				</p>

				{error && (
					<Alert variant="danger">
						<p>
							Something went wrong and the model could not be
							rolled back to version {version}.
						</p>
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleCancel}>
					Cancel
				</Button>

				<Button variant="success" onClick={deleteModelVersion}>
					Rollback
				</Button>
			</Modal.Footer>
		</StyledModal>
	);
};
