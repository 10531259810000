import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styled from 'styled-components';
import AdminNavigation from '../navigation/AdminNavigation';
import Breadcrumbs from '@components/breadcrumbs/Breadcrumbs';

const StyledView = styled(Container)`
	color: ${props => props.theme.colors.textColor};
	padding-top: 2.5rem;
	padding-bottom: 3rem;

	@media (min-width: 1200px) {
		max-width: 1520px;
	}
`;

const AdminView = ({ title = '', maxWidth = null, breadcrumbs, children }) => {
	return (
		<StyledView>
			<Row>
				<Col md={2}>
					<AdminNavigation />
				</Col>
				<Col md={maxWidth ? maxWidth : 10}>
					{breadcrumbs && <Breadcrumbs items={breadcrumbs} />}

					<h1 className="mb-4">{title}</h1>
					{children}
				</Col>
			</Row>
		</StyledView>
	);
};

export default AdminView;
