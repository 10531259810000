import styled from 'styled-components';

import PopoverPicker from '@components/popoverPicker/PopoverPicker';
import useDebounce from '@hooks/useDebounce';

const ColorMenu = styled.div`
	margin-top: 20px;
`;

const ModifyHexagon = ({ hexagonColor, setHexagonColor }) => {
	const [r, g, b] = hexagonColor.color.split(', ').map(Number);
	const defaultColor = { r, g, b, a: 1 };

	const changeColor = useDebounce(
		({ r, g, b }) => setHexagonColor({ color: `${r}, ${g}, ${b}` }),
		300
	); // 300ms debounce time

	return (
		<ColorMenu>
			<PopoverPicker
				label="Hexagons color"
				color={defaultColor}
				onChange={changeColor}
				popDirection="left"
				stackItems={false}
				hideAlpha
			/>
		</ColorMenu>
	);
};

export default ModifyHexagon;
