import { Route, Routes } from 'react-router';

import { ProjectProvider } from '@contexts/Project.context';
import { ModelProvider } from '@contexts/Model.context';
import NewProjectProvider from '@contexts/NewProject.context';
import { ROLES, TIERS } from '@contexts/User.context';

import Chatbot from '@components/chatbot/Chatbot';

import RequireAuth from '@components/RequireAuth';
import Root from '@components/layout/Root';

import Home from '@routes/unprotectedRoutes/home/Home';
import Login from '@routes/unprotectedRoutes/Login';
import PrivacyPolicy from '@routes/unprotectedRoutes/PrivacyPolicy';
import BiodroneEULA from '@routes/unprotectedRoutes/BiodroneEULA';
import Contact from '@routes/unprotectedRoutes/Contact';
import Unauthorized from '@routes/unprotectedRoutes/Unauthorized';
import Missing404 from '@routes/unprotectedRoutes/Missing404';

import RegisterAccount from '@routes/unprotectedRoutes/RegisterAccount';
import RegisterTrialAccount from '@routes/unprotectedRoutes/RegisterTrialAccount';
import VerifyUser from '@routes/unprotectedRoutes/Verify';

import RequestPasswordReset from '@routes/unprotectedRoutes/RequestPasswordReset';
import PasswordReset from '@routes/unprotectedRoutes/resetPassword/components/ResetPassword';

import Checkout from '@routes/userRoutes/Checkout';
import Subscription from '@routes/userRoutes/Subscription';
import UserProjects from '@routes/userRoutes/projects/userProjects/Projects';
import Profile from '@routes/userRoutes/Profile';
import Subscribe from '@routes/userRoutes/Subscribe';

import ProjectMapView from '@routes/userRoutes/projects/singleProject/ProjectMapView';
import NewProject from '@routes/userRoutes/projects/newProject/NewProject';
import EditProject from '@routes/userRoutes/projects/EditProject';

import Models from '@routes/userRoutes/models/modelsArchive/Models';
import SingleModel from '@components/singleModel/SingleModel';

import OrganizationProfile from '@routes/userRoutes/OrganizationProfile';
import OrganizationProjects from '@routes/userRoutes/OrganizationProjects';

import Users from './routes/adminRoutes/users/Users';
import Organizations from './routes/adminRoutes/organizations/Organizations';
import Organization from './routes/adminRoutes/organizations/Organization';
import AdminProjects from './routes/adminRoutes/projects/ProjectsView';
import AdminDemoProjects from './routes/adminRoutes/projects/DemoProjects';
import AdminModels from './routes/adminRoutes/models/ModelsView';
import AdminSingleModel from './routes/adminRoutes/models/components/Model';
import MaintenanceMode from '@routes/unprotectedRoutes/MaintenanceMode';

const routesConfig = [
	{
		path: '/',
		element: <Root />,
		children: [
			{ index: true, element: <Home /> },
			{ path: 'login', element: <Login /> },
			{ path: 'privacy-policy', element: <PrivacyPolicy /> },
			{ path: 'eula', element: <BiodroneEULA /> },
			{ path: 'contact', element: <Contact /> },
			{ path: 'register', element: <RegisterAccount /> },
			{ path: 'register-trial', element: <RegisterTrialAccount /> },
			{ path: 'verify', element: <VerifyUser /> },
			{
				path: 'request-password-reset',
				element: <RequestPasswordReset />,
			},
			{ path: 'password-reset', element: <PasswordReset /> },
			{ path: 'unauthorized', element: <Unauthorized /> },
			{
				element: (
					<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User]} />
				),
				children: [
					{
						path: 'projects',
						children: [
							{ index: true, element: <UserProjects /> },
							{
								path: 'new',
								element: (
									<NewProjectProvider>
										<NewProject />
									</NewProjectProvider>
								),
							},
							{
								path: ':project_uuid',
								element: (
									<ProjectProvider>
										<ProjectMapView />
									</ProjectProvider>
								),
							},
							{
								path: ':project_uuid/edit',
								element: <EditProject />,
							},
						],
					},
					{
						path: 'models',
						children: [
							{ index: true, element: <Models /> },
							{
								path: ':model_uuid',
								element: (
									<ModelProvider>
										<SingleModel />
									</ModelProvider>
								),
							},
						],
					},
					{ path: 'subscribe', element: <Subscribe /> },
					{ path: 'profile', element: <Profile /> },
					{
						path: 'organization/:organization_uuid/profile',
						element: <OrganizationProfile />,
					},
					{
						path: 'organization/:organization_uuid/projects',
						element: <OrganizationProjects />,
					},
					{
						element: <RequireAuth allowedTiers={[TIERS.Pro]} />,
						children: [
							{ path: 'checkout', element: <Checkout /> },
							{ path: 'subscription', element: <Subscription /> },
						],
					},
				],
			},
			{
				path: 'admin',
				element: <RequireAuth allowedRoles={[ROLES.Admin]} />,
				children: [
					{ path: 'users', element: <Users /> },
					{ path: 'projects', element: <AdminProjects /> },
					{ path: 'demo-projects', element: <AdminDemoProjects /> },
					{
						path: 'organizations/:organization_uuid',
						element: <Organization />,
					},
					{ path: 'organizations/create', element: <Organization /> },
					{ path: 'organizations', element: <Organizations /> },
					{
						path: 'models',
						children: [
							{ index: true, element: <AdminModels /> },
							{
								path: ':model_uuid',
								element: (
									<ModelProvider>
										<AdminSingleModel />
									</ModelProvider>
								),
							},
						],
					},
				],
			},
			{ path: '*', element: <Missing404 /> },
		],
	},
];

const renderRoutes = routes =>
	routes.map(({ path, element, children, index }) => (
		<Route
			key={path || 'index'}
			path={path}
			element={element}
			index={index}>
			{children && renderRoutes(children)}
		</Route>
	));

function App() {
	const isMaintenanceMode =
		import.meta.env.VITE_APP_MAINTENANCE_MODE === 'true';

	// If the site is in maintenance mode, display the maintenance mode page
	if (isMaintenanceMode) {
		return <MaintenanceMode />;
	}

	return (
		<>
			<Routes>{renderRoutes(routesConfig)}</Routes>
			<Chatbot />
		</>
	);
}

export default App;
