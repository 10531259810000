import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getOngoingTrainingOrEvaluation } from '@utils/api';

const useOngoingModelJobs = modelUuid => {
	const [trainingModel, setTrainingModel] = useState(false);
	const [evaluatingModel, setEvaluatingModel] = useState(false);

	// Ongoing training or evaluation
	const { data: ongoingJobs } = useQuery({
		queryKey: ['ongoingJobs', modelUuid],
		queryFn: () => getOngoingTrainingOrEvaluation(modelUuid),
		refetchInterval: trainingModel || evaluatingModel ? 10000 : false,
		enabled: !!modelUuid,
	});

	useEffect(() => {
		if (ongoingJobs) {
			const training = ongoingJobs.training_in_progress;
			const evaluation = ongoingJobs.evaluation_in_progress;

			setTrainingModel(!!training);
			setEvaluatingModel(!!evaluation);
		}
	}, [ongoingJobs]);
	return { trainingModel, evaluatingModel };
};
export default useOngoingModelJobs;
