import { Link } from 'react-router';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

import ProjectTags from '@components/projectComponents/projectTags/ProjectTagsWrapper';
import ProjectThumbnail from '@components/projectComponents/ProjectThumbnail';
import { CardTitle, CardDate } from '../ProjectCardComponents';

const Body = styled.div`
	padding: 1rem;
	${props => props.$error && 'background: var(--bs-danger-bg-subtle);'}
`;

const StyledCardDate = styled(CardDate)`
	margin-bottom: 2rem;
`;

const OverlayComponent = ({ projectData }) => {
	if (!projectData) return null;
	const { orthophoto_progress, uuid, tags, title, created_at } = projectData;

	const error = !!orthophoto_progress?.error;

	return (
		<>
			<ProjectTags uuid={uuid} tags={tags} />
			<ProjectThumbnail uuid={uuid} />
			<Body $error={error}>
				{error && (
					<p className="alert alert-danger small p-2">
						Project processing has failed
					</p>
				)}
				<CardTitle>{title}</CardTitle>
				<StyledCardDate created_at={created_at} />
				<Button as={Link} to={`/projects/${uuid}/`} variant="dark">
					View Project
				</Button>
			</Body>
		</>
	);
};
export default OverlayComponent;
