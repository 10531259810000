import styled, { keyframes } from 'styled-components';

import UploadField from './components/uploadField/UploadField';
import FileStorage from './components/FileStorage';
import Map from '../map/Map';

const fadeIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
	padding-bottom: 20px;

	animation: ${fadeIn} 1s;

	@media screen and (max-width: 991.98px) {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto 22rem auto;
	}
`;

const AutoFit = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 1rem;

	&:has(> :nth-child(2)) {
		grid-template-rows: auto 1fr;
	}

	@media screen and (max-width: 991.98px) {
		grid-row: 3 / 4;
		margin-top: 1rem;
	}
`;

const UploadProjectFiles = () => (
	<Wrapper>
		<UploadField />

		<AutoFit>
			<FileStorage />
			<Map />
		</AutoFit>
	</Wrapper>
);

export default UploadProjectFiles;
