import { api } from './index.js';
import { PROJECT_MODE } from '@utils/constants.js';

const convertBytesToMegabytes = bytes => {
	return (bytes / 1024 ** 2).toFixed(0);
};

export const startPostProcessing = async ({
	// Project Info
	projectUuid,
	projectMode,

	// Run with Model
	models,

	// File Info
	exifData,
	tifSize,
	fileCount,
	stitchProject,

	// Advanced Options
	odmFlag,
}) => {
	const tifSizeMB = convertBytesToMegabytes(tifSize);

	const singleImageEndpoint = `/biodroneai/singleimage_processing/${projectUuid}`;
	const tifEndpoint = `/biodroneai/orthophoto_postprocessing/${projectUuid}/${tifSizeMB}`;
	const stitchEndpoint = `/biodroneai/odm_postprocessing/${projectUuid}/${fileCount}`;

	const { SINGLE_IMAGE, ORTHOPHOTO } = PROJECT_MODE;

	let body = { predict_requests: models };
	let url = '';

	if (projectMode == SINGLE_IMAGE) {
		url = singleImageEndpoint;
		body = { ...body, exifdata: exifData };
	}

	if (projectMode == ORTHOPHOTO) {
		url = stitchProject ? stitchEndpoint : tifEndpoint;
		if (stitchProject) body = { ...body, odm_flag: odmFlag };
	}

	await api.post(url, body);

	return true;
};

export const orthophotoPostprocessing = async uuid => {
	try {
		const res = await api.post(
			`/biodroneai/orthophoto_postprocessing/${uuid}`
		);
		return res;
	} catch (err) {
		console.log(err);
		return err.response;
	}
};

export const postDetectPolygon = async (projectId, taskId, results) => {
	const postData = {
		polygon: results[0],
		project_uuid: projectId,
		model_uuid: taskId,
	};
	try {
		const res = await api.post('/biodroneai/predict-in-polygon', postData);
		if (res?.data?.results_file) {
			return res.data;
		} else {
			throw new Error('No results file');
		}
	} catch (err) {
		throw err;
	}
};
