import { forwardRef, useState } from 'react';
import { Link, useNavigate } from 'react-router';

import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import {
	AiOutlineDelete,
	AiOutlineEdit,
	AiOutlineMore,
	AiOutlineTags,
} from 'react-icons/ai';

import { useAuth } from '@contexts/User.context';
import { humanFileSize } from '@utils/files';
import ProjectTags from './projectTags/ProjectTagsWrapper';
import ProjectThumbnail from './ProjectThumbnail';
import ProjectTagsModal from './projectTags/projectTagsModal';
import ArchiveProjectDialog from '@components/archiveProject/ArchiveProject';
import ProjectProgress from './ProjectProgress';

const StyledProjectCard = styled(Card)`
	color: ${p => p.theme.colors.textColor};
	background-color: ${p =>
		p.demo === 'true'
			? p.theme.colors.backgroundAccent
			: p.error === 'true'
			? 'var(--bs-danger-bg-subtle)'
			: p.theme.colors.modal};
	border: none;
	margin-bottom: calc(var(--bs-gutter-x));
	min-height: calc(100% - var(--bs-gutter-x));

	.card-footer {
		background: none;
	}
`;

const StyledActionsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: var(--bs-card-spacer-y);

	.action-btn {
		font-weight: 400;
		background: ${props => props.theme.colors.border};

		&:not(:hover) {
			background-color: ${p => p.theme.colors.border};
		}

		&:hover {
			background: #f8f9fa !important;
			color: black !important;
		}

		&:focus-visible {
			box-shadow: rgba(248, 249, 250, 0.5) 0px 0px 0px 4px;
			color: #f8f9fa;
			background: ${props => props.theme.colors.border};
		}
	}
`;

const StyledDropdownToggleButton = styled(Button)`
	background-color: ${p => p.theme.colors.border};
	padding: 0;
	font-size: 1.5rem;
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ProjectCard = ({ project, refetchProjects }) => {
	const { tierTrial, tierPro } = useAuth();
	const navigate = useNavigate();

	const [showTagsModal, setShowTagsModal] = useState(false);
	const [projectToBeArchived, setProjectToBeArchived] = useState(null);

	const {
		uuid: projectID,
		title,
		description,
		created_at: createdAt,
		tags,
		file_storage: fileStorage,
		orthophoto_progress,
		demo_project: isDemo,
		center_coordinate: center,
	} = project;

	const error = !!orthophoto_progress?.error;
	const creating =
		!error && (!center || (center[0] === -1 && center[1] === -1));

	const handleDropdownSelect = eventKey => {
		switch (eventKey) {
			case 'edit':
				navigate(`/projects/${projectID}/edit`);
				break;
			case 'tags':
				setShowTagsModal(true);
				break;
			case 'delete':
				setProjectToBeArchived({ id: projectID, title: title });
				break;
			default:
				console.log('Unknown eventKey: ', eventKey);
		}
	};

	return (
		<StyledProjectCard error={error.toString()} demo={isDemo.toString()}>
			<ProjectTags uuid={projectID} tags={tags} />

			<ProjectThumbnail uuid={projectID} />
			<ProjectProgress project={project} />

			<Card.Body>
				{error && (
					<p className="alert alert-danger small p-2">
						Project processing has failed
					</p>
				)}

				<Card.Title>{title ? title : 'Untitled project'}</Card.Title>
				<Card.Subtitle className="mb-2 text-muted fst-italic">
					{new Date(createdAt).toLocaleDateString('nb-NO', {
						day: 'numeric',
						month: 'short',
						year: 'numeric',
					})}
				</Card.Subtitle>

				<Card.Text>{description}</Card.Text>

				{tierPro && !isDemo && (
					<p className="small text-muted mb-0">
						{humanFileSize(fileStorage)} storage
					</p>
				)}
			</Card.Body>

			<Card.Footer className="border-0 background-none">
				<StyledActionsWrapper>
					<Button
						as={Link}
						to={`/projects/${projectID}/`}
						variant="dark"
						disabled={creating}
						className={creating && 'disabled'}>
						View Project
					</Button>

					{!isDemo && (
						<CardDropdown
							projectID={projectID}
							handleDropdownSelect={handleDropdownSelect}
							tierTrial={tierTrial}
							isDemo={isDemo}
						/>
					)}
				</StyledActionsWrapper>
			</Card.Footer>

			{showTagsModal && (
				<ProjectTagsModal
					show={showTagsModal}
					setShow={setShowTagsModal}
					projectId={projectID}
					projectTitle={title}
					existingTags={tags}
					callBack={refetchProjects}
				/>
			)}

			{projectToBeArchived && (
				<ArchiveProjectDialog
					project={projectToBeArchived}
					callBack={() => {
						setProjectToBeArchived(null);
						refetchProjects();
					}}
				/>
			)}
		</StyledProjectCard>
	);
};

export default ProjectCard;

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
	<StyledDropdownToggleButton
		className="rounded-circle btn-dark"
		size="lg"
		ref={ref}
		onClick={e => {
			onClick(e);
		}}>
		{children}
	</StyledDropdownToggleButton>
));

const CardDropdown = ({ projectID, handleDropdownSelect, tierTrial }) => {
	return (
		<Dropdown onSelect={eventKey => handleDropdownSelect(eventKey)}>
			<Dropdown.Toggle
				as={CustomToggle}
				aria-label="Toggle project actions"
				id={`action-menu-${projectID}`}>
				<AiOutlineMore aria-hidden="true" />
			</Dropdown.Toggle>

			<Dropdown.Menu variant="dark">
				<Dropdown.Item as="button" eventKey="edit">
					<AiOutlineEdit className="m-1 mb-2" aria-hidden="true" />
					Edit
				</Dropdown.Item>

				<Dropdown.Item as="button" eventKey="tags">
					<AiOutlineTags className="m-1 mb-2" aria-hidden="true" />
					Edit tags
				</Dropdown.Item>

				<Dropdown.Divider className="border-secondary" />

				<Dropdown.Item
					as="button"
					eventKey="delete"
					disabled={tierTrial}>
					<AiOutlineDelete className="m-1 mb-2" aria-hidden="true" />
					Delete
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};
