import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import Placeholder from 'react-bootstrap/Placeholder';
import { RiArrowUpDoubleFill, RiArrowDownDoubleFill } from 'react-icons/ri';

import { currentTheme } from '@contexts/Theme.context';

const buttonHeight = 48;

const OuterWrapper = styled.div`
	position: relative;
	min-height: ${({ $minHeight }) => $minHeight + 20}px;
	transition: padding 0.3s;

	padding-bottom: ${({ $open }) => ($open ? `${buttonHeight}px` : 0)};
`;

const InnerContentWrapper = styled.div`
	overflow: hidden;
	height: ${({ $minHeight, $height, $open }) =>
		$open ? $height : $minHeight}px;
	transition: height 0.3s;

	@starting-style {
		min-height: 20px;
	}
`;

const InnerContent = styled.div`
	position: ${({ $show }) => ($show ? 'static' : 'absolute')};
	z-index: ${({ $show }) => ($show ? '1' : '-1')};
`;

const Button = styled.button`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: ${buttonHeight}px;
	padding: 20px 10px 5px;
	background-color: transparent;
	background-image: ${({ $buttonBg }) =>
		`linear-gradient(to top, ${$buttonBg} 70%, transparent)`};

	color: ${({ $buttonColor }) => $buttonColor};
	border: none;
	font-size: 0.8rem;

	div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	span {
		text-decoration: underline;
	}
`;

const CollapsibleContent = ({
	minHeight = 40,
	buttonColor = currentTheme.colors.textColor,
	buttonBgColor = currentTheme.colors.background,
	children,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [innerContentHeight, setInnerContentHeight] = useState(null);
	const [collapsible, setMakeCollapsible] = useState(false);

	const innerContentRef = useRef(null);
	const componentMinHeight = collapsible ? minHeight : 'none';

	useEffect(() => {
		const height = innerContentRef.current.clientHeight;
		if (height && innerContentHeight !== height) {
			setInnerContentHeight(height);
			if (height > minHeight + buttonHeight) {
				// Only make it collapsible if the content is larger than the minHeight
				setMakeCollapsible(true);
			}
		}

		return () => {
			if (innerContentHeight) {
				setInnerContentHeight(null);
				setMakeCollapsible(false);
			}
		};
	}, [innerContentRef.current?.clientHeight]);

	return (
		<OuterWrapper $minHeight={componentMinHeight} $open={isOpen}>
			<InnerContentWrapper
				$open={isOpen}
				$minHeight={componentMinHeight}
				$height={collapsible ? innerContentHeight : 'auto'}>
				{!innerContentHeight && <PlaceholderContent />}
				<InnerContent
					$show={!!innerContentHeight}
					ref={innerContentRef}>
					{children}
				</InnerContent>
			</InnerContentWrapper>

			{collapsible && (
				<Button
					onClick={() => setIsOpen(!isOpen)}
					$buttonBg={buttonBgColor}
					$buttonColor={buttonColor}>
					{isOpen ? <LessText /> : <MoreText />}
				</Button>
			)}
		</OuterWrapper>
	);
};
export default CollapsibleContent;

const PlaceholderContent = () => {
	return (
		<>
			<Placeholder as="div" animation="glow">
				<Placeholder xs={12} />
			</Placeholder>
			<Placeholder as="div" animation="glow">
				<Placeholder xs={6} />
			</Placeholder>
			<Placeholder as="div" animation="glow">
				<Placeholder xs={9} />
			</Placeholder>
		</>
	);
};

const MoreText = () => {
	return (
		<>
			<span>Show more</span> <RiArrowDownDoubleFill />
		</>
	);
};

const LessText = () => {
	return (
		<>
			<span>Show less</span> <RiArrowUpDoubleFill />
		</>
	);
};
