import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useProject } from '@contexts/Project.context';
import { makePointLayer } from '@utils/map/point.layer';
import { getMasterFeatures } from '@utils/api';
import { setupLayerDetails, deleteLayerByCustomId } from '@utils/map/helpers';

import Checkbox from '../sidebars/sidebarElements/Checkbox';
import NoResult from '../sidebars/sidebarElements/NoAnalyzesResult';
import ObjectInfoLayer from './ObjectInfoLayer';

/**
 *
 * responsible for rendering the point layer,
 * and the feature menu that is used to change the styles of the points
 */

const PointLayer = ({ setActiveSidebar }) => {
	const {
		mapObject,
		defaultProjection,
		features,
		pickedModelClasses,
		pointLayer,
		pickedTask,
		project,
		colorScheme,
		colorOptions,
		dispatch,
	} = useProject();

	const [showInfoLayer, setShowInfoLayer] = useState(true);

	const layerId = 'pointsResultsLayer';
	const taskId = pickedTask?.model_uuid;

	const {
		data: pointData,
		isError,
		error,
	} = useQuery({
		queryKey: [taskId, 'points_master_features', project.uuid],
		queryFn: async () => getMasterFeatures(project.uuid, taskId, 'points'),
		enabled: !!project?.uuid && !!taskId,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const adding = useRef(false);
	const removing = useRef(false);

	const updatePointLayer = useCallback(() => {
		if (adding.current) return;

		let mounted = true; // Track if component is mounted

		if (features.length > 0 && mapObject) {
			adding.current = true;
			let newColorScheme;

			if (!pickedModelClasses?.length) {
				const { modelClasses, colorStyles } = setupLayerDetails(
					features,
					colorOptions
				);

				newColorScheme = colorStyles;

				dispatch({ type: 'setColorScheme', payload: colorStyles });
				dispatch({
					type: 'setPickedModelClasses',
					payload: modelClasses,
				});
			}

			// Don't add the layer if the color scheme is not set
			if (!newColorScheme) {
				adding.current = false;
				return;
			}

			const tempPointLayer = makePointLayer({
				mapObject,
				defaultProjection,
				features,
				colorScheme: newColorScheme,
				layerId,
			});

			// Only update state if component is still mounted
			if (mounted) {
				dispatch({
					type: 'setPointLayer',
					payload: tempPointLayer,
				});

				console.log('🎯 Added point layer');
			}

			adding.current = false;
		} else {
			cleanupPointLayer();
		}

		// Return cleanup function
		return () => {
			mounted = false;
			cleanupPointLayer();
		};
	}, [features, colorScheme]);

	const cleanupPointLayer = () => {
		if (mapObject && pointLayer && !removing.current) {
			removing.current = true;

			console.log('🗑️ Removed point layer');
			deleteLayerByCustomId(mapObject, layerId);
			dispatch({ type: 'setPointLayer', payload: null });
			dispatch({ type: 'setPickedModelClasses', payload: null });
			dispatch({ type: 'setFeatures', payload: [] });

			removing.current = false;
		}
	};

	useEffect(() => {
		if (pointData?.features) {
			dispatch({ type: 'setFeatures', payload: pointData.features });
			return;
		}

		if (isError) {
			console.warn('could not fetch points', error);
			cleanupPointLayer();
		}
	}, [pointData, isError]);

	useEffect(() => {
		updatePointLayer();
	}, [updatePointLayer]);

	if (!pointLayer) return <NoResult />;

	return (
		<>
			<div id="pointLayer">
				<Checkbox
					label={pointLayer.get('name')}
					defaultState={pointLayer?.getVisible()}
					handleCheck={() => {
						pointLayer?.setVisible(true);
						setShowInfoLayer(true);
					}}
					handleUncheck={() => {
						pointLayer?.setVisible(false);
						setShowInfoLayer(false);
					}}
					handleClick={setActiveSidebar}
					layer={pointLayer}
				/>
			</div>
			{showInfoLayer && (
				<ul>
					<li>
						<ObjectInfoLayer />
					</li>
				</ul>
			)}
		</>
	);
};

export default PointLayer;
