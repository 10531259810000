const HelperText = ({ id, children }) => {
	if (!id) {
		console.warn('HelperText component requires an id prop');
		return;
	}

	return (
		<div id={`help-${id}`} className="small text-muted lh-sm my-2">
			{children}
		</div>
	);
};
export default HelperText;
