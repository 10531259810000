import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { StyledModal } from '../../../../../components/modal/ModalStyle';
import { getProjectResourceReport } from '../../../../../utils/api';

const ReportModal = ({ project_uuid, show, setShow }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [report, setReport] = useState(null);

	useEffect(() => {
		setLoading(true);
		const fetchReport = async () => {
			await getProjectResourceReport(project_uuid)
				.then(response => {
					if (response && response.status === 200 && response.data) {
						setReport(response.data);
					} else {
						setReport(null);
						setError(
							response?.data?.message || 'Failed to get report'
						);
					}
				})
				.catch(err => {
					console.error('Failed to get report in admin panel', err);
					setError(err);
				})
				.finally(() => {
					setLoading(false);
				});
		};
		fetchReport();
	}, [project_uuid]);

	return (
		<StyledModal
			variant="dark"
			show={show}
			centered={true}
			onHide={() => {
				setShow(false);
			}}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>Project resource report</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{loading && <Spinner animation="border" variant="white" />}

				{!loading && error && <p>Error: {error}</p>}

				{!loading && !error && report && (
					<div>
						{Object.entries(report).map(([key, value]) => (
							<p key={key}>
								{key}: {value}
							</p>
						))}
					</div>
					// <pre>{JSON.stringify(report, null, 2)}</pre>
				)}
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={() => setShow(false)}>
					Close
				</Button>
			</Modal.Footer>
		</StyledModal>
	);
};
export default ReportModal;
