import { Link } from 'react-router';

export const TitleLink = ({ title, modelUuid, description }) => {
	return (
		<div>
			<Link to={`/models/${modelUuid}/`} className="inherit">
				{title}
			</Link>
			{description && (
				<p className="text-muted mb-0 small">{description}</p>
			)}
		</div>
	);
};
