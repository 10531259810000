/**
 * Convert bytes to a human-friendly file size representation.
 *
 * @param {number} bytes - The number of bytes to convert.
 * @returns {string} Human-friendly file size representation.
 */
export function humanFileSize(bytes) {
	if (bytes == 0) return '0 Bytes';

	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	const i = Math.floor(Math.log(bytes) / Math.log(1024));

	return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}

// Function to download a file from a URL
export const downloadFile = ({ url, title, fileExtension }) => {
	if (!url || !title || !fileExtension) {
		console.warn(
			'Invalid parameters. Please provide a valid URL, title, and file extension.'
		);
		return;
	}

	// Sanitize the project title by replacing special characters and spaces with underscores
	const sanitizedTitle = title.replace(/[^a-zA-Z0-9]/g, '_');

	// Create a new link element
	const link = document.createElement('a');

	// Set the href of the link to the object URL
	link.href = url;

	// Set the download attribute of the link to the sanitized title and file ending
	link.download = `${sanitizedTitle}.${fileExtension}`;

	// Append the link to the body
	document.body.appendChild(link);

	// Click the link to start the download
	link.click();

	// Remove the link from the body
	document.body.removeChild(link);
};
