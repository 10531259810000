import axios from 'axios';
import { api, baseURL } from './index.js';
import { setRefreshToken, setToken } from '@contexts/Token.context.jsx';

export const registerUser = async fields => {
	const url = baseURL + '/auth/register';

	const {
		name,
		email,
		password,
		passwordConfirm: password_confirm,
		tier,
	} = fields;

	const user = {
		name,
		username: email,
		email,
		password,
		password_confirm,
		tier,
	};

	const { data } = await axios.post(url, user);
	return data;
};

export const requestPasswordReset = async email => {
	const url = baseURL + '/auth/request_reset_password';

	const passwordResetRequest = {
		email,
	};

	const { data } = await axios.post(url, passwordResetRequest);
	return data;
};

export const resetPassword = async fields => {
	const url = baseURL + '/auth/reset_password';

	const {
		email,
		token,
		password,
		passwordConfirm: password_confirm,
	} = fields;
	const passwordResetData = {
		token,
		email,
		password,
		password_confirm,
	};

	const { data } = await axios.post(url, passwordResetData);
	return data;
};

export const verifyUserEmail = async (email, token) => {
	const url = `${baseURL}/auth/verifyemail?token=${token}&email=${email}`;

	const { data } = await axios.get(url);

	return data;
};

export const loginUser = async fields => {
	const url = baseURL + '/auth/login';

	const { email, password } = fields;
	const user = {
		email,
		password,
	};

	const { data } = await axios.post(url, user, {
		withCredentials: false,
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		},
	});

	if (data.access_token) setToken(data.access_token);
	if (data.refresh_token) setRefreshToken(data.refresh_token);

	return data;
};

export const setActiveOrg = async (org_id = '') => {
	const url = baseURL + '/auth/users/set_active_org';

	const { data } = await api.put(url, null, { params: { org_id } });
	return data;
};
