import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router';
import { IoCloseSharp } from 'react-icons/io5';

import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { AiFillBell } from 'react-icons/ai';

import Notification from './Notification';

import {
	getUserNotifications,
	updateNotificationsStatus,
	deleteNotifications,
} from '@utils/api';
import { useAuth } from '@contexts/User.context';

const Wrapper = styled.div`
	position: relative;
`;

const StyledToggleButton = styled(Button)`
	font-size: 1.2rem;
	height: 36px;
	padding-top: 3px;
	padding-bottom: 3px;
	&[aria-pressed='true'] {
		background-color: ${p => p.theme.colors.backgroundAccent};
	}
`;

const Ring = keyframes`
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }
    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
`;

const IconWrapper = styled.span`
	position: relative;
	display: block;

	${p =>
		p.$hasunread &&
		css`
			svg {
				animation: ${Ring} 4s ease-in-out;
			}
			span {
				position: absolute;
				top: -3px;
				right: -11px;
				width: 22px;
				height: 22px;
				border-radius: 50%;
				background-color: ${p.theme.colors.green};
				border: 2px solid ${p.theme.colors.background};
				font-size: 12px;
				text-shadow: 0 0 2px ${p.theme.colors.background};
				> [aria-pressed='true'] &,
				button:hover & {
					border-color: ${p => p.theme.colors.backgroundAccent};
				}
			}
		`}
`;

const Backdrop = styled.div`
	position: fixed;
	z-index: 98;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
`;

const NotificationsContainer = styled.aside`
	position: absolute;
	z-index: 99;
	right: 0;
	top: 39px;
	width: 25vw;
	min-width: 426px;
	max-width: 680px;
	max-height: calc(100vh - 70px);
	overflow-y: auto;
	border-radius: 6px;
	background-color: ${p => p.theme.colors.backgroundAccent};
	box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.25);
	padding: 0.75rem;
`;

const NotificationsList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
`;

const HeaderName = styled.p`
	font-size: 0.8rem;
	margin: 0;
	color: ${p => p.theme.colors.textAccent};
`;

const StyledButton = styled(Button)`
	margin-right: 6px;
	color: ${p => p.theme.colors.textAccent};

	span {
		text-decoration: underline;
		font-size: 0.8rem;
	}
	svg {
		color: white;
	}
`;

/**
 * Component to display notifications with a toggle button.
 *
 * @returns {JSX.Element} The notifications component.
 */
const Notifications = () => {
	const [open, setOpen] = useState(false);

	const activeJobsRef = useRef(0);
	const queryClient = useQueryClient();

	const [searchParams] = useSearchParams();
	const pickedModel = searchParams.get('model');

	const { currentUser } = useAuth();

	const { data, isLoading, refetch } = useQuery({
		queryKey: [
			'notifications',
			currentUser?.uuid,
			currentUser?.active_org_id,
		],
		queryFn: getUserNotifications,
		enabled: !!currentUser?.uuid,
		refetchInterval: 1000 * 60 * 5, // 5 minutes
		refetchOnWindowFocus: false,
	});

	const { mutate: setRead } = useMutation({
		mutationFn: uuids => updateNotificationsStatus({ uuids }),
		onSuccess: () => {
			console.log('notifications marked as read');
			refetch();
		},
	});

	// All notifications
	const notifications = data?.notifications || [];

	// Active jobs notifications
	const activeJobsNotifications = useMemo(
		() =>
			notifications.filter(
				n => n.job_status === 'running' || n.job_status === 'pending'
			),
		[notifications]
	);

	// Unread notifications
	const unreads = notifications
		.filter(n => n.notification_status === 'unread')
		.map(n => n.uuid);

	const unreadCount = unreads?.length || 0;

	useEffect(() => {
		if (!open && unreadCount > 0) {
			console.log('setting read');
			// On close, mark all unread notifications as read
			setRead(unreads);
		}
	}, [open]);

	const handleQueryInvalidations = useCallback(() => {
		if (
			!pickedModel ||
			(activeJobsNotifications.length === 0 &&
				activeJobsRef.current === 0)
		) {
			return;
		}

		// If a model is picked and there are no active jobs, but there previously were,
		// invalidate all queries for that uses the model uuid as a query key
		queryClient.invalidateQueries({ queryKey: [pickedModel] });
		queryClient.invalidateQueries({ queryKey: ['models_detected_on'] });

		activeJobsRef.current = activeJobsNotifications.length;
	}, [activeJobsNotifications, pickedModel]);

	useEffect(() => {
		handleQueryInvalidations();
	}, [handleQueryInvalidations]);

	return (
		<Wrapper>
			<StyledToggleButton
				variant="dark"
				aria-pressed={open}
				disabled={isLoading}
				title={open ? 'Close notifications' : 'Open notifications'}
				onClick={() => setOpen(!open)}>
				{isLoading ? (
					<Spinner
						animation="border"
						variant="light"
						size="sm"
						role="status">
						<span className="visually-hidden">
							Checking for notifications
						</span>
					</Spinner>
				) : (
					<IconWrapper $hasunread={unreadCount && unreadCount > 0}>
						<AiFillBell />
						{unreadCount > 0 && <span>{unreadCount}</span>}
					</IconWrapper>
				)}
			</StyledToggleButton>
			{open && (
				<>
					<Backdrop onClick={() => setOpen(false)} />
					<NotificationsDropdown
						notifications={notifications}
						refetchNotifications={refetch}
					/>
				</>
			)}
		</Wrapper>
	);
};

export default Notifications;

/**
 * Component to display a dropdown list of notifications.
 *
 * @param {Array} notifications - Array of notification objects.
 * @param {Function} refetchNotifications - Function to refetch notifications.
 * @returns {JSX.Element} The notifications dropdown component.
 */
const NotificationsDropdown = ({ notifications, refetchNotifications }) => {
	// Notifications that are not pending or running and we can delete
	const inactiveNotifications = notifications
		.filter(n => !['pending', 'running'].includes(n.job_status))
		.map(n => n.uuid);

	return (
		<>
			<NotificationsContainer>
				{notifications.length > 0 ? (
					<>
						<div className="d-flex justify-content-between mb-3">
							<HeaderName>Notifications</HeaderName>
							<DeleteInactiveNotificationsButton
								inactiveNotifications={inactiveNotifications}
								refetchNotifications={refetchNotifications}
							/>
						</div>
						<NotificationsList>
							{notifications.map(notification => (
								<Notification
									key={notification.uuid}
									notification={notification}
									refetchAll={refetchNotifications}
								/>
							))}
						</NotificationsList>
					</>
				) : (
					<p className="text-center mt-1 mb-0">
						You have no new notifications
					</p>
				)}
			</NotificationsContainer>
		</>
	);
};

/**
 * Component to display a button for deleting inactive notifications.
 *
 * @param {Array} inactiveNotifications - Array of inactive notification UUIDs.
 * @param {Function} refetchNotifications - Function to refetch notifications after deletion.
 * @returns {JSX.Element|null} The delete button component or null if there are no inactive notifications.
 */
const DeleteInactiveNotificationsButton = ({
	inactiveNotifications,
	refetchNotifications,
}) => {
	if (inactiveNotifications?.length === 0) {
		return null;
	}

	const [deleting, setDeleting] = useState(false);

	const { mutate: mutateDeleteNotifications } = useMutation({
		mutationFn: uuids => deleteNotifications(uuids),
		onSuccess: () => {
			console.log('notifications deleted');
			refetchNotifications();
		},
		onSettled: () => {
			setDeleting(false);
		},
	});

	const deleteInactiveNotifications = () => {
		setDeleting(true);
		mutateDeleteNotifications(inactiveNotifications);
	};

	return (
		<StyledButton
			variant="link-light"
			size="sm"
			className="p-0"
			onClick={deleteInactiveNotifications}
			disabled={deleting}>
			<span>Clear inactive items</span>{' '}
			{deleting ? <Spinner size="sm" /> : <IoCloseSharp />}
		</StyledButton>
	);
};
