import { Card } from 'react-bootstrap';
import { Link } from 'react-router';

import { useModelContext } from '@contexts/Model.context';
import CollapsibleContent from '@components/collapsibleContent/CollapsibleContent';
import { currentTheme } from '@contexts/Theme.context';
import Loader from '@components/loading/Loader';

const Annotations = () => {
	const { modelAnnotations, modelUuid, annotationInfoLoading } =
		useModelContext();

	return (
		<Card body>
			<Card.Title>Projects with training data</Card.Title>
			{annotationInfoLoading && (
				<div className="d-flex  gap-3 py-3">
					<Loader inline /> Loading data
				</div>
			)}
			{!annotationInfoLoading && (
				<AnnotationsList
					annotations={modelAnnotations}
					modelUuid={modelUuid}
				/>
			)}
		</Card>
	);
};
export default Annotations;

const AnnotationsList = ({ annotations, modelUuid }) => {
	if (!annotations || annotations?.length < 1)
		return (
			<>
				<Card.Text className="text-muted">
					There are no projects with training data from this model.
				</Card.Text>
				<Card.Text>
					<Link to="/projects">Choose a project</Link> and start
					annotating to add training data.
				</Card.Text>
			</>
		);

	return (
		<CollapsibleContent
			buttonBgColor={currentTheme.colors.modal}
			minHeight={200}>
			<ul className="list-group list-group-flush">
				{annotations.map(annotation => (
					<AnnotationItem
						key={`annotation-item-${annotation.project_uuid}`}
						annotation={annotation}
						modelUuid={modelUuid}
					/>
				))}
			</ul>
		</CollapsibleContent>
	);
};

const AnnotationItem = ({ annotation, modelUuid }) => {
	if (!annotation) return null;
	const {
		project_name,
		project_uuid,
		number_of_annotations,
		number_of_annotated_tiles,
	} = annotation;

	return (
		<li className="list-group-item bg-transparent">
			<div>
				<Link
					to={`/projects/${project_uuid}?model=${modelUuid}&showTrainingData=true`}
					className="inherit">
					{project_name}
				</Link>
				<br />
				<small className="text-muted">
					<strong>{number_of_annotations}</strong> annotations on{' '}
					<strong>{number_of_annotated_tiles}</strong> tiles
				</small>
			</div>
		</li>
	);
};
