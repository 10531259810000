import { useState, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { useAuth } from '@contexts/User.context';
import useNotify from '@hooks/useNotify';
import { PROJECT_MODE } from '@utils/constants';
import {
	createProject,
	archiveProject,
	bulkUploadImagesToS3,
} from '@utils/api';
import { startPostProcessing } from '@utils/api';

const useSubmitProject = () => {
	const navigate = useNavigate();
	const { currentUser } = useAuth();
	const { checkForNotifications } = useNotify();
	const { publish_wms } = currentUser ?? {};
	const { ORTHOPHOTO, SINGLE_IMAGE } = PROJECT_MODE;

	const projectUuid = useRef(null);
	const [progress, setProgress] = useState(-1);
	const [isLoading, setIsLoading] = useState(false);

	const confirmUnload = e => e.preventDefault();

	const { mutateAsync: createProjectMutation } = useMutation({
		mutationFn: createProject,
		onMutate: () => console.log('%c 🛠️ Creating Project', 'color: #198754'),
		onError: err => console.error(`Error creating project: ${err}`),
	});

	const { mutateAsync: uploadFilesMutation } = useMutation({
		mutationFn: bulkUploadImagesToS3,
		onMutate: () =>
			console.group('%c ☁️ Uploading files', 'color: #0d6efd'),

		onError: err => console.error(`Error uploading files: ${err}`),
		onSettled: () => console.groupEnd(),
	});

	const { mutateAsync: startPostProcessingMutation } = useMutation({
		mutationFn: startPostProcessing,
		onMutate: () =>
			console.log('%c ⚙️ Starting post processing', 'color: #D49DFF'),

		onError: err => console.error(`Error starting post processing: ${err}`),
		onSuccess: () =>
			console.log('%c ✅ Post processing started', 'color: #00d26a'),
	});

	const { mutateAsync: archiveProjectMutation } = useMutation({
		mutationFn: archiveProject,
		onMutate: () =>
			console.log(
				'%c 📦 Archiving project due to error in project creation',
				'color: #FF5722'
			),

		onError: err =>
			console.error(
				`Error archiving project when failing on project creation: ${err}`
			),
	});

	const submit = async ({
		// Project Info
		projectName,
		projectDescription,
		projectTags,

		// Run with Model
		projectModels,

		// Advanced Options
		odmFlag,

		// File Info
		mode,
		files,
		isTif,
		totalFilesSize,
		exifData,
	}) => {
		try {
			if (mode == ORTHOPHOTO && !isTif && files.length < 25)
				return window.alert(
					'You need to upload at least 25 images to create an orthophoto project.'
				);

			if (mode == SINGLE_IMAGE && files.length < 1)
				return window.alert(
					'You need to upload at least 1 image to create a single image project.'
				);

			setIsLoading(true);

			// Show confirmation dialog when user tries to leave the page
			window.addEventListener('beforeunload', confirmUnload);

			const { uuid } = await createProjectMutation({
				title: projectName,
				description: projectDescription,
				tags: projectTags,
				file_storage: totalFilesSize,
				orthophoto_project: mode == ORTHOPHOTO,
				wms_publish: publish_wms,
			});
			projectUuid.current = uuid;

			await uploadFilesMutation({ files, projectId: uuid, setProgress });

			// FIX: This check happens too early. Temporary fix is to comment it out, and trust that the totalFilesSize set abov is correct. A fix should be implemented in the future.
			// await updateProjectWithS3Usage(projectId, isOrthophoto);

			await startPostProcessingMutation({
				// Project Info
				projectUuid: uuid,
				projectMode: mode,

				// Run with Model
				models: projectModels,

				// Advanced Options
				odmFlag,

				// File Info
				exifData,
				tifSize: isTif ? files[0].size : null,
				fileCount: files.length,
				stitchProject: !isTif,
			});

			checkForNotifications();

			// Remove confirmation dialog when user leaves the page
			window.removeEventListener('beforeunload', confirmUnload);

			navigate(`/projects`);
		} catch (err) {
			// Remove confirmation dialog when user leaves the page
			window.removeEventListener('beforeunload', confirmUnload);

			console.log('%c ❌ Error creating project.', 'color: #f92f60');

			const { current: uuid } = projectUuid;
			if (uuid) await archiveProjectMutation(uuid);
		} finally {
			// Reset
			projectUuid.current = null;
			setIsLoading(false);
			setProgress(-1);
		}
	};

	return {
		isLoading,
		progress,
		submit,
	};
};

export default useSubmitProject;
