import { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Input from '@components/form/Input';
import { setTrainingDescription } from '@utils/api';
import { useModelContext } from '@contexts/Model.context';
import { Spinner } from 'react-bootstrap';
import { useToast } from '@contexts/Toast.context';
import useClickAway from '@hooks/useClickAway';

const TrainingDescription = ({ training_id, training_description }) => {
	return (
		<div>
			<EditDescription
				training_id={training_id}
				description={training_description}
			/>
		</div>
	);
};
export default TrainingDescription;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 0px;
	gap: 0;
	align-items: center;
	transition: grid-template-columns 0.3s, gap 0.3s;

	form.editing & {
		grid-template-columns: 1fr 50px;
		gap: 1rem;
	}

	label:hover {
		cursor: pointer;
	}
`;

const ButtonWrapper = styled.div`
	opacity: 0;
	height: 0;
	width: 0;
	visibility: hidden;
	transition: opacity 0.3s;
	form.editing & {
		opacity: 1;
		height: auto;
		width: auto;
		visibility: visible;
	}
`;

const EditDescription = ({ training_id, description }) => {
	const { modelUuid } = useModelContext();
	const queryClient = useQueryClient();
	const { addToast } = useToast();

	const { mutate: saveDescription, isLoading } = useMutation({
		mutationFn: newDescription =>
			setTrainingDescription({
				model_uuid: modelUuid,
				training_id,
				description: newDescription,
			}),
		onSuccess: () => {
			queryClient.invalidateQueries('modelTrainings');
			setEditing(false);

			addToast({
				id: `model_description_success-${new Date().getTime()}`,
				title: 'Version description saved',
				bg: 'success',
			});
		},
		onError: error => {
			console.error('Failed to save model description', error);
			addToast({
				id: `model_description_error-${new Date().getTime()}`,
				title: 'Failed to save the version description',
				bg: 'danger',
			});
		},
	});

	const { control, setValue, handleSubmit } = useForm({
		defaultValues: {
			description: '',
		},
	});

	const [editing, setEditing] = useState(false);
	const formRef = useRef();
	useClickAway(formRef, () => setEditing(false));

	const onSubmit = data => saveDescription(data.description);

	useEffect(() => {
		setValue('description', description || '', { shouldDirty: true });
	}, [description]);

	return (
		<Form
			ref={formRef}
			onSubmit={handleSubmit(onSubmit)}
			onFocus={() => setEditing(true)}
			className={editing ? 'editing' : ''}>
			<Grid>
				<Controller
					name="description"
					control={control}
					render={({ field }) => (
						<Input
							{...field}
							type="text"
							label="Version description"
							placeholder="Add description"
							id={`description-${training_id}`}
							noWrap
						/>
					)}
				/>
				<ButtonWrapper>
					<Button
						type="submit"
						variant="success"
						disabled={isLoading}
						size="sm">
						{isLoading ? <Spinner size="sm" /> : 'Save'}
					</Button>
				</ButtonWrapper>
			</Grid>
		</Form>
	);
};
