const BooleanValue = ({ value }) => {
	return (
		<>
			{value ? (
				<span className="text-success" title="True">
					&#10003;
				</span>
			) : (
				<span className="text-muted" title="False">
					&#10005;
				</span>
			)}
		</>
	);
};

export default BooleanValue;
