import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router';
import { ButtonGroup, Dropdown, Button } from 'react-bootstrap';

import { useNewProject } from '@contexts/NewProject.context';
import { PROJECT_MODE } from '@utils/constants';

import CreateProjectWithModelModal from './CreateProjectWithModelModal';
import LimitInfo from '@components/subscription/LimitInfo';

const Actions = styled.div`
	align-self: end;
	margin-top: 1rem;

	display: grid;
	grid-template-columns: 1fr auto auto;
	align-items: end;
	gap: 10px;

	a {
		grid-column: 2 / 3;

		&[disabled] {
			opacity: 0.65;

			cursor: default;

			&:hover {
				background: #6c757d;
			}
		}
	}
`;

const DropdownToggle = styled(Dropdown.Toggle)`
	&[aria-expanded='true'] {
		background: #146c43 !important;
	}
`;

const DropdownMenu = styled(Dropdown.Menu)`
	border-color: rgba(
		255,
		255,
		255,
		0.4
	); //Without this it's hard to see the dropdown
`;

const FormActions = () => {
	const {
		monthlyProjectsLimit,
		userStorage,
		isSubmitting,
		files,
		mode,
		isTif,
	} = useNewProject();

	const {
		showLimitInfo,
		projectLimit,
		monthlyProjects,
		error: monthlyProjectsLimitError,
	} = monthlyProjectsLimit ?? {};
	const { error: userStorageError } = userStorage ?? {};

	const error = !!monthlyProjectsLimitError || !!userStorageError;

	const notEnoughImages =
		!isTif &&
		mode === PROJECT_MODE.ORTHOPHOTO &&
		files.length > 0 &&
		files.length < 25;

	const submitDisabled =
		!files.length || isSubmitting || error || notEnoughImages;

	const [showModal, setShowModal] = useState(false);

	return (
		<div>
			{showLimitInfo && (
				<LimitInfo
					className="text-end mt-2"
					limit={projectLimit}
					used={monthlyProjects}
					singularName="project"
					pluralName="projects"
					actionPastTense="created"
					actionPresentTense="Creating"
				/>
			)}

			{notEnoughImages && (
				<p className="small text-warning text-end">
					You need at least 25 images to create en orthophoto.
				</p>
			)}

			<Actions>
				<Button
					variant="secondary"
					as={Link}
					to="/projects"
					disabled={isSubmitting}>
					Cancel
				</Button>
				<Dropdown as={ButtonGroup}>
					<Button
						type="submit"
						variant="success"
						disabled={submitDisabled}>
						Create Project
					</Button>
					<DropdownToggle
						split
						variant="success"
						disabled={submitDisabled}
						title="Additional Options"
					/>

					<DropdownMenu>
						<Dropdown.Item
							as={Button}
							onClick={() => setShowModal(true)}>
							Create project and run detection
						</Dropdown.Item>
					</DropdownMenu>
				</Dropdown>
			</Actions>

			{showModal && (
				<CreateProjectWithModelModal setShowModal={setShowModal} />
			)}
		</div>
	);
};

export default FormActions;
