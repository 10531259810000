import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import CircularProgress from './CircularProgress';
import NotificationContent from './NotificationContent';
import { extractLatestNotificationJobProgress } from '@utils/notifications';
import LoadingDots from '@components/loading/LoadingDots';
import { Description } from './Layout';
import Badge from '@components/Badge';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 52px;
	align-items: center;
	gap: 10px;
`;

/**
 * Component to display job-related content for a notification.
 *
 * @param {Object} notification - The notification object.
 * @param {Function} setIntervalMs - Function to set the interval in milliseconds.
 * @param {Function} refetchAll - Function to refetch all notifications.
 * @returns {JSX.Element} The job content including common content, job description, and progress indicator.
 */
const JobNotification = ({ notification, setIntervalMs, refetchAll }) => {
	const hadRunningJob = useRef(false);

	const {
		project_uuid,
		project_title,
		model_uuid,
		model_info,
		job_status,
		updated_at,
		message,
	} = notification;

	// Extract job name, progress, and text from the last added object in the message
	const { jobType, jobName, progress, text, classification } =
		extractLatestNotificationJobProgress(message);

	const jobActive = job_status === 'running' || job_status === 'pending';
	const error =
		job_status === 'failed' ||
		job_status === 'killed' ||
		(job_status === 'finished' && progress < 100);

	useEffect(() => {
		if (!job_status) return;

		if (jobActive) {
			// If the job is running or pending, set the interval to 1 minute
			let interval = 1000 * 60;
			if (jobName.includes('Single Image')) {
				console.log('Single Image');
				interval = 1000 * 5;
			}
			setIntervalMs(interval);
			hadRunningJob.current = true; // Ref to know if we need to refetch after the job has finished
		} else {
			// Otherwise, clear the interval
			setIntervalMs(false);

			// Refetch all notifications after the job has finished
			if (hadRunningJob.current) {
				refetchAll();
				hadRunningJob.current = false;
			}
		}
	}, [job_status]);

	const descriptionComponent = () => {
		if (error) {
			return <JobError jobName={jobName} />;
		}

		if (job_status === 'pending') {
			return <JobPending />;
		}

		return <DefaultJobDescription text={text} jobActive={jobActive} />;
	};

	return (
		<Wrapper>
			<div>
				<Label classification={classification} />
				<NotificationContent
					project_uuid={project_uuid}
					project_title={project_title}
					model_uuid={model_uuid}
					model_info={model_info}
					updated_at={updated_at}
					disabled={jobActive}
					jobType={jobType}
				/>

				<Description>{descriptionComponent()}</Description>
			</div>

			{text && !error && (
				<CircularProgress
					size={40}
					strokeWidth={3}
					progress={progress}
					status={job_status}
				/>
			)}
		</Wrapper>
	);
};
export default JobNotification;

const DefaultJobDescription = ({ text, jobActive }) => {
	return (
		<p>
			{text}
			{jobActive && <LoadingDots />}
		</p>
	);
};

const JobPending = () => {
	return (
		<p>
			Pending startup
			<LoadingDots />
		</p>
	);
};

const JobError = ({ jobName }) => {
	return (
		<p className="alert alert-danger py-2 m-0">Error: {jobName} failed</p>
	);
};

const Label = ({ classification }) => {
	if (!classification) return null;

	return <Badge>{classification}</Badge>;
};
