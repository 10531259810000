import { Link } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { registerUser } from '@api';

import Alert from '@components/alert/Alert';
import Input from '@components/form/Input';
import Check from '@components/form/Check';

export default function RegisterForm({
	onSuccess,
	tier = 'pro',
	submitText = 'Register',
}) {
	const { control, handleSubmit } = useForm({
		defaultValues: {
			name: '',
			email: '',
			password: '',
			passwordConfirm: '',
			eula: false,
			terms: false,
		},
	});

	const {
		mutate: register,
		error,
		isLoading,
	} = useMutation({
		mutationFn: values => registerUser(values),
		onError: err => {
			console.warn('Registration failed', err);
		},
		onSuccess: () => {
			onSuccess();
		},
	});

	const onSubmit = data => {
		const { name, email, password, passwordConfirm, eula, terms } = data;

		if (eula && terms) {
			register({
				name,
				email,
				password,
				passwordConfirm,
				tier,
			});
		}
	};

	return (
		<>
			{error && <ErrorAlert error={error} />}

			<Form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="name"
					control={control}
					render={({ field }) => (
						<Input
							{...field}
							type="text"
							placeholder="Enter name"
							label="Name"
							autoComplete="name"
							required
						/>
					)}
				/>

				<Controller
					name="email"
					control={control}
					render={({ field }) => (
						<Input
							{...field}
							type="email"
							placeholder="Enter email"
							label="Email address"
							autoComplete="username"
							required
						/>
					)}
				/>

				<Controller
					name="password"
					control={control}
					render={({ field }) => (
						<Input
							{...field}
							type="password"
							placeholder="Password"
							label="Password"
							autoComplete="new-password"
							required
						/>
					)}
				/>

				<Controller
					name="passwordConfirm"
					control={control}
					render={({ field }) => (
						<Input
							{...field}
							type="password"
							placeholder="Confirm Password"
							label="Confirm Password"
							autoComplete="new-password"
							required
						/>
					)}
				/>

				<Controller
					name="eula"
					control={control}
					render={({ field }) => (
						<Check
							{...field}
							type="checkbox"
							label={
								<>
									Yes, I agree to the{' '}
									<Link
										to="/eula"
										target="_blank"
										className="inherit">
										Biodrone Portal User Agreement (EULA)
									</Link>
								</>
							}
							id="eula"
							required
						/>
					)}
				/>

				<Controller
					name="terms"
					control={control}
					render={({ field }) => (
						<Check
							{...field}
							type="checkbox"
							label={
								<>
									Yes, I agree to{' '}
									<Link
										to="/privacy-policy"
										target="_blank"
										className="inherit">
										Biodrones Privacy Policy
									</Link>
								</>
							}
							id="terms"
							required
						/>
					)}
				/>

				<Button
					variant="success"
					type="submit"
					className="mt-3"
					disabled={isLoading}>
					{submitText}
				</Button>
			</Form>
		</>
	);
}

const ErrorAlert = ({ error }) => {
	let errorMessage = error?.response?.data?.detail ?? 'Unknown error';
	if (errorMessage.includes('Invalid request')) {
		// Give some more context to the user
		errorMessage =
			'Error registering new user. Contact support if the issue persists.';
	}

	if (
		error.status !== 409 &&
		errorMessage.includes('password') && // prevent console.error for password not strong enough
		errorMessage.includes('Passwords') // prevent console.error for password mismatch
	) {
		console.error('Could not register new user', errorMessage);
	}

	return (
		<Alert variant="danger">
			<p>{errorMessage}</p>
		</Alert>
	);
};
