import AdminView from '@components/layout/views/AdminView';
import SingleModel from '@components/singleModel/SingleModel';

const Model = () => {
	return (
		<SingleModel
			ViewComponent={AdminView}
			breadcrumbsRootLink="/admin/models"
		/>
	);
};
export default Model;
