import { useParams, useLocation, Navigate } from 'react-router';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useForm, Controller, useFieldArray } from 'react-hook-form';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { getOrganization, updateOrganization } from '@api';

import AdminView from '@components/layout/views/AdminView';
import Toggle from '@components/form/Toggle';
import Select from '@components/form/Select';
import Alert from '@components/alert/Alert';
import OrganizationDetailsFormFields from '@components/forms/OrganizationDetailsFormFields';

import { useAuth } from '@contexts/User.context';
import { useToast } from '@contexts/Toast.context';

const OrganizationProfile = () => {
	const params = useParams();
	const location = useLocation();
	const { roleAdmin, isOrgAdmin } = useAuth();
	const { addToast } = useToast();

	const {
		isLoading: organizationLoading,
		isError,
		data: organization,
		error,
	} = useQuery({
		queryKey: ['organization', params?.organization_uuid],
		queryFn: () => {
			return getOrganization(params?.organization_uuid);
		},
		refetchOnWindowFocus: false,
	});

	const { control, handleSubmit } = useForm({
		defaultValues: {
			name: organization?.name ?? '',
			description: organization?.description ?? '',
			org_no: organization?.org_no ?? '',
			address: organization?.address ?? '',
			postal_code: organization?.postal_code ?? '',
			city: organization?.city ?? '',
			country: organization?.country ?? '',
			project_tags: organization?.project_tags || [],
			members: organization?.members || [],
		},
		values: organization,
	});

	const { fields: members, update } = useFieldArray({
		control,
		name: 'members',
	});

	const adminUsers =
		organization?.members?.filter(m => m.role === 'admin') ?? {};

	const {
		mutate: updateOrg,
		isLoading: updateOrgLoading,
		error: updateOrgError,
	} = useMutation({
		mutationFn: updatedOrg => {
			return updateOrganization(updatedOrg);
		},
		onSuccess: () => {
			addToast({
				id: `org-profile-update-${new Date().getTime()}`,
				title: 'Organization profile was updated!',
				bg: 'success',
			});
		},
	});

	const onSubmit = data => {
		updateOrg(data);
	};

	if (organizationLoading) {
		return <AdminView>Loading...</AdminView>;
	}

	if (!(isOrgAdmin || roleAdmin)) {
		return (
			<Navigate to="/unauthorized" state={{ from: location }} replace />
		);
	}

	return (
		<AdminView title={`Organization profile: ${organization?.name}`}>
			{isError ? (
				<Alert variant="danger">
					<p>{error?.message}</p>
				</Alert>
			) : (
				<>
					{updateOrgError ? (
						<Alert variant="danger">
							An error occurred:{' '}
							{updateOrgError?.message || 'Unknown'}
						</Alert>
					) : null}

					<Form
						onSubmit={handleSubmit(onSubmit)}
						disabled={updateOrgLoading}>
						<OrganizationDetailsFormFields control={control} />

						<div className="pb-4">
							<div className="mt-4">
								<h2>Organization admins</h2>
								{adminUsers?.length > 0 && (
									<ul className="list-group w-75 mb-3">
										{adminUsers?.map(u => (
											<li
												className="list-group-item"
												key={u.user_email}>
												{u.user_email}
											</li>
										))}
									</ul>
								)}
							</div>

							<div className="my-4">
								<h2>Members</h2>
								<Members members={members} update={update} />
							</div>

							<p className="small">
								To add or remove admins or members,{' '}
								<a href="mailto:post@biodrone.no">
									contact us at post@biodrone.no
								</a>
							</p>
						</div>

						<Controller
							name="project_tags"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									value={field.value?.map(t => ({
										value: t,
										label: t,
									}))}
									onChange={e => {
										field.onChange(e.map(tag => tag.label));
									}}
									id="organization-tags"
									label="Project tags (by assigning project tags, users in this organization will be limited to add only these tags to their projects)"
									noOptionsMessage={() =>
										'Start typing to add a tag'
									}
									isMulti
									creatable
								/>
							)}
						/>

						<div className="mt-5 mb-5">
							<Button
								type="submit"
								variant="success"
								className="m-1"
								disabled={
									organizationLoading || updateOrgLoading
								}>
								{updateOrgLoading ? 'Saving...' : 'Save'}
							</Button>
						</div>
					</Form>
				</>
			)}
		</AdminView>
	);
};
export default OrganizationProfile;

const Members = ({ members, update }) => {
	const accessOptions = [
		{
			value: 'create',
			label: 'Create',
			helperText: 'Allowed to create a project',
			disabled: true,
		},
		{
			value: 'detect',
			label: 'Detect',
			helperText: 'Allowed to detect objects in a project',
			disabled: false,
		},
		{
			value: 'train',
			label: 'Train',
			helperText: 'Allowed to train models in a project',
			disabled: false,
		},
	];

	return (
		<>
			<ul className="list-group w-75 mb-1">
				{members.map((member, index) => {
					if (member.role === 'admin') {
						return null;
					}
					return (
						<li
							key={`member-${member.user_uuid}`}
							className="list-group-item d-flex flex-wrap gap-4 align-items-center justify-content-between">
							<div>{member.user_email}</div>
							<div className="d-flex gap-4 mt-2">
								{accessOptions.map((o, i) => (
									<Toggle
										type="switch"
										id={`access-${i}-member-${member.user_uuid}`}
										key={`access-${i}`}
										name={o.value}
										label={o.label}
										defaultChecked={member.access.some(
											accessItem => accessItem === o.value
										)}
										disabled={o.disabled}
										inline
										onChange={e => {
											const checked = e.target.checked;
											const updatedAccess = checked
												? [...member.access, o.value] // If checked is true, add o.value to the member.access array
												: member.access.filter(
														a => a !== o.value
												  ); // If not, remove o.value from the array
											update(index, {
												...member,
												access: updatedAccess,
											});
										}}
									/>
								))}
							</div>
						</li>
					);
				})}
			</ul>
			<p className="text-muted">
				{accessOptions.map(o => {
					return (
						<span key={o.value} className="me-3 d-inline-block">
							<span className="badge bg-dark me-1">
								{o.label}
							</span>{' '}
							<span className="small">{o.helperText}</span>
						</span>
					);
				})}
			</p>
		</>
	);
};
