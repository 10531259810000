import { api } from './index.js';

export const getModelsDetectedOn = async project_uuid => {
	const { data } = await api.get(`/jobs/models_detected_on/${project_uuid}`);
	return data;
};

export const getOngoingTrainingOrEvaluation = async model_uuid => {
	const { data } = await api.get(
		`/jobs/check_on_going_training_or_evaluation_for_model?model_uuid=${model_uuid}`
	);
	return data;
};
