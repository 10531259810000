import exifr from 'exifr';

// Default options for exifr
const defaultJpgOptions = {
	chunked: true,
	gps: { skip: ['GPSAltitudeRef'] },
	exif: [
		'FocalLength',
		'FocalLengthIn35mmFormat',
		'ExifImageWidth',
		'ExifImageHeight',
	],
	xmp: true,
	icc: false,
	iptc: false,
	jfif: false,
	ihdr: false,
	ifd1: false,
	ifd0: { pick: ['BitsPerSample'] },
};

const defaultTiffOptions = {
	chunked: true,
	chunkSize: 1000000000,
	ifd0: { skip: ['GDALMetadata'] },
	ifd1: false,
	skip: [
		'StripByteCounts',
		'StripOffsets',
		'GeoTiffDoubleParams',
		'TileByteCounts',
		'TileOffsets',
		'TileLength',
		'TileWidth',
	],
};

export const GPSErrorMsg = 'No GPS data found in image';

export const getExifData = async files => {
	const defaultOptions =
		files[0].type === 'image/tiff' ? defaultTiffOptions : defaultJpgOptions;

	const exifDataPromises = files.map(file => {
		return exifr.parse(file, defaultOptions).then(formatExifData(file));
	});

	return Promise.allSettled(exifDataPromises)
		.then(results => {
			// Filter out only rejected promises
			const errors = results
				.filter(r => r.status === 'rejected')
				.map(r => r.reason.message);

			// Handle errors
			if (errors.length > 0) {
				throw new Error(errors);
			}

			// Map fulfilled values
			const values = results
				.filter(r => r.status === 'fulfilled')
				.map(r => r.value);

			return values;
		})
		.catch(err => {
			// handle errors
			return new Promise((resolve, reject) =>
				reject(err?.message ?? err)
			);
		});
};

const formatExifData = file => {
	return exifData => {
		// If there are any errors, throw an error with the first error message
		if (exifData?.errors?.length > 0) {
			throw new Error(exifData.errors[0]);
		}

		let formattedData;

		if (file.type === 'image/tiff') {
			formattedData = formatTiffData(file, exifData);
		} else {
			formattedData = formatJpgData(file, exifData);
			if (!formattedData.GPSLatitude || !formattedData.GPSLongitude) {
				throw new Error(GPSErrorMsg);
			}
		}

		return formattedData;
	};
};

const formatTiffData = (file, exifData) => {
	return {
		image_name: file.name,
		BitsPerSample: Array.from(exifData?.['BitsPerSample'] ?? [8, 8, 8]),
		Compression: exifData?.['Compression'] ?? null,
		ExtraSamples: exifData?.['ExtraSamples'] ?? null,
		GeoTiffAsciiParams: exifData?.['GeoTiffAsciiParams'] ?? null,
		GeoTiffDirectory: Array.from(exifData?.['GeoTiffDirectory'] ?? []),
		ImageHeight: exifData?.['ImageHeight'] ?? null,
		ImageWidth: exifData?.['ImageWidth'] ?? null,
		ModelTiePoint: Array.from(exifData?.['ModelTiePoint'] ?? []),
		PhotometricInterpretation:
			exifData?.['PhotometricInterpretation'] ?? null,
		PixelScale: Array.from(exifData?.['PixelScale'] ?? []),
		PlanarConfiguration: exifData?.['PlanarConfiguration'] ?? null,
		RowsPerStrip: exifData?.['RowsPerStrip'] ?? null,
		SampleFormat: Array.from(exifData?.['SampleFormat'] ?? []),
		SamplesPerPixel: exifData?.['SamplesPerPixel'] ?? null,
	};
};

const formatJpgData = (file, exifData) => {
	return {
		image_name: file.name,
		GPSLatitude: exifData?.['latitude'] ?? null,
		GPSLongitude: exifData?.['longitude'] ?? null,
		GPSAltitude: exifData?.['GPSAltitude'] ?? null,
		RelativeAltitude: exifData?.['RelativeAltitude'] ?? null,
		PixelXDimension: exifData?.['ExifImageWidth'] ?? null,
		PixelYDimension: exifData?.['ExifImageHeight'] ?? null,
		FocalLength: exifData?.['FocalLength'] ?? null,
		FocalLengthIn35mmFilm: exifData?.['FocalLengthIn35mmFormat'] ?? null,
		GimbalYawDegree: exifData?.['GimbalYawDegree'] ?? null,
		GimbalPitchDegree: exifData?.['GimbalPitchDegree'] ?? null,
		GimbalRollDegree: exifData?.['GimbalRollDegree'] ?? null,
		BitsPerSample: Array.from(exifData?.['BitsPerSample'] ?? [8, 8, 8]),
	};
};
