import { useEffect, useRef, useState } from 'react';
import { Map, View } from 'ol/index';
import { get as getProjection } from 'ol/proj.js';
import { Cluster } from 'ol/source.js';
import VectorSource from 'ol/source/Vector';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import {
	createMapLayer,
	createPinLayer,
	createClusterCircleLayer,
	createOverlayLayer,
} from '../utils/createLayers';
import handleMapClick from '../utils/handleMapClick';
import handleMapMouseMove from '../utils/handleMapMouseMove';
import {
	getFeaturesFromProjectsData,
	zoomToContainFeatures,
} from '../utils/helpers';

import OverlayInfo from './OverlayInfo';

const Wrapper = styled.div`
	position: relative;

	overflow: hidden;

	/* Map Styling for smaller than tablet size */
	@media (max-width: 991.98px) {
		border-top: 2px solid ${props => props.theme.colors.border};
		border-radius: 0.375rem;
		overflow: hidden;
	}
`;

const OverlayComponent = styled.div`
	position: absolute;
	translate: -50% -25%;

	display: grid;
	grid-template-columns: 16rem;

	background: ${props => props.theme.colors.modal};
	color: ${props => props.theme.colors.textColor};

	min-width: min-content;
	border-radius: 0.375rem;

	overflow: hidden;
	scale: 0.8;

	/* ProjectCard Change for smaller than tablet size */
	@media (max-width: 991.98px) {
		scale: 0.65;
	}
`;

const LoadingOverlay = styled.div`
	display: grid;
	place-items: center;

	background: #000000bb;
	position: absolute;
	overflow: hidden;
	z-index: 1;
	inset: 0;
`;

const StyledSpinner = styled(Spinner)`
	scale: 3;
`;

const MapComponent = ({ projectsData, map, setMap, projectDataQuery }) => {
	const { isLoading, isFetching } = projectDataQuery;

	const [layersToUpdate, setLayersToUpdate] = useState(null);
	const [overlayData, setOverlayData] = useState(null);

	const mapRef = useRef(null);
	const mapAdded = useRef(false);
	const overlayRef = useRef(null);

	// Set up map state and layers with no source once.
	useEffect(() => {
		if (mapAdded.current) return;
		console.log('Setting up map state and layers');

		const mapLayer = createMapLayer({ name: 'OSM Layer', zIndex: 0 });
		const pinLayer = createPinLayer({
			name: 'Cluster Pin Layer',
			zIndex: 1,
		});
		const clusterCircleLayer = createClusterCircleLayer({
			name: 'Cluster Circle Layer',
			zIndex: 2,
		});
		const mapInstance = new Map({
			target: mapRef.current,
			controls: [],
			loadTilesWhileAnimating: true,
			loadTilesWhileInteracting: true,
			view: new View({
				center: [0, 0],
				zoom: 0,
				maxZoom: 50,
				projection: getProjection('EPSG:3857'),
			}),
			layers: [mapLayer, pinLayer, clusterCircleLayer],
		});

		handleMapClick(mapInstance, mapRef.current);
		handleMapMouseMove(
			mapInstance,
			mapRef.current,
			createOverlayLayer(mapInstance, overlayRef.current),
			data => setOverlayData(data)
		);

		setMap(mapInstance);
		setLayersToUpdate({ pinLayer, clusterCircleLayer });

		mapAdded.current = true;

		// Cleanup
		return () => {
			console.log('Cleaning up map');
			try {
				if (!map) return;
				map.dispose();
				map.setTarget(null);
			} catch (e) {
				console.warn('Could not clean up map', e);
			}

			mapAdded.current = false;
			mapRef.current = null;
			setMap(null);
		};
	}, []);

	// Update Source of Cluster Layers And Zoom to Contain Features when projectsData changes
	useEffect(() => {
		if (!projectsData || !map || !layersToUpdate) return;
		console.log('Updating cluster layers');

		const source = new Cluster({
			source: new VectorSource({
				features: getFeaturesFromProjectsData(projectsData),
			}),
			zIndex: 1,
			distance: 50,
		});

		const { pinLayer, clusterCircleLayer } = layersToUpdate || {};
		pinLayer?.setSource(source);
		clusterCircleLayer?.setSource(source);

		zoomToContainFeatures(
			map.getView(),
			getFeaturesFromProjectsData(projectsData),
			[50, 50, 50, 50],
			0
		);
	}, [projectsData, layersToUpdate]);

	return (
		<Wrapper ref={mapRef}>
			<OverlayComponent ref={overlayRef}>
				<OverlayInfo projectData={overlayData} />
			</OverlayComponent>
			{isLoading ||
				(isFetching && (
					<LoadingOverlay>
						<StyledSpinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</StyledSpinner>
					</LoadingOverlay>
				))}
		</Wrapper>
	);
};
export default MapComponent;
