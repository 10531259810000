import { createContext, useContext, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from '@contexts/User.context';
import { getMonthlyProjectsLimit, getUserStorage } from '@utils/api';
import { getExifData } from '@routes/userRoutes/projects/newProject/utils/exif';
import { getImageStatistics } from '@utils/api';
import useSubmitProject from '@routes/userRoutes/projects/newProject/utils/hooks/useSubmitProject';

const useIsTif = files =>
	useMemo(() => files[0]?.type == 'image/tiff', [files]);

const useMonthlyProjectsLimit = () => {
	const { tierPro } = useAuth();
	const { data, error, isLoading } = useQuery({
		queryKey: ['userMonthlyProjectsLimit'],
		queryFn: getMonthlyProjectsLimit,
		enabled: tierPro,
		refetchOnWindowFocus: false,
	});

	if (error) console.error('Error fetching monthly projects limit.', error);

	const {
		permission,
		limit: projectLimit,
		monthly_projects: monthlyProjects,
		image_limit: imageLimit,
		days_left: daysLeft,
	} = data ?? {};

	const showLimitInfo =
		(!!monthlyProjects || monthlyProjects == 0) &&
		(!!projectLimit || projectLimit == 0);

	return {
		error,
		isLoading: tierPro ? isLoading : false,

		showLimitInfo,
		permission,
		projectLimit,
		monthlyProjects,
		projectLimitReached: projectLimit <= monthlyProjects,
		imageLimit,
		daysLeft,
	};
};

const useTotalFilesSize = files =>
	useMemo(
		() => files.reduce((accumulator, file) => accumulator + file.size, 0),
		[files]
	);

const useUserStorage = totalFilesSize => {
	const { tierPro, subscription } = useAuth();
	const { data, error, isLoading } = useQuery({
		queryKey: ['userStorage'],
		queryFn: getUserStorage,
		enabled: tierPro,
		refetchOnWindowFocus: false,
	});

	if (error) console.error('Error fetching user storage.', error);

	const { used: storageUsed } = data ?? {};
	const storageLimit = subscription?.product_metadata?.storage_limit ?? 0;

	return {
		error,
		isLoading: tierPro ? isLoading : false,

		storageUsed,
		storageLimit: parseInt(storageLimit),
		storageLimitReached: storageUsed + totalFilesSize > storageLimit,
	};
};

const useExif = (files, isTif, mode, isSubmitting) => {
	const hasFiles = files.length > 0;
	const getExif = async () => await getExifData(files);

	const getExifFootprint = async exifData =>
		await getImageStatistics({
			exifData: exifData,
			isTif: isTif,
			imageMode: mode,
		});

	const {
		data: exifData,
		error: exifError,
		isLoading: exifIsLoading,
	} = useQuery({
		queryKey: ['exif', files, isTif, mode],
		queryFn: getExif,
		enabled: !isSubmitting && hasFiles,
		refetchOnWindowFocus: false,
		retry: false,
	});

	if (exifError && !isSubmitting) {
		console.warn('Error parsing exif data.', exifError);
	}

	const {
		data: exifFootprint,
		error: exifFootprintError,
		isLoading: exifFootprintIsLoading,
	} = useQuery({
		queryKey: ['exifFootprint', files, isTif, mode, exifData],
		queryFn: () => getExifFootprint(exifData),
		enabled: !!exifData && !isSubmitting && hasFiles,
		refetchOnWindowFocus: false,
		retry: false,
	});
	if (exifFootprintError)
		console.warn(
			'Error fetching exif footprint from API.',
			exifFootprintError
		);

	const error =
		exifError || exifFootprintError
			? { exif: exifError, exifFootprint: exifFootprintError }
			: null;

	const isLoading =
		hasFiles && !error ? exifIsLoading || exifFootprintIsLoading : false;

	return {
		error,
		isLoading: isLoading && files.length > 0,
		exifData,
		exifFootprint,
	};
};

const NewProjectContext = createContext(null);
const NewProjectProvider = ({ children }) => {
	const { isLoading: isSubmitting, progress, submit } = useSubmitProject();

	const [files, setFiles] = useState([]);
	const [mode, setMode] = useState(null);

	const isTif = useIsTif(files);
	const totalFilesSize = useTotalFilesSize(files);
	const monthlyProjectsLimit = useMonthlyProjectsLimit();
	const userStorage = useUserStorage(totalFilesSize);
	const exif = useExif(files, isTif, mode, isSubmitting);

	const methods = useForm({
		defaultValues: {
			projectName: '',
			projectDescription: '',
			projectTags: [],
			fastOrtophoto: false,
			projectModels: '',
		},
	});
	const { handleSubmit } = methods;

	const formatArray = array => array.map(item => item?.value ?? item);
	const onSubmit = formData => {
		const { error: userStorageError } = userStorage;
		const { error: monthlyProjectsLimitError } = monthlyProjectsLimit;
		if (userStorageError || monthlyProjectsLimitError) return;

		const { projectTags, projectModels, fastOrtophoto } = formData;
		const { exifData } = exif;

		const models = projectModels?.value
			? [
					{
						model_uuid: projectModels.value,
					},
				]
			: [];

		formData = {
			...formData,
			projectTags: formatArray(projectTags),
			projectModels: models,
			odmFlag: { fastOrtophoto },
		};

		submit({
			// Project Info + Advanced options
			...formData,

			// File Info
			mode,
			files,
			isTif,
			totalFilesSize,
			exifData,
		});
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<NewProjectContext.Provider
					value={{
						files,
						setFiles,
						mode,
						setMode,
						isSubmitting,
						progress,
						isTif,
						totalFilesSize,
						monthlyProjectsLimit,
						userStorage,
						exif,
						submit,
						onSubmit,
					}}>
					{children}
				</NewProjectContext.Provider>
			</form>
		</FormProvider>
	);
};

export default NewProjectProvider;

/**
 * Custom hook that provides access to the NewProjectContext.
 * @returns {Object} The NewProjectContext object.
 * @throws {Error} If used outside of a NewProjectProvider.
 */
export const useNewProject = () => {
	const context = useContext(NewProjectContext);
	if (!context)
		throw new Error(
			'useNewProject must be used within a NewProjectProvider'
		);

	return context;
};
