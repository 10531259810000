import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { differenceInDays } from 'date-fns';

import { getNotification } from '@utils/api';

import DeleteNotificationButton from './components/DeleteNotificationButton';
import GeneralNotification from './components/GeneralNotification';
import JobNotification from './components/JobNotification';

const StyledNotification = styled.li`
	border-radius: 6px;
	background-color: ${p => p.theme.colors.backgroundAccentMuted};
	display: ${p =>
		p.$deleting ? 'none' : 'block'}; // Hide the notification while deleting
	font-size: 14px;
	position: relative;
	padding: 1rem;
	position: relative;
	border-left: 5px solid
		${p =>
			p.$unread
				? p.theme.colors.green
				: p.theme.colors.backgroundAccentMuted};
`;

/**
 * Component to display a notification.
 *
 * @param {Object} notification - The notification object.
 * @param {Function} refetchAll - Function to refetch all notifications.
 * @returns {JSX.Element|null} The notification component or null if no default data is provided.
 */
const Notification = ({ notification: defaultData, refetchAll }) => {
	const [intervalMs, setIntervalMs] = useState(false);
	const [deleting, setDeleting] = useState(false);

	if (!defaultData) return null;

	const { data } = useQuery({
		queryKey: ['notification', defaultData.uuid],
		queryFn: () => getNotification(defaultData.uuid),
		initialData: defaultData,
		refetchInterval: intervalMs,
		enabled: !!intervalMs,
	});

	const notification = data?.notification || defaultData;
	const { notification_type, notification_status, job_status, created_at } =
		notification;

	const isUnread = notification_status === 'unread';
	const isJob = notification_type === 'job';
	const canBeDeleted =
		(job_status !== 'running' && job_status !== 'pending') ||
		differenceInDays(new Date(), new Date(created_at)) > 2; // Allow deletion after 2 days. because the job has probably failed

	return (
		<StyledNotification
			role="status"
			$unread={isUnread}
			$deleting={deleting}>
			{
				// Using two different components based on the notification type because of layout differences
			}
			{isJob ? (
				<JobNotification
					notification={notification}
					intervalMs={intervalMs}
					setIntervalMs={setIntervalMs}
					refetchAll={refetchAll}
				/>
			) : (
				<GeneralNotification notification={notification} />
			)}

			<DeleteNotificationButton
				uuid={notification.uuid}
				refetchAll={refetchAll}
				deleting={deleting}
				setDeleting={setDeleting}
				disabled={!canBeDeleted}
			/>
		</StyledNotification>
	);
};

export default Notification;
