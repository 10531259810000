import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter, Routes, Route } from 'react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';
import { UserProvider } from './contexts/User.context';
import { ThemeProvider } from './contexts/Theme.context';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastProvider } from './contexts/Toast.context';
import { TokenProvider } from './contexts/Token.context';

// Import Sentry configuration
import './utils/sentryConfig';

const container = document.getElementById('root');

const queryClient = new QueryClient();

const root = createRoot(container, {
	// Callback called when an error is thrown and not caught by an Error Boundary.
	onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
		console.warn('Uncaught error', error, errorInfo.componentStack);
	}),
	// Callback called when React catches an error in an Error Boundary.
	onCaughtError: Sentry.reactErrorHandler(),
	// Callback called when React automatically recovers from errors.
	onRecoverableError: Sentry.reactErrorHandler(),
});

root.render(
	<React.StrictMode>
		<TokenProvider>
			<BrowserRouter>
				<ThemeProvider>
					<QueryClientProvider client={queryClient}>
						<UserProvider>
							<ToastProvider>
								<Routes>
									<Route path="/*" element={<App />} />
								</Routes>
							</ToastProvider>
						</UserProvider>
					</QueryClientProvider>
				</ThemeProvider>
			</BrowserRouter>
		</TokenProvider>
	</React.StrictMode>
);
