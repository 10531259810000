import Draw from 'ol/interaction/Draw.js';
import { unByKey } from 'ol/Observable';

import { setOverlayInfo, createOverlay, keyboardListenOnce } from './helpers';
import { erasorMoveListener } from '@utils/map/erasorPointer';

let drawInteractions = [];
let toolEventKeys = [];
/**
 * Adds a draw interaction to the map object.
 *
 * @param {Object} options The options for the draw interaction.
 * @param {Object} options.mapObject The map object to add the interaction to.
 * @param {String} options.type The geometry type of the draw interaction.
 * @param {Object} options.style The style for the draw interaction.
 * @returns {Object} The created draw interaction.
 */
const addDrawInteraction = ({ mapObject, type, style }) => {
	const interaction = new Draw({
		type: type,
		style: style,
	});
	mapObject.addInteraction(interaction);
	return interaction;
};

/**
 * Checks if a feature is a measurement.
 *
 * @param {Object} feature The feature to check.
 * @param {string} featureType Checks if the feature is of this type.
 * @returns {boolean} Returns true if the feature is a measurement, false otherwise.
 */
const featureIsMeasurement = (feature, featureType) =>
	feature?.get('featureType') === featureType;

/**
 * Adds an eraser tool to the map object that allows for removing a specific feature by its ID.
 *
 * @param {Object} options The options for the eraser tool.
 * @param {Object} options.mapObject The map object to which the eraser tool will be added.
 * @param {Object} options.layer The layer from which the feature will be removed.
 * @param {String} options.featureType The customFeatureType of the features that are to be erased.
 * @returns {Array} An array containing the keys for the 'pointermove' and 'click' event listeners.
 */
const addErasor = ({ mapObject, layer, featureType }) => {
	const moveKey = mapObject.on('pointermove', e =>
		erasorMoveListener(e, mapObject, [featureType])
	);

	const clickKey = mapObject.on('singleclick', e => {
		const topFeatureAtPixel = mapObject.getFeaturesAtPixel(e.pixel)[0];
		if (!featureIsMeasurement(topFeatureAtPixel, featureType)) return;

		mapObject.removeOverlay(topFeatureAtPixel.get('featureOverlay'));
		layer.getSource().removeFeature(topFeatureAtPixel);
	});

	return [moveKey, clickKey];
};

/**
 * Adds a draw tool to the map.
 *
 * @param {Object} options The options for the draw tool.
 * @param {Object} options.mapObject The map object.
 * @param {Object} options.layer The layer object.
 * @param {Object} options.sketchOverlay The sketch overlay object.
 * @param {String} options.type The type of draw tool.
 * @param {Object} options.style The style of the draw tool.
 * @param {String} options.featureType The type of the features that are created. (Used to identify the feature)
 * @returns {Object} An object containing the interactions and keys.
 */
const addDrawTool = ({
	mapObject,
	layer,
	sketchOverlay,
	type,
	style,
	featureType,
}) => {
	const interaction = addDrawInteraction({
		mapObject: mapObject,
		type: type,
		style: style,
	});

	let changeKey;
	interaction.on('drawstart', e => {
		changeKey = e.feature.getGeometry().on('change', e => {
			setOverlayInfo({
				overlay: sketchOverlay,
				type: type,
				geometry: e.target,
			});
		});

		keyboardListenOnce({
			key: 'Escape',
			callback: () => {
				unByKey(changeKey);
				interaction.abortDrawing();
				sketchOverlay.setPosition(undefined);
			},
		});

		// Removes sketch when user hides Measurements layer.
		const removeSketch = () => {
			if (layer.getVisible()) return;
			unByKey(changeKey);
			interaction.abortDrawing();
			sketchOverlay.setPosition(undefined);
			document.removeEventListener('click', removeSketch);
		};
		document.addEventListener('click', removeSketch);
	});

	interaction.on('drawend', e => {
		//Cleanup
		unByKey(changeKey);
		sketchOverlay.setPosition(undefined);

		//Add overlay to map and set overlay info
		const measurementOverlay = createOverlay({
			mapObject: mapObject,
			type: type,
			className: `ol-tooltip ${layer.get('customLayerId')}Overlay`,
		});

		setOverlayInfo({
			overlay: measurementOverlay,
			type: type,
			geometry: e.feature.getGeometry(),
		});

		// Toggle overlay element visibility based on layer visibility
		layer.on('change:visible', () => {
			measurementOverlay.getElement().style.display = layer.getVisible()
				? 'block'
				: 'none';
		});

		// Set feature info and add feature to layerSource
		e.feature.setProperties({
			featureType: featureType,
			featureOverlay: measurementOverlay,
			type: type,
			style: style,
		});
		layer.getSource().addFeature(e.feature);
	});

	return {
		interactions: [interaction],
		keys: [changeKey],
	};
};

/**
 * Selects a tool based on the provided parameters.
 *
 * @param {Object} options The options for selecting the tool.
 * @param {Object} options.mapObject The map object.
 * @param {Object} options.layer The layer object.
 * @param {Object} options.sketchOverlay The sketch overlay object.
 * @param {String} options.type The type of the tool.
 * @param {Object} options.style The style object.
 * @param {String} options.featureType The type of the feature. (Used to identify the feature)
 * @returns {Object} An object containing the interactions and event keys of the selected tool.
 */
const selectTool = ({
	mapObject,
	layer,
	sketchOverlay,
	type,
	style,
	featureType,
}) => {
	if (!featureType) console.warn(`featureType is not defined.`);

	if (drawInteractions) {
		drawInteractions.forEach(interaction =>
			mapObject.removeInteraction(interaction)
		);
		drawInteractions = [];
	}

	if (toolEventKeys?.length > 0) {
		toolEventKeys.forEach(key => unByKey(key));
		toolEventKeys = [];
	}

	switch (type) {
		// If no type is defined console error.
		case undefined:
			console.warn(`Tool type is not defined.`);
			return;

		case 'Move':
			break;

		case 'Erase':
			toolEventKeys = addErasor({
				mapObject: mapObject,
				layer: layer,
				featureType: featureType,
			});
			break;

		// Defaults to OpenLayers draw interaction.
		default:
			let { interactions, keys } = addDrawTool({
				mapObject: mapObject,
				layer: layer,
				sketchOverlay: sketchOverlay,
				type: type,
				style: style,
				featureType: featureType,
			});

			drawInteractions = interactions;
			toolEventKeys = keys;
			break;
	}

	return {
		interactions: drawInteractions,
		keys: toolEventKeys,
	};
};

export default selectTool;
