import axios from 'axios';

import { deleteUserCookiesFromBrowser } from './users';
import { getRefreshToken, getToken, setToken } from '@contexts/Token.context';

const DEPLOYMENT = import.meta.env.MODE;
const BASE_API_URL = import.meta.env.VITE_APP_BASE_API_URL;

const getBaseURL = () => {
	if (BASE_API_URL) {
		return BASE_API_URL;
	}
	return DEPLOYMENT === 'production'
		? 'https://api.biodroneportal.com/api'
		: 'http://localhost:8080/api';
};

export const baseURL = getBaseURL();

export const api = axios.create({
	baseURL,
});

api.interceptors.request.use(
	config => {
		const token = getToken();

		if (token) {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${token}`,
			};
		}

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	response => response,
	async error => {
		const originalRequest = error.config;

		if (error?.response?.status === 401 && !originalRequest.sent) {
			const originalAccessToken = getToken();
			const refreshToken = getRefreshToken();

			if (!refreshToken || !originalAccessToken) {
				console.warn('No access or refresh token found in cookies!');

				deleteUserCookiesFromBrowser();

				return Promise.reject(error);
			}

			const response = await axios
				.post(baseURL + '/auth/refresh', {
					access_token: originalAccessToken,
					refresh_token: refreshToken,
				})
				.then(res => res)
				.catch(err => err);

			if (response.status === 200) {
				const newAccessToken = response.data.token ?? null;

				if (!newAccessToken) {
					console.error(
						'Did not receive a new access token from the refresh token request!',
						response
					);

					deleteUserCookiesFromBrowser();

					return Promise.reject(error);
				}

				originalRequest.sent = true;
				originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

				setToken(newAccessToken);

				return api(originalRequest);
			} else {
				deleteUserCookiesFromBrowser();

				// Check if the current URL is already the home url
				const currentURL = window.location.pathname;
				const homeURL = '/';

				if (currentURL !== homeURL) {
					window.location = homeURL;
				}
			}
		}

		return Promise.reject(error);
	}
);

export * from './helpers';
export * from './auth';
export * from './biodroneai';
export * from './jobs';
export * from './projects';
export * from './training';
export * from './tasks';
export * from './users';
export * from './organizations';
export * from './admin';
export * from './models';
export * from './stripe';
export * from './files';
export * from './filterTemplates';
export * from './subscriptions';
export * from './notifications';
export * from './evaluation';
export * from './grid';
