import { useState, useEffect } from 'react';

/**
 * Checks if the given types contain any of the allowed types.
 *
 * @param {Array<string>} types The types to check.
 * @param {Array<string>} allowedTypes The allowed types.
 * (If not provided, all types are allowed)
 *
 *
 * @returns {boolean} True if the types contain any of the allowed types, false otherwise.
 */
const hasAllowedTypes = (types, allowedTypes) => {
	if (!allowedTypes) return true;
	if (allowedTypes.length < 1) return true;
	return allowedTypes.some(type => types.includes(type));
};

/**
 * Custom hook that tracks whether a drag operation is active.
 * @param {string[]} allowedTypes Optional array of allowed data types for the drag operation.
 * (If not provided, all types are allowed)
 *
 * @returns {boolean} Returns true if a drag operation is active, otherwise false.
 */
const useDragActive = allowedTypes => {
	const [dragActive, setDragActive] = useState(false);
	useEffect(() => {
		let timeoutId;
		const handleDragOver = e => {
			if (!hasAllowedTypes(e.dataTransfer.types, allowedTypes)) return;
			if (timeoutId) clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				setDragActive(false);
			}, 100);

			if (!dragActive) setDragActive(true);
		};

		document.addEventListener('dragover', handleDragOver);
		return () => {
			document.removeEventListener('dragover', handleDragOver);
			if (timeoutId) clearTimeout(timeoutId);
			setDragActive(false);
		};
	}, []);

	return dragActive;
};

export default useDragActive;
