import { useEffect, useState, useMemo, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import Button from 'react-bootstrap/Button';

import HexagonColorFilterLayer, {
	hexagonStyles,
} from '@routes/userRoutes/projects/singleProject/components/layers/HexagonColorFilterLayer';

import SidebarTemplate from '@routes/userRoutes/projects/singleProject/components/sidebars/sidebarElements/SidebarTemplate';
import PDFExportButton from './PDFExportButton';
import FilterBox from './filterBox/FilterBox';
import Summary from './Summary';
import FilterTemplates, { CreateFilterTemplate } from './FilterTemplates';

import { useProject } from '@contexts/Project.context';
import { getHexagonFeatures } from '@api';
import HexagonDataFields from './HexagonDataFields';
import Alert from '@components/alert/Alert';

const calculatePercentage = (part, whole) => {
	const percentage = (part / whole) * 100;
	return percentage.toFixed(2);
};

const preventNegativeValue = value => {
	if (value < 0) {
		return 0;
	}
	return value;
};

const defaultColors = [
	{ r: 10, g: 120, b: 10, a: 0.7 },
	{ r: 255, g: 160, b: 0, a: 0.7 },
	{ r: 255, g: 0, b: 0, a: 0.7 },
	{ r: 0, g: 0, b: 255, a: 0.7 },
	{ r: 255, g: 0, b: 255, a: 0.7 },
];

export default function HexagonColorFilter({ sidebarId }) {
	const {
		project,
		pickedTask,
		hexagonInfo: initialHexagonInfo,
		hexagonSize: initialHexagonSize,
		isDemo,
		dispatch,
	} = useProject();

	let childRef = useRef();
	const callRemoveHcfLayer = () => {
		childRef.current.removeHcfLayer();
		dispatch({ type: 'setToolBarVisible', payload: true });
	};

	const [filters, setFilters] = useState(null);
	const [filterBoxes, setFilterBoxes] = useState(null);
	const [activeFilterTemplate, setActiveFilterTemplate] = useState(null);
	const [hexagons, setHexagons] = useState([]); // The hexagons to show on the map
	const [noMatchHexagons, setNoMatchHexagons] = useState(null);

	const [hexagonSize, setHexagonSize] = useState(initialHexagonSize);
	const [filterClasses, setFilterClasses] = useState(null);

	const { data: hexagonInfo } = useQuery({
		queryKey: [
			'hexagon_features',
			project.uuid,
			pickedTask.model_uuid,
			hexagonSize,
			filterClasses,
		],
		queryFn: () =>
			getHexagonFeatures(
				project.uuid,
				pickedTask.model_uuid,
				hexagonSize,
				filterClasses
			),
		enabled: !!project?.uuid && !!pickedTask?.model_uuid,
		refetchOnWindowFocus: false,
		initialData: initialHexagonInfo,
	});

	const defaultClasses =
		initialHexagonInfo?.metadata?.total?.detections &&
		Object.keys(initialHexagonInfo?.metadata?.total?.detections); // The default classes to show in the filter

	const calculateAreaSummary = matchingHexs => {
		const area = matchingHexs?.length * hexagonSize;
		const totalArea = isNaN(area) ? null : area;
		const areaPercentage = calculatePercentage(
			matchingHexs?.length,
			hexagons.length
		);

		return { totalArea, areaPercentage };
	};

	const addFilterBox = e => {
		e.preventDefault();
		const newBox = { ...emptyFilterBox };
		const color = defaultColors[filters.length] ?? defaultColors[0];
		newBox.color = color;
		setFilters([...filters, newBox]);
	};

	const { hexagonLayerData, hexagonRanges, emptyFilterBox } = useMemo(() => {
		// Initialize variables
		const hexagonLayerData = [];

		let emptyFilterBox = null;

		// Get the source data
		const hexagonsSource = hexagonInfo?.hexagon_data;

		// If there's no source data, return the initial values
		if (!hexagonsSource) {
			return { hexagonLayerData, hexagonRanges: null, emptyFilterBox };
		}

		const ranges = {
			smallestMeanHeight: Infinity,
			largestMeanHeight: -Infinity,
			smallestCount: Infinity,
			largestCount: -Infinity,
		};

		// Check the smallest and largest mean height and count values in the hexagons
		for (const key in hexagonsSource) {
			const hexMeta = hexagonsSource[key].metadata;
			ranges.smallestMeanHeight = Math.min(
				ranges.smallestMeanHeight,
				hexMeta.mean_height
			);
			ranges.largestMeanHeight = Math.max(
				ranges.largestMeanHeight,
				hexMeta.mean_height
			);
			ranges.smallestCount = Math.min(
				ranges.smallestCount,
				hexMeta.count
			);
			ranges.largestCount = Math.max(ranges.largestCount, hexMeta.count);
		}

		const height = {
			min: parseFloat(Math.min(ranges.smallestMeanHeight, 0).toFixed(2)),
			max: parseFloat(Math.max(ranges.largestMeanHeight, 1).toFixed(2)),
		};
		const count = {
			min: parseFloat(Math.min(ranges.smallestCount, 0).toFixed(2)),
			max: parseFloat(Math.max(ranges.largestCount, 1).toFixed(2)),
		};

		// Set the hexagon ranges
		const hexagonRanges = {
			height: {
				min: preventNegativeValue(height.min),
				max: preventNegativeValue(height.max),
			},
			count: {
				min: preventNegativeValue(count.min),
				max: preventNegativeValue(count.max),
			},
		};

		// Create an empty filter box with the smallest and largest height and count values available
		emptyFilterBox = {
			label: '',
			color: defaultColors[0],
			multiplier: 0,
			count: {
				min: hexagonRanges.count.min,
				max: hexagonRanges.count.max,
			},
			height: {
				min: hexagonRanges.height.min,
				max: hexagonRanges.height.max,
			},
		};

		if (!activeFilterTemplate) {
			setFilters([{ ...emptyFilterBox }]);
		}

		// Make array of hexagons
		hexagonLayerData.push(...Object.values(hexagonsSource));

		return { hexagonLayerData, hexagonRanges, emptyFilterBox };
	}, [hexagonInfo]);

	const matchesFilter = (hexagon, filter) => {
		const meta = hexagon.values_?.data?.metadata;

		if (!meta) return false;

		// Check if the count of the current hexagon meets the filter criteria
		const count = meta.count?.toFixed(2) ?? 0;
		const countMatch =
			count >= filter.count.min && count <= filter.count.max;

		// Check if the height of the current hexagon meets the filter criteria
		const height = meta.mean_height?.toFixed(2) ?? 0;
		const heightMatch =
			height >= filter.height.min && height <= filter.height.max;

		// Return true if both the count and height match the criteria
		return countMatch && heightMatch;
	};

	useEffect(() => {
		if (!hexagons.length || !filters) return;

		const _filterBoxes = filters.map(filter => {
			const matchingHexs = hexagons.filter(hexagon =>
				matchesFilter(hexagon, filter)
			);

			const { totalArea, areaPercentage } =
				calculateAreaSummary(matchingHexs);

			const calculation = totalArea * filter.multiplier;
			const totalCalculation = isNaN(calculation) ? null : calculation;

			return {
				...filter,
				matchedHexagons: matchingHexs.length,
				totalArea,
				areaPercentage,
				totalCalculation,
			};
		});
		setFilterBoxes(_filterBoxes);

		// Iterate over each hexagon in the hexagons array
		const noMatch = [];
		hexagons.forEach(hexagon => {
			let matched = false;

			const defaultLabel = 'No filter match';

			hexagon.setProperties({
				colors: [], // Set the default colors property to an empty array
				name: defaultLabel, // For KML export
				description: 'No filter matched', // For KML export
			});

			// Iterate over each filter in the filterBoxes array
			_filterBoxes.forEach(filter => {
				// Check if the hexagon matches the current filter
				if (matchesFilter(hexagon, filter)) {
					// If it matches, add the filter color to the hexagon colors property
					hexagon.set('colors', [
						...hexagon.get('colors'),
						filter.color,
					]);

					const noLabel = 'No label';
					let existingName = hexagon.get('name');
					let newName;

					if (existingName === defaultLabel) {
						newName = filter.label || noLabel;
					} else {
						newName =
							existingName + ', ' + (filter.label || noLabel);
					}

					// For KML export
					hexagon.set('name', newName);
					hexagon.set('description', 'Matched filters: ' + newName);

					matched = true;
				}
			});

			// Set the style of the hexagon based on the colors property
			const hexColors = hexagon.get('colors');
			hexagon.setStyle(hexagonStyles(hexColors));

			if (!matched) {
				noMatch.push(hexagon);
			}
		});

		const { totalArea, areaPercentage } = calculateAreaSummary(noMatch);

		setNoMatchHexagons({
			matchedHexagons: noMatch.length,
			multiplier: 0,
			color: { r: 0, g: 0, b: 0, a: 1 },
			totalArea,
			areaPercentage,
		});
	}, [filters, hexagons]);

	if (!filters) return null;

	return (
		<SidebarTemplate
			zindex={10}
			sidebarId={sidebarId}
			header="Hexagon Color Filter"
			closeable={true}
			align="left"
			width="large"
			onHide={callRemoveHcfLayer}>
			<div className="px-3 pb-5 pt-2">
				<FilterTemplates
					emptyFilterBox={emptyFilterBox}
					setFilters={setFilters}
					activeFilterTemplate={activeFilterTemplate}
					setActiveFilterTemplate={setActiveFilterTemplate}
					initialHexagonSize={initialHexagonSize}
					setHexagonSize={setHexagonSize}
					isDemo={isDemo}
				/>

				{filterBoxes?.length > 0 && (
					<CreateFilterTemplate
						filterBoxes={filterBoxes}
						activeFilterTemplate={activeFilterTemplate}
						setActiveFilterTemplate={setActiveFilterTemplate}
						hexagonSize={hexagonSize}
						isDemo={isDemo}
					/>
				)}

				<HexagonDataFields
					hexagonSize={hexagonSize}
					setHexagonSize={setHexagonSize}
					filterClasses={filterClasses}
					setFilterClasses={setFilterClasses}
					defaultClasses={defaultClasses}
				/>

				{hexagonInfo !== null && (
					<>
						<div className="pt-3">
							<span>Filter Boxes</span>

							<div className="d-flex flex-column gap-3">
								{filterBoxes?.map((box, index) => (
									<FilterBox
										key={`hexagon-filter-box${index}`}
										index={index}
										setFilters={setFilters}
										data={box}
										hexagonRanges={hexagonRanges}
										totalHexagons={hexagons.length}
										activeFilterTemplate={
											activeFilterTemplate?.filterBoxes?.[
												index
											]
										}
									/>
								))}
							</div>
						</div>
						<div className="d-flex flex-column justify-content-end align-items-end gap-1 mt-4">
							<Button
								onClick={addFilterBox}
								variant="success"
								className="mb-4">
								Add filter box +
							</Button>
						</div>

						{filterBoxes?.length > 0 && (
							<div className="mt-5">
								<Summary
									totalHexagons={hexagons.length}
									filterBoxes={filterBoxes}
									noMatchHexagons={noMatchHexagons}
								/>
							</div>
						)}

						<div className="mt-3 d-flex gap-2">
							<HexagonColorFilterLayer
								data={hexagonLayerData}
								setHexagons={setHexagons}
								ref={childRef}
							/>
							<PDFExportButton
								filterBoxes={filterBoxes}
								hexagonSize={hexagonSize}
							/>
						</div>
					</>
				)}

				{hexagonInfo === null && (
					<div className="mt-4">
						<Alert variant="warning">
							<p>
								No hexagon data available with selected filters
							</p>
						</Alert>
					</div>
				)}
			</div>
		</SidebarTemplate>
	);
}
