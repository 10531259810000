import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { StyledModal } from '@components/modal/ModalStyle';
import ProjectTagsSelect from '@components/projectComponents/projectTags/ProjectTagsSelect';

import { addProjectTags, deleteProjectTags, getUserTags } from '@utils/api';

const ProjectTagsModal = ({
	show = false,
	setShow,
	projectId,
	projectTitle,
	existingTags,
	callBack,
}) => {
	const { control, handleSubmit } = useForm({
		defaultValues: {
			selectedTags: existingTags.map(tag => ({ value: tag, label: tag })),
		},
	});

	const { data: userTags, isLoading: tagsLoading } = useQuery({
		queryKey: ['user_tags'],
		queryFn: getUserTags,
		retry: false,
	});
	const [error, setError] = useState(null);

	const { mutateAsync: mutateTags, isLoading: mutateLoading } = useMutation({
		mutationFn: ({ action, tags }) => {
			if (action == 'add') return addProjectTags(projectId, tags);
			if (action == 'delete') return deleteProjectTags(projectId, tags);
			else throw new Error('Invalid action');
		},
		onError: setError,
	});

	const onSubmit = async formData => {
		const { selectedTags } = formData;
		const tags = selectedTags.map(tag => tag.value);

		const tagsToAdd = tags.filter(tag => !existingTags.includes(tag));
		if (tagsToAdd.length)
			await mutateTags({ action: 'add', tags: tagsToAdd });

		const tagsToRemove = existingTags.filter(tag => !tags.includes(tag));
		if (tagsToRemove.length)
			await mutateTags({ action: 'delete', tags: tagsToRemove });

		callBack();
	};

	if (!show) return null;

	return (
		<StyledModal
			variant="dark"
			show={show}
			centered={true}
			onHide={() => {
				callBack();
				setShow(false);
			}}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>
					Edit tags for <em>{projectTitle}</em>
				</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>
					{error ? (
						<div className="alert alert-warning">
							<p className="mb-0">
								Error: {error?.message ?? 'Unknown'}
							</p>
						</div>
					) : (
						<div className="mt-3 mb-4">
							{tagsLoading ? (
								<p>Loading tags...</p>
							) : (
								<Controller
									name="selectedTags"
									control={control}
									render={({ field }) => (
										<ProjectTagsSelect
											field={field}
											defaultTags={existingTags}
											userTags={userTags}
										/>
									)}
								/>
							)}
						</div>
					)}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Cancel
					</Button>
					<Button
						type="submit"
						variant="success"
						className="m-1"
						disabled={tagsLoading || mutateLoading}>
						Save
					</Button>
				</Modal.Footer>
			</Form>
		</StyledModal>
	);
};

export default ProjectTagsModal;
