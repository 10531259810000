import { useNewProject } from '@contexts/NewProject.context';
import { useAuth } from '@contexts/User.context';
import Alert from '@components/alert/Alert';
import StorageStatus from '@components/storage/StorageStatus';
import Loader from '@components/loading/Loader';
import UpgradeStoragePlan from './UpgradeStoragePlan';

const FileStorage = () => {
	const { userStorage, monthlyProjectsLimit, totalFilesSize } =
		useNewProject();

	const { tierPro } = useAuth();

	const {
		isLoading: storageIsLoading,
		error: storageError,
		storageUsed,
		storageLimit,
		storageLimitReached,
	} = userStorage;

	const { isLoading: monthlyProjectsLimitLoading } =
		monthlyProjectsLimit ?? {};

	const isLoading = storageIsLoading || monthlyProjectsLimitLoading;

	const getVariant = () =>
		storageError || storageLimitReached ? 'danger' : 'dark';

	const getHeading = () => {
		if (storageError) return 'File Storage Error';
		if (storageLimitReached) return 'File Storage Limit Reached';
		else return 'File Storage';
	};

	const getFileStorageMessage = () =>
		storageLimitReached
			? `
				You do not have enough storage left to start this project. Delete an existing project or upgrade your plan.
			`
			: 'Actual file storage will be calculated after the files are submitted.';

	const variant = getVariant();
	const heading = getHeading();

	const fileStorageMessage = getFileStorageMessage();

	if (!tierPro) return null;
	if (isLoading)
		return (
			<Alert className="m-0" variant="dark">
				<Loader inline />
			</Alert>
		);

	return (
		<Alert className="m-0" variant={variant} heading={heading}>
			<p>{fileStorageMessage}</p>
			<StorageStatus
				projectFilesSize={totalFilesSize}
				usedStorage={storageUsed}
				totalStorage={storageLimit}
			/>
			<UpgradeStoragePlan />
		</Alert>
	);
};

export default FileStorage;
