import { useQuery } from '@tanstack/react-query';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router';

import { getProjectsDetectedOn } from '@utils/api';

import CollapsibleContent from '@components/collapsibleContent/CollapsibleContent';

import { currentTheme } from '@contexts/Theme.context';
import { useModelContext } from '@contexts/Model.context';
import { formateDate } from '@utils/helpers';
import Loader from '@components/loading/Loader';

const Detections = () => {
	const { modelUuid } = useModelContext();

	const { data: projects, isLoading } = useQuery({
		queryKey: ['projects', modelUuid],
		queryFn: () => getProjectsDetectedOn(modelUuid),
		enabled: !!modelUuid,
	});

	return (
		<Card body>
			<Card.Title>Projects detected on</Card.Title>
			{isLoading && (
				<div className="d-flex  gap-3 py-3">
					<Loader inline /> Loading data
				</div>
			)}
			{!isLoading && <DetectionsList projects={projects} />}
		</Card>
	);
};
export default Detections;

const DetectionsList = ({ projects }) => {
	if (!projects || projects?.length < 1)
		return (
			<p className="m-0 text-muted">
				This model has not been used for detection on any projects yet.
			</p>
		);

	return (
		<CollapsibleContent
			buttonBgColor={currentTheme.colors.modal}
			minHeight={200}>
			<ul className="list-group list-group-flush">
				{projects.map((project, index) => (
					<DetectionItem
						key={`project-item-${project.project_uuid}-${index}`}
						project={project}
					/>
				))}
			</ul>
		</CollapsibleContent>
	);
};

const DetectionItem = ({ project }) => {
	if (!project) return null;
	const {
		project_title,
		project_uuid,
		multimodel_uuid,
		created_at,
		model_info,
	} = project;

	const { model_uuid } = model_info || {};
	const model = multimodel_uuid ? multimodel_uuid : model_uuid;

	return (
		<li className="list-group-item bg-transparent">
			<Link
				to={`/projects/${project_uuid}?model=${model}`}
				className="inherit d-block">
				{project_title}
			</Link>

			<div className="d-flex gap-3">
				<small className="text-muted">
					{formateDate(created_at)}
					{multimodel_uuid && <> - Used in multimodel</>}
				</small>
			</div>
		</li>
	);
};
