export const getTrainingDetailsExplanations = () => ({
	updated_at: {
		label: 'Last Updated',
		content: 'The date the model was last updated',
	},
	training_job_date: {
		label: 'Training Date',
		content: 'The date the training job was started',
	},
	training_id: {
		label: 'Model version',
		content: 'The version of the model',
	},
	f1_score: {
		label: 'F1 score',
		content: (
			<>
				<p>
					The <strong>F1 score</strong> is a way to measure how good a
					model is at making predictions.
				</p>
				<p>
					It is the harmonic mean of <strong>precision</strong> and{' '}
					<strong>recall</strong>, balancing both to give a single
					score.
				</p>
				<p className="mb-0">
					The score ranges from <strong>0</strong> to{' '}
					<strong>1</strong>, including all decimals in between.
					<br />• <strong>0</strong> indicates the worst performance
					(the model fails completely).
					<br />• <strong>1</strong> indicates perfect precision and
					recall (the model makes no mistakes).
				</p>
			</>
		),
	},
	precision: {
		label: 'Precision',
		content: (
			<>
				<p>
					<strong>Precision</strong> tells us how many of the
					predicted positive results are actually correct.
				</p>
				<p className="mb-0">
					<strong>Precision</strong> ranges from <strong>0</strong> to{' '}
					<strong>1</strong>, including all decimals in between.
					<br />• <strong>0</strong> indicates that none of the
					predicted positive results are correct.
					<br />• <strong>1</strong> Indicates that all of the
					predicted positive results are correct.
				</p>
			</>
		),
	},
	maps: {
		label: 'Maps',
		content: (
			<>
				The mean average <strong>precision</strong> score of the model.
			</>
		),
	},
	recall: {
		label: 'Recall',
		content: (
			<>
				<strong>Recall</strong> tells us how many of the actual positive
				results were correctly predicted by the model.
			</>
		),
	},
	accuracy: {
		label: 'Accuracy',
		content: (
			<>
				<strong>Accuracy</strong> is how many predictions the model got
				right out of all the predictions it made.
			</>
		),
	},
	number_of_annotations: {
		label: 'Annotations',
		content: 'The number of annotations used to train the model',
	},
	number_of_background_annotations: {
		label: 'Background Images',
		content: 'The number of background images used when training the model',
	},
});
