import { useState } from 'react';

import { Placeholder, Card } from 'react-bootstrap';

import AdvancedSelect from '@components/form/Select';
import TrainingdataDetailsList from './TrainingdataDetailsList';
import EvaluationDetailsList from './EvaluationDetailsList';
import TrainingDescription from './TrainingDescription';
import TrainingImages from './TrainingImages';
import RollBackModelVersion from './RollBackModelVersion';

import { useModelContext } from '@contexts/Model.context';

const ModelTrainings = () => {
	const { trainings, loadingTrainings } = useModelContext();

	const reversedTrainings = trainings?.slice().reverse();
	const latestTraining = reversedTrainings?.[0];

	const defaultSelected = {
		label: `Model v. ${latestTraining?.training_id}`,
		value: latestTraining,
	};

	return (
		<Card body>
			<Card.Title>Model Version Details</Card.Title>

			{loadingTrainings && <CardPlaceholder />}

			{!loadingTrainings && !trainings?.length && (
				<Card.Text className="text-muted">
					No training data available.
				</Card.Text>
			)}

			{trainings?.length > 0 && (
				<CardContent
					reversedTrainings={reversedTrainings}
					defaultSelected={defaultSelected}
				/>
			)}
		</Card>
	);
};
export default ModelTrainings;

const CardPlaceholder = () => (
	<Placeholder as={Card.Text} animation="glow" className="mb-4">
		<Placeholder xs={8} size="lg" className="mb-4 mt-2" />
		<Placeholder xs={5} size="lg" /> <Placeholder xs={5} size="lg" />
	</Placeholder>
);

const CardContent = ({ reversedTrainings, defaultSelected }) => {
	const [selected, setSelected] = useState(defaultSelected);

	if (!selected) return null;

	const allowRollback =
		reversedTrainings.length > 1 &&
		defaultSelected.label !== selected.label;

	return (
		<>
			<div className="row row-gap-4 pb-2">
				<div className="col-12 col-md-6">
					<AdvancedSelect
						id="model-versions"
						label="Selected version"
						options={reversedTrainings.map(training => ({
							label: `Model v. ${training.training_id}`,
							value: training,
						}))}
						onChange={setSelected}
						value={selected}
					/>

					{allowRollback && (
						<RollBackModelVersion
							version={selected?.value?.training_id}
						/>
					)}

					<TrainingDescription {...selected?.value} />
				</div>

				<div className="col-12 col-md-6"></div>

				<div className="col-12 col-md-6">
					<TrainingdataDetailsList {...selected?.value} />
				</div>

				<div className="col-12 col-md-6">
					<EvaluationDetailsList {...selected?.value} />
				</div>

				<div className="col-12">
					<TrainingImages {...selected?.value} />
				</div>
			</div>
		</>
	);
};
