import Select from 'ol/interaction/Select';

import { MODEL_TYPE } from '@utils/constants';
import { getObjectEraseData } from './object.draw';
import { getPolygonEraseData } from './polygon.draw';
import {
	pointerMoveListener,
	removeErasorHighlightedFeature,
} from './erasorPointer';

import {
	activeSquareRef,
	isFeatureIntersectingSquare,
} from './annotation/annotation.interaction';

let selectInteraction;
let eraseDataFunction;
let pointerMoveRef;

export const startErasor = mapReference => {
	if (!selectInteraction) return;

	// Add trash can cursor
	pointerMoveRef = evt => {
		pointerMoveListener(evt, mapReference);
	};
	mapReference.on('pointermove', pointerMoveRef);

	mapReference.addInteraction(selectInteraction);

	const { interactionsToDeactivate } = eraseDataFunction();

	interactionsToDeactivate.forEach(interaction => {
		interaction.setActive(false);
	});
};

export const stopErasor = mapReference => {
	if (!eraseDataFunction) return;

	const { interactionsToDeactivate } = eraseDataFunction();

	// remove trash can cursor
	mapReference.un('pointermove', pointerMoveRef);
	mapReference.getTargetElement().style.cursor = 'auto';

	if (selectInteraction) {
		mapReference.removeInteraction(selectInteraction);
	}

	interactionsToDeactivate.forEach(interaction => {
		// if interaction has function setActive
		if (interaction && typeof interaction.setActive === 'function') {
			interaction.setActive(true);
		}
	});

	resetActiveErasor(mapReference);
};

export const addErasorInteraction = (modelType, mapReference) => {
	console.log('Creating erasor interaction');
	if (selectInteraction) {
		// Remove existing select interaction
		mapReference.removeInteraction(selectInteraction);
	}

	if (modelType === MODEL_TYPE.OBJECT_DETECTION) {
		eraseDataFunction = getObjectEraseData;
	}
	if (modelType === MODEL_TYPE.SEGMENTATION) {
		eraseDataFunction = getPolygonEraseData;
	}

	const { selectedLayer, deleteFunction } = eraseDataFunction();

	selectInteraction = new Select({
		layers: [selectedLayer],
	});

	selectInteraction.on('select', function (e) {
		const selected = e.selected?.[0];
		if (selected) {
			const isOverlapping = isFeatureIntersectingSquare(
				selected,
				activeSquareRef
			);

			isOverlapping && deleteFunction(selected);
			selectInteraction.getFeatures().clear();
		}
	});
};

export const removeErasorInteraction = mapReference => {
	if (selectInteraction) {
		console.log('Removing erasor interaction');
		mapReference.removeInteraction(selectInteraction);

		selectInteraction = null;
		eraseDataFunction = null;
	}

	if (pointerMoveRef) {
		mapReference.un('pointermove', pointerMoveRef);
		pointerMoveRef = null;
	}

	resetActiveErasor(mapReference);
};

const resetActiveErasor = mapReference => {
	// Ensure the pointer style is set back to normal so we know the erasor icon is gone
	const mapTarget = mapReference.getTargetElement();
	if (mapTarget) {
		mapTarget.style.cursor = 'default';
	}
	// Remove any highlighted feature
	removeErasorHighlightedFeature();
};
