import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { AiOutlineDelete } from 'react-icons/ai';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Input from '@components/form/Input';
import ProjectTagsSelect from '@components/projectComponents/projectTags/ProjectTagsSelect';

import View from '@components/layout/views/View';

import ArchiveProjectDialog from '@components/archiveProject/ArchiveProject';

import { getProject, updateProject, getUserTags } from '@utils/api';
import { useAuth } from '@contexts/User.context';
import Loader from '@components/loading/Loader';

const StyledActionsWrapper = styled.div`
	margin-top: calc(var(--bs-gutter-x) * 2);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const EditProjectForm = ({
	project,
	userTags,
	setAlertMessage,
	setShowArchiveModal,
	refetch,
}) => {
	if (!project || !userTags) return null;

	const { project_uuid } = useParams();
	const { tierTrial } = useAuth();
	const navigate = useNavigate();

	const { title, description, tags } = project;
	const { control, handleSubmit } = useForm({
		defaultValues: {
			title,
			description,
			tags: tags.map(tag => ({ value: tag, label: tag })),
		},
	});

	const { mutate: mutateProject, isLoading: mutateIsLoading } = useMutation({
		mutationFn: data => updateProject({ uuid: project_uuid, ...data }),
		onSuccess: () => {
			setAlertMessage({
				variant: 'success',
				text: 'Project was updated',
			});
			refetch();
		},
		onError: () =>
			setAlertMessage({
				variant: 'danger',
				text: 'Project was not updated. Try again later.',
			}),
	});

	const onSubmit = data => {
		setAlertMessage(null);
		const { tags: tagObjects } = data;
		data = {
			...data,
			tags: tagObjects.map(tag => tag?.value ?? tag),
		};

		mutateProject(data);
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Controller
				name="title"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						type="text"
						label="Project Name"
						required
					/>
				)}
			/>

			<Controller
				name="description"
				control={control}
				render={({ field }) => (
					<Input {...field} type="text" label="Project Description" />
				)}
			/>
			<Controller
				name="tags"
				control={control}
				render={({ field }) => (
					<ProjectTagsSelect
						field={field}
						defaultTags={tags}
						userTags={userTags}
					/>
				)}
			/>

			<StyledActionsWrapper>
				<div>
					<Button
						type="button"
						variant="danger"
						size="sm"
						className="d-flex align-items-center"
						disabled={tierTrial}
						onClick={() => setShowArchiveModal(true)}>
						<AiOutlineDelete className="me-1" aria-hidden="true" />
						<span>Delete project</span>
					</Button>
				</div>

				<div>
					<Button
						type="button"
						variant="secondary"
						className="m-1"
						onClick={() => navigate(-1)}>
						Back
					</Button>
					<Button
						type="submit"
						variant="success"
						className="m-1"
						disabled={mutateIsLoading}>
						{mutateIsLoading ? 'Saving...' : 'Save'}
					</Button>
				</div>
			</StyledActionsWrapper>
		</Form>
	);
};

const EditProject = () => {
	const { project_uuid } = useParams();
	const navigate = useNavigate();

	const [showArchiveModal, setShowArchiveModal] = useState(false);
	const [alertMessage, setAlertMessage] = useState(null);

	const {
		data: project,
		isLoading: projectIsLoading,
		refetch,
		isError,
	} = useQuery({
		queryKey: ['edit_project', project_uuid],
		queryFn: async () => await getProject(project_uuid),
		enabled: !!project_uuid,
	});

	const { data: userTags, isLoading: userTagsIsLoading } = useQuery({
		queryKey: ['user_tags'],
		queryFn: async () => await getUserTags(),
		enabled: !!project,
		retry: false,
	});

	if (projectIsLoading || userTagsIsLoading) {
		return (
			<View>
				<Loader message="Loading project" />
			</View>
		);
	}

	return (
		<View
			maxWidth={550}
			title={
				project
					? `Edit project details for ${project.title}`
					: 'Error loading project'
			}>
			{!isError && (
				<>
					{alertMessage && (
						<Alert variant={alertMessage.variant}>
							{alertMessage.text}
						</Alert>
					)}

					<EditProjectForm
						project={project}
						userTags={userTags}
						setAlertMessage={setAlertMessage}
						setShowArchiveModal={setShowArchiveModal}
						refetch={refetch}
					/>
				</>
			)}

			{showArchiveModal && (
				<ArchiveProjectDialog
					callBack={success => {
						setShowArchiveModal(false);
						if (success) navigate(-1);
					}}
					project={{
						id: project_uuid,
						title: project?.title,
					}}
				/>
			)}
		</View>
	);
};

export default EditProject;
