import { forwardRef } from 'react';
import styled from 'styled-components';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import { FieldWrapper } from './CommonStyles';
import HelperText from './HelperText';

const StyledInput = styled(Form.Control)`
	background-color: ${props => props.theme.colors.backgroundInput};
	border: 1px solid ${props => props.theme.colors.borderInput};
	color: ${props => props.theme.colors.textColor};
	color-scheme: dark;
	min-width: ${props => props.minwidth || 'auto'};

	&:focus {
		background-color: ${props => props.theme.colors.modal};
		border: none;
		color: ${props => props.theme.colors.textColor};
	}
`;

// https://react-bootstrap.github.io/docs/forms/form-text
const Input = forwardRef(
	(
		{
			label,
			placeholder,
			labelProps,
			noWrap = false,
			helperText = null,
			id = null,
			...props
		},
		ref
	) => {
		const field = (
			<Form.Group>
				{label ? (
					<FloatingLabel
						label={label}
						aria-label={label}
						{...labelProps}>
						<StyledInput
							ref={ref}
							aria-describedby={
								helperText && id ? `help-${id}` : null
							}
							placeholder={placeholder ?? label}
							id={id}
							{...props}
						/>
					</FloatingLabel>
				) : (
					<StyledInput
						ref={ref}
						id={id}
						aria-describedby={
							helperText && id ? `help-${id}` : null
						}
						placeholder={placeholder ?? label}
						{...props}
					/>
				)}

				{helperText && id && (
					<HelperText id={`help-${id}`}>{helperText}</HelperText>
				)}
			</Form.Group>
		);

		if (noWrap) return field;

		return <FieldWrapper>{field}</FieldWrapper>;
	}
);

export default Input;
