import Container from 'react-bootstrap/Container';
import styled from 'styled-components';

import Breadcrumbs from '@components/breadcrumbs/Breadcrumbs';

export const StyledView = styled(Container)`
	color: ${props => props.theme.colors.textColor};
	padding-top: 2.5rem;
	height: 100%;
	${p =>
		p.maxwidth &&
		`
		max-width: ${p.maxwidth}px;
	`}
`;

const StyledHeadingWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 1rem;

	@media (max-width: 767.98px) {
		grid-template-columns: 1fr auto;
		margin-bottom: 1.5rem;

		& > :nth-child(1) {
			grid-column: 1 / 3;
		}
		& > :nth-child(2) {
			justify-self: start;
			grid-row: 2 / 3;
		}
	}
`;

const View = ({
	title = '',
	maxWidth = null,
	headingComponent = null,
	breadcrumbs,
	children,
}) => {
	return (
		<StyledView maxwidth={maxWidth}>
			{breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
			<StyledHeadingWrapper>
				<h1 className="mb-4">{title}</h1>
				{headingComponent && headingComponent}
			</StyledHeadingWrapper>
			{children}
		</StyledView>
	);
};

export default View;
