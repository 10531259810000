import { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'react-bootstrap';

import { verifyUserEmail } from '@utils/api';

import View from '@components/layout/views/View';
import Loader from '@components/loading/Loader';
import Alert from '@components/alert/Alert';

export default function VerifyUser() {
	const [searchParams] = useSearchParams();
	const email = searchParams.get('email');
	const token = searchParams.get('token');

	if (!email || !token) {
		return (
			<View maxWidth={550} title="Email verification">
				<VerifyUserMissingParams email={email} token={token} />
			</View>
		);
	}

	return (
		<View maxWidth={550} title="Email verification">
			<VerifyUserWithEmailAndToken email={email} token={token} />
		</View>
	);
}

function VerifyUserWithEmailAndToken({ email, token }) {
	const {
		mutate: verify,
		isLoading,
		isError,
		error,
		isSuccess,
		data,
	} = useMutation({
		mutationFn: () => verifyUserEmail(email, token),
		retry: 1,
	});

	if (isLoading) {
		return <Loader />;
	}

	if (isError) {
		console.error('Could not verify user', error);
		return <VerifyUserError error={error} />;
	}

	if (isSuccess) {
		return <VerifyUserSuccess data={data} />;
	}

	return (
		<VerifyUserForm email={email} verify={verify} isLoading={isLoading} />
	);
}

function VerifyUserMissingParams({ email, token }) {
	let missingParams = '';

	if (!email && !token) {
		missingParams = 'token and email';
	} else if (!email) {
		missingParams = 'email';
	} else if (!token) {
		missingParams = 'token';
	}

	return (
		<Alert variant="danger">
			Could not verify email. The url is missing {missingParams}.
		</Alert>
	);
}

function VerifyUserForm({ verify, email, isLoading }) {
	return (
		<>
			<p>
				Please verify your email address <em>{email}</em> by clicking
				the button below.
			</p>
			<Button variant="success" onClick={verify} disabled={isLoading}>
				Verify email
			</Button>
		</>
	);
}

function VerifyUserError({ error }) {
	console.error('Could not verify user', error);

	return (
		<Alert variant="danger">
			Could not verify email. Invalid token or email.
		</Alert>
	);
}

function VerifyUserSuccess({ data }) {
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate('/login');
		}, 5000);
	}, []);

	return (
		<Alert variant="success">
			<p className="mb-0">
				Email verified successfully.
				<br /> <Link to={'/login'}>Click here to log in</Link> or wait
				to be redirected.
			</p>
		</Alert>
	);
}
