import { useProject } from '@contexts/Project.context';
import PopoverPicker from '@components/popoverPicker/PopoverPicker';
import useDebounce from '@hooks/useDebounce';

const ModifyPoint = ({ id }) => {
	const { colorOptions, colorScheme, dispatch } = useProject();

	const defaultColor =
		colorScheme[id] && colorScheme[id].color
			? colorScheme[id].color
			: colorOptions[id];

	const changeColor = useDebounce(color => {
		dispatch({
			type: 'setColorScheme',
			payload: {
				...colorScheme,
				[id]: {
					...colorScheme[id],
					color,
				},
			},
		});
	}, 300); // 300ms debounce time

	return (
		<PopoverPicker
			color={defaultColor}
			onChange={changeColor}
			popDirection="right"
		/>
	);
};

export default ModifyPoint;
