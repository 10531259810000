import { Link } from 'react-router';
import styled from 'styled-components';

import { useNewProject } from '@contexts/NewProject.context';
import Alert from '@components/alert/Alert';
import SubscriptionsAvailableForUser from '@components/subscription/SubscriptionsAvailableForUser';

const Wrapper = styled.div`
	display: grid;
	row-gap: 3rem;

	& > * {
		max-width: fit-content;
	}
`;

const ProjectLimitWarning = () => {
	const { monthlyProjectsLimit } = useNewProject();
	const { projectLimit, daysLeft } = monthlyProjectsLimit;

	return (
		<Wrapper>
			<Alert
				variant="warning"
				heading={`Limit of ${projectLimit} projects reached`}>
				<p>
					Your subscription is limited to creating{' '}
					<strong>{projectLimit} projects</strong> each billing
					period. <br />
					Please note that deleted projects are also counted towards
					this limit.
					<br />
					<Link to="/subscription" className="inherit">
						See details of your usage in the subscription overview.
					</Link>
				</p>

				<p>
					Please wait{' '}
					<strong>
						{daysLeft} {daysLeft > 1 ? 'days' : 'day'}
					</strong>{' '}
					for your subscription to renew,
					<br /> or choose a subscription with a higher limit from the
					options below.
				</p>
			</Alert>

			<div>
				<h2 className="h1 mb-4">Available Subscriptions</h2>
				<SubscriptionsAvailableForUser
					filterProductsBy={{
						monthly_project_limit: projectLimit,
					}}
				/>
			</div>
		</Wrapper>
	);
};

export default ProjectLimitWarning;
