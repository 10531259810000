import { api } from './index.js';

export const postTileData = async (
	projectId,
	taskId,
	currentTileName,
	data
) => {
	const json = data; /*JSON.stringify(postData)*/

	try {
		const res = await api.post(
			`/training/annotations/${projectId}/${taskId}/${currentTileName}`,
			json
		);
		return res.data;
	} catch (err) {
		return err.response;
	}
};

export const initTraining = async ({ projectUuid = 'dummy', modelId }) => {
	// Dummy projectUuid is a temporary solution to avoid breaking the API
	const { data } = await api.get(`/training/train/${projectUuid}/${modelId}`);

	return data;
};
