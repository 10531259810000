/**
 * Formats the mode string by converting it to title case.
 *
 * @param {string} mode - The mode string to be formatted.
 * @returns {string} The formatted mode string.
 *
 * @example
 * ```js
 * 	'test_string' => 'Test String'
 * ```
 */
export const formatMode = mode =>
	mode
		?.split('_')
		?.map(string => `${string[0].toUpperCase()}${string.slice(1)}`)
		?.join(' ') || '';

/**
 * Splits a MIME type string and returns the last part.
 *
 * @param {string} mimeType The MIME type to split.
 * @returns {string} The last part of the MIME type.
 *
 * @example
 * ```js
 * 	'x/y' => 'y'
 * ```
 *
 */
export const mimeSplit = mimeType => mimeType.split('/').pop();

/**
 * Calculates the sum of file sizes in an array of files.
 *
 * @param {Array} files The array of files.
 * @returns {number} The sum of file sizes in bytes.
 */
export const getTotalFilesSize = files =>
	files.reduce((accumulator, file) => accumulator + file.size, 0);

/**
 * Converts bytes to megabytes.
 *
 * @param {number} bytes The number of bytes to convert.
 * @returns {number} The converted value in megabytes.
 */
export const convertToMegabytes = bytes => bytes / 1024 / 1024;

/**
 * Formats the area in hectares.
 *
 * @param {number} area The area in square meters.
 * @returns {string} The formatted area in hectares.
 */
export const formatArea = area => `${parseFloat((area / 10000).toFixed(2))} ha`;

/**
 * Formats a given overlap value as a percentage string.
 *
 * @param {number} overlap - The overlap value to format.
 * @returns {string} The formatted overlap percentage string.
 */
export const formatOverlap = overlap => `${overlap.toFixed(0)}%`;

/**
 * Linearly interpolates between two values.
 *
 * @param {number} start - The start value.
 * @param {number} end - The end value.
 * @param {number} position - The interpolation factor (0 to 1).
 * @returns {number} The interpolated value.
 */
const lerp = (start, end, position) => (1 - position) * start + position * end;

/**
 * Linearly interpolates between two points.
 *
 * @param {Object} point1 The first point with x and y coordinates.
 * @param {number} point1.x The x coordinate of the first point.
 * @param {number} point1.y The y coordinate of the first point.
 * @param {Object} point2 The second point with x and y coordinates.
 * @param {number} point2.x The x coordinate of the second point.
 * @param {number} point2.y The y coordinate of the second point.
 * @param {number} position The interpolation factor (0 to 1).
 * @returns {Object} The interpolated point with x and y coordinates.
 */
const lerpPoint = (point1, point2, position) => {
	const x = lerp(point1.x, point2.x, position);
	const y = lerp(point1.y, point2.y, position);

	return { x, y };
};

/**
 * Calculates the extent of a footprint polygon.
 *
 * @param {Array} footprint The footprint polygon to calculate the extent for.
 * @returns {Array|null} The extent of the footprint polygon as an array [minX, minY, maxX, maxY].
 */
export const getFootprintExtent = footprint => {
	const initialState = {
		minX: Infinity,
		minY: Infinity,
		maxX: -Infinity,
		maxY: -Infinity,
	};

	const { minX, minY, maxX, maxY } =
		footprint.flat().reduce((accumulator, { x, y }) => {
			if (x < accumulator.minX) accumulator.minX = x;
			if (x > accumulator.maxX) accumulator.maxX = x;
			if (y < accumulator.minY) accumulator.minY = y;
			if (y > accumulator.maxY) accumulator.maxY = y;
			return accumulator;
		}, initialState) || {};

	return [minX, minY, maxX, maxY];
};

/**
 * Smooths the corners of the given footprint / an array of points with x & y coordinates.
 *
 * @param {Array<{x: number, y: number}>} points The array of points representing the footprint.
 * @param {number} accuracy The accuracy of the smoothing, determines the number of interpolated points. (0 to 1)
 * @param {number} smoothingFactor The factor by which to smooth the corners. (0 to 1)
 * @returns {Array<{x: number, y: number}>} The array of points with smoothed corners.
 */
export const smoothFootprintCorners = (points, accuracy, smoothingFactor) => {
	let pointsCopy = [...points];
	pointsCopy.pop();

	const smoothedCornerPoints = pointsCopy.reduce(
		(accumulator, currentPoint, index) => {
			const previousPoint =
				pointsCopy[index - 1] ?? pointsCopy[pointsCopy.length - 1];
			const nextPoint = pointsCopy[index + 1] ?? pointsCopy[0];

			const startCornerPoint = lerpPoint(
				currentPoint,
				previousPoint,
				smoothingFactor
			);
			const endCornerPoint = lerpPoint(
				currentPoint,
				nextPoint,
				smoothingFactor
			);

			for (let i = 0; i < 1; i += accuracy) {
				const a = lerpPoint(startCornerPoint, currentPoint, i);
				const b = lerpPoint(currentPoint, endCornerPoint, i);
				const smoothedCornerPoint = lerpPoint(a, b, i);

				accumulator.push(smoothedCornerPoint);
			}

			return accumulator;
		},
		[]
	);

	return [...smoothedCornerPoints, smoothedCornerPoints[0]];
};
