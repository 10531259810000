import pLimit from 'p-limit';
import { api, baseURL, Upload } from './index.js';

export const getProjects = async ({
	tags = null,
	offset = 0,
	limit = 100,
	search = '',
}) => {
	const params = {};

	if (tags?.length > 0) params.tags = tags.join(',');
	if (offset) params.offset = offset;
	if (search) params.search = search;
	params.limit = limit;

	const { data } = await api.get('/projects', { params });
	return data;
};

export const getDemoProjects = async () => {
	const { data } = await api.get('/projects/demo_projects');
	return data;
};

export const getProject = async project_id => {
	const { data } = await api.get(`/projects/${project_id}`);
	return data;
};

export const getAnnotations = async project_id => {
	try {
		const res = await api.get(`/project/${project_id}/annotations`);
		return res.data;
	} catch (err) {
		return err.response;
	}
};

export const getFeatures = async (projectId, taskName, jobId, feature_type) => {
	try {
		const res = await api.get(
			`/projects/${projectId}/${taskName}/${jobId}/features/${feature_type}`
		);
		return res.data;
	} catch (err) {
		return err.response;
	}
};

export const getMasterFeatures = async (projectId, taskId, feature_type) => {
	const { data } = await api.get(
		`/projects/${projectId}/${taskId}/features/${feature_type}`
	);
	return data;
};

export const deleteMasterFeatures = async (projectId, model_uuid) => {
	const { data } = await api.delete(
		`/projects/${projectId}/${model_uuid}/delete_master_features`
	);
	return data;
};

export const getMasterFeaturesTrainingData = async (
	projectId,
	taskId,
	feature_type
) => {
	const { data } = await api.get(
		`/projects/${projectId}/${taskId}/features/${feature_type}`
	);
	return data;
};

export const getHexagonFeatures = async (
	projectId,
	taskId,
	resolution,
	class_names = null
) => {
	const res = await api.post(
		`/projects/${projectId}/hexagons/${taskId}/${resolution}`,
		class_names
	);

	// The server returns 204 and text if there are no features.
	// I want to return null in that case.
	if (res.status === 204) return null;

	const { data } = res;

	return data;
};

/**
 * Uploads multiple images to S3 in bulk.
 *
 * @param {File[]} files Array of files to be uploaded.
 * @param {string} projectId The ID of the project to which the files belong.
 * @param {function} setProgress Callback function to update the upload progress.
 * @throws {Error} Throws an error if no files are provided.
 * @returns {Promise<boolean>} Returns true if all files are successfully uploaded.
 */
export const bulkUploadImagesToS3 = async ({
	files,
	projectId,
	setProgress,
}) => {
	if (!files?.length) throw new Error('No files to upload');
	if (files.length == 1) {
		/**
		 * use new uploadclass to upload multipart here
		 */
		const file = files[0];
		const upload = new Upload({
			fileRef: file,
			projectId: projectId,
			updateProgress: progress => setProgress(progress),
		});

		console.log('%c⬆️ ' + file.name, 'color: #EABDC4');

		await upload.start();
		return true;
	}

	let sumProgress = 0;
	const promises = [];
	const limit = pLimit(5);
	for (const file of files) {
		promises.push(
			limit(async () => {
				const upload = new Upload({
					fileRef: file,
					projectId: projectId,
					updateProgress: progress => {
						sumProgress += progress;
						setProgress(sumProgress / files.length);
					},
				});

				console.log('%c⬆️ ' + file.name, 'color: #EABDC4');
				await upload.start();
			})
		);
	}
	await Promise.all(promises);

	files = [];
	return true;
};

export const getProjectProgress = async projectId => {
	const url = `/projects/${projectId}/progress`;
	try {
		const res = await api.get(url);
		return res.data;
	} catch (err) {
		return err.response;
	}
};

export const createProject = async data => {
	const res = await api.post('/projects', data);

	return res.data;
};

export const updateProject = async ({ uuid, title, description, tags }) => {
	const { data } = await api.post(`/projects/${uuid}/update`, {
		title,
		description,
		tags,
	});
	return data;
};

export const archiveProject = async project_uuid => {
	const { data } = await api.delete(`/projects/${project_uuid}`);
	return data;
};

export const addProjectTags = async (project_id, tags) => {
	const { data } = await api.post(`/projects/${project_id}/tags`, tags);
	return data;
};

export const deleteProjectTags = async (project_id, tags) => {
	const { data } = await api.delete(
		`${baseURL}/projects/${project_id}/tags`,
		{
			data: tags,
		}
	);
	return data;
};

export const getImageStatistics = async ({ exifData, isTif, imageMode }) => {
	const urlPath = isTif ? 'tif' : 'png?image_mode=' + imageMode;
	const body = isTif ? exifData[0] : exifData;

	const { data } = await api.post(
		`/projects/exif/image_statistics/${urlPath}`,
		body
	);

	return data;
};

export const updateProjectWithS3Usage = async (project_id, is_orthophoto) => {
	const { data } = await api.post(`/projects/${project_id}/update_s3_usage`, {
		is_orthophoto,
	});

	return data;
};
