import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { useNewProject } from '@contexts/NewProject.context';
import { useAuth } from '@contexts/User.context';

// Helpers
import { formatMode } from './utils/helpers';

// JSX
import View from '@components/layout/views/View';
import Loader from '@components/loading/Loader';
import ProjectLimitWarning from './components/ProjectLimitWarning';
import Step from './components/Step';
import SelectProjectType from './components/SelectProjectType';
import AddProjectDetails from './components/AddProjectDetails';
import UploadProjectFiles from './components/uploadProjectFiles/UploadProjectFiles';
import UploadProgressModal from './components/UploadProgressModal';
import AdvancedActions from './components/uploadProjectFiles/components/AdvancedActions';
import FormActions from './components/uploadProjectFiles/components/FormActions';

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
	padding-bottom: 2.5rem;
`;

const NewProject = () => {
	const { monthlyProjectsLimit, mode } = useNewProject();
	const { watch } = useFormContext();
	const { isEnterprise, tierPro, currentUser } = useAuth();

	const { active_org_name: activeOrgName } = currentUser ?? {};

	const projectName = watch('projectName');

	const viewTitle = isEnterprise
		? `Create new project for ${activeOrgName}`
		: 'Create new project';

	const { isLoading: monthlyProjectsLimitIsLoading, projectLimitReached } =
		monthlyProjectsLimit ?? {};

	if (monthlyProjectsLimitIsLoading && tierPro)
		return (
			<View title="Create new project">
				<Loader />
			</View>
		);

	if (!monthlyProjectsLimitIsLoading && projectLimitReached)
		return (
			<View title="Create new project">
				<ProjectLimitWarning />
			</View>
		);

	return (
		<View title={viewTitle}>
			<Step step="1" header="Choose Project Type">
				<SelectProjectType />
			</Step>

			<Step
				step="2"
				header={`Add ${formatMode(mode)} Project Details`}
				active={!!mode}>
				<AddProjectDetails />
			</Step>

			<Step step="3" header="Upload Project Files" active={!!projectName}>
				<UploadProjectFiles />
				<Grid>
					<AdvancedActions />
					<FormActions />
				</Grid>
			</Step>

			<UploadProgressModal />
		</View>
	);
};

export default NewProject;
