import styled from 'styled-components';
import { useSearchParams } from 'react-router';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { PROJECT_MODE } from '@utils/constants';

const StyledTabs = styled(Tabs)`
	.nav-link {
		color: ${props => props.theme.colors.textColor};
		opacity: 0.8;
		transition: all 0.2s;
		&:hover {
			opacity: 1;
		}

		&.active {
			background: none;
			opacity: 1;
			border-bottom-color: ${props => props.theme.colors.background};
		}
	}
	~ .tab-content {
		padding-top: 1.5rem;
	}
`;

const ModelTypesTabs = ({modelsContentComponent: ModelsContentComponent}) => {

	const [searchParams, setSearchParams] = useSearchParams();
	const defaultKey = searchParams.get('tab') ?? PROJECT_MODE.ORTHOPHOTO;

    const onSelect = key => {
        searchParams.set('tab', key);
        setSearchParams(searchParams);
    };

	return (
		<>
			<StyledTabs
				defaultActiveKey={defaultKey}
				id="models-tab"
				onSelect={onSelect}
			>
				<Tab
					eventKey={PROJECT_MODE.ORTHOPHOTO}
					title="Orthophoto Models">
					<ModelsContentComponent projectMode={PROJECT_MODE.ORTHOPHOTO} />
				</Tab>
				<Tab
					eventKey={PROJECT_MODE.SINGLE_IMAGE}
					title="Single Image Models">
					<ModelsContentComponent projectMode={PROJECT_MODE.SINGLE_IMAGE} />
				</Tab>
			</StyledTabs>
		</>
	);
};

export default ModelTypesTabs;
