import styled from 'styled-components';

import { VscRecord as ObjectDetectionIcon } from 'react-icons/vsc';
import { BiShapePolygon as SegmentationIcon } from 'react-icons/bi';
import { GrMultiple as MultiModelIcon } from 'react-icons/gr';

const IconWrapper = styled.span`
	display: flex;
	align-items: center;
	svg {
		opacity: 0.5;
		path {
			stroke: currentColor;
		}
	}
`;

const TooltipSpan = ({
	content,
	tooltipContainerId,
	tooltipVariant = 'dark',
	children,
	...props
}) => (
	<span
		data-tooltip-id={tooltipContainerId}
		data-tooltip-content={content}
		data-tooltip-place="left"
		data-tooltip-variant={tooltipVariant}
		{...props}>
		{children}
	</span>
);

const TaskTypeIcon = ({
	multimodel,
	task_type,
	tooltipContainerId,
	tooltipVariant,
	...props
}) => {
	const getTooltipContent = () => {
		if (multimodel) return 'Multi model';
		if (task_type === 'object_detection') return 'Object detection model';
		return 'Segmentation model';
	};

	const getIconComponent = () => {
		if (multimodel) return <MultiModelIcon />;
		if (task_type === 'object_detection') return <ObjectDetectionIcon />;
		return <SegmentationIcon />;
	};

	return (
		<TooltipSpan
			content={getTooltipContent()}
			tooltipContainerId={tooltipContainerId}
			tooltipVariant={tooltipVariant}
			{...props}>
			<IconWrapper>{getIconComponent()}</IconWrapper>
		</TooltipSpan>
	);
};
export default TaskTypeIcon;
