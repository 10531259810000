import { useRef } from 'react';
import DropdownBS from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { MdOutlineUploadFile } from 'react-icons/md';
import { RiFolderUploadLine } from 'react-icons/ri';
import styled from 'styled-components';

const Dropdown = styled(DropdownBS)`
	margin-top: 1rem;

	& > div {
		min-width: fit-content;

		& > button {
			display: flex;
			gap: 0.625rem;
			align-items: center;
		}
	}
`;

const FileIcon = styled(MdOutlineUploadFile)`
	font-size: 1.25rem;
`;

const FolderIcon = styled(RiFolderUploadLine)`
	font-size: 1.25rem;
`;

const DropdownItem = ({ icon, label, inputProps }) => {
	const inputRef = useRef(null);
	const handleClick = () => inputRef.current.click();

	return (
		<Dropdown.Item as={Button} variant="dark" onClick={handleClick}>
			{icon}
			{label}
			<input {...inputProps} ref={inputRef} />
		</Dropdown.Item>
	);
};

const UploadButton = ({ inputProps }) => {
	return (
		<Dropdown drop="down-centered">
			<Dropdown.Toggle variant="dark">Upload</Dropdown.Toggle>

			<Dropdown.Menu>
				<DropdownItem
					icon={<FileIcon />}
					label="Files"
					inputProps={inputProps}
				/>
				<DropdownItem
					icon={<FolderIcon />}
					label="Folder"
					inputProps={{ ...inputProps, webkitdirectory: '' }}
				/>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default UploadButton;
