import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { MdDelete } from 'react-icons/md';
import { Dropdown } from 'react-bootstrap';

import { useProject } from '@contexts/Project.context';
import InfoTootip from '@components/infotooltip/InfoTooltip';

const ModelUl = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	--bs-dropdown-link-active-bg: var(--bs-dropdown-link-hover-bg);
	&:not(.child-models) > li > div {
		> .parent-model {
			margin: -4px -16px;
			padding: 4px 16px;
			width: calc(100% + 32px);
			&:hover {
				color: var(--bs-dropdown-link-hover-color);
				background-color: var(--bs-dropdown-link-hover-bg);
			}
		}
	}
	ul {
		margin: 0 calc(-1 * var(--bs-dropdown-item-padding-x)) 0 4px;
	}
`;
const DropdownHeader = styled(Dropdown.Header)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5em;
	width: 100%;
	padding-left: 35px;
	span {
		display: inline-block;
		line-height: 1;
	}
`;
const Button = styled.button`
	padding: 0;
	margin: 0;
	border: none;
	background: none;
	display: flex;
	flex-direction: column;
	&:hover {
		background: none;
	}
	&[aria-pressed='true'] {
		> span {
			color: ${props => props.theme.colors.lightGreen};
		}
	}
`;

const Row = styled.i`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const DeleteIcon = styled(MdDelete)`
	transform: scale(1.2);
	transition: 0.2s;
	button:not(:disabled) & {
		&:hover {
			cursor: pointer;
			color: ${props => props.theme.colors.warning};
		}
	}
`;

const NoResults = styled.div`
	padding: 10px 35px;
	font-size: 0.875rem;
`;
export const TooltipSpan = ({ content, children }) => (
	<span
		data-tooltip-id="tooltip-toolbar-root"
		data-tooltip-content={content}
		data-tooltip-place="left">
		{children}
	</span>
);

const ModelList = ({
	models,
	taskDescription,
	pickTask,
	searchValue,
	trashTask,
	disabled,
}) => {
	const { pickedTask } = useProject();

	const groupModels = models => {
		// Separate multimodels and single models
		const multimodels = models.filter(model => model.multimodel);
		const singleModels = models.filter(model => !model.multimodel);

		// Create a map to group single models by their parent multimodel's UUID
		const groupedModels = multimodels.map(multimodel => {
			const children = singleModels.filter(singleModel =>
				multimodel.classes.some(
					multiClass => multiClass.model_uuid === singleModel.uuid
				)
			);
			return { ...multimodel, children };
		});

		// Add single models that are not children of any multimodel
		const ungroupedModels = singleModels.filter(
			singleModel =>
				!groupedModels.some(group =>
					group.children.some(
						child => child.uuid === singleModel.uuid
					)
				)
		);

		return [...ungroupedModels, ...groupedModels];
	};

	const baseModels = useMemo(
		() => groupModels(models.filter(task => task.basemodel)),
		[models]
	);

	const customModels = useMemo(
		() => groupModels(models.filter(task => !task.basemodel)),
		[models]
	);

	return (
		<>
			{baseModels && (
				<>
					<Header
						title="Base Models"
						content="Base models are pre-generated by Biodrone and cannot be modified or removed"
					/>

					{baseModels.length > 0 && (
						<ModelUl>
							{baseModels.map(task => (
								<ModelItem
									key={task.model_uuid}
									task={task}
									pickedTask={pickedTask}
									pickTask={pickTask}
									taskDescription={taskDescription}
									searchValue={searchValue}
									disabled={disabled}
								/>
							))}
						</ModelUl>
					)}

					{baseModels.length === 0 && (
						<NoResults>No Base Models available</NoResults>
					)}

					<Dropdown.Divider />
				</>
			)}

			{customModels && (
				<>
					<Header
						title="Customizable Models"
						content="Customizable models can be trained to detect any object of interest"
					/>

					{customModels.length > 0 && (
						<ModelUl>
							{customModels.map(task => (
								<ModelItem
									key={task.model_uuid}
									task={task}
									pickedTask={pickedTask}
									pickTask={pickTask}
									trashTask={trashTask}
									taskDescription={taskDescription}
									searchValue={searchValue}
									disabled={disabled}
								/>
							))}
						</ModelUl>
					)}

					{customModels.length === 0 && (
						<NoResults>No Custom Models exists</NoResults>
					)}
				</>
			)}
		</>
	);
};

export default ModelList;

const Header = ({ title, content }) => {
	return (
		<DropdownHeader>
			<span>{title}</span>
			<InfoTootip
				tooltipContainerId="tooltip-toolbar-root"
				placement="left"
				content={content}
			/>
		</DropdownHeader>
	);
};

const ModelItem = ({
	task,
	pickedTask,
	pickTask,
	trashTask,
	taskDescription,
	searchValue,
	disabled,
}) => {
	const { model_uuid, description, hover_description, classes } = task;
	const { model_uuid: picked_model } = pickedTask || {};

	const descriptionMatches = description
		.toLowerCase()
		.includes(searchValue.toLowerCase());

	const classesDescriptionMatches = classes.some(cls =>
		cls.description.toLowerCase().includes(searchValue.toLowerCase())
	);

	const [deleting, setDeleting] = useState(false);

	const visible = descriptionMatches || classesDescriptionMatches;
	const selected = picked_model === model_uuid;
	const isDisabled = disabled || deleting || selected;

	const handleDelete = () => {
		setDeleting(true);
		trashTask(task);

		// Fastes way to remove the deleting state in case of an error
		// Doing it a nother way would require a lot of refactoring
		setTimeout(() => {
			setDeleting(false);
		}, 10000);
	};

	return (
		<li className={visible ? '' : 'd-none'}>
			<Dropdown.Item as="div" id={model_uuid} disabled={deleting}>
				<Row className="parent-model">
					<Button
						type="button"
						className="flex-grow-1 align-items-start"
						onClick={() => pickTask(task)}
						disabled={isDisabled}
						aria-pressed={selected}>
						<span>{taskDescription(task)}</span>
						{hover_description && (
							<small className="text-muted ms-3">
								{hover_description}
							</small>
						)}
					</Button>
					{!task.basemodel && (
						<Button
							type="button"
							onClick={handleDelete}
							disabled={isDisabled}>
							<DeleteIcon className="ms-2" />
						</Button>
					)}
				</Row>

				{task.children && (
					<ModelUl className="child-models">
						{task.children.map(child => (
							<ModelItem
								key={child.model_uuid}
								task={{ ...child, hover_description: null }}
								pickedTask={pickedTask}
								pickTask={pickTask}
								trashTask={trashTask}
								taskDescription={taskDescription}
								searchValue={searchValue}
								disabled={disabled}
							/>
						))}
					</ModelUl>
				)}
			</Dropdown.Item>
		</li>
	);
};
