import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import ProductsModal from '@components/modal/ProductsModal';

export default function UpgradeStoragePlan() {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button
				variant="link"
				className="text-left p-0 mt-2"
				onClick={() => setShowModal(true)}>
				Need more storage?
			</Button>
			{showModal && (
				<ProductsModal
					title="Change your subscription"
					message={`
						Changing your subscription will end this session.
						You will need to restart the project creation process after completing the transaction.
					`}
					onClose={() => setShowModal(false)}
				/>
			)}
		</>
	);
}
