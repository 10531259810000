import DateFormatted from '@components/dateFormatted/DateFormatted';
import { getTrainingDetailsExplanations } from '../../utils/trainingDetailsExplanations';

import DetailsList from './DetailsList';

const TrainingdataDetailsList = ({
	training_job_date,
	number_of_annotations,
	number_of_background_annotations,
}) => {
	const {
		number_of_annotations: number_of_annotationsExp,
		number_of_background_annotations: number_of_background_annotationsExp,
		training_job_date: training_job_dateExp,
	} = getTrainingDetailsExplanations() || {};

	const detailItems = [
		{
			key: 'number_of_annotations',
			label: number_of_annotationsExp?.label,
			helperText: number_of_annotationsExp?.content,
			value: number_of_annotations,
		},
		{
			key: 'number_of_background_annotations',
			label: number_of_background_annotationsExp?.label,
			helperText: number_of_background_annotationsExp?.content,
			value: number_of_background_annotations,
		},
		{
			key: 'training_job_date',
			label: training_job_dateExp?.label,
			helperText: training_job_dateExp?.content,
			value: <DateFormatted inputDate={training_job_date} />,
		},
	];

	return (
		<div>
			{' '}
			<small className="text-muted">Training details</small>
			<DetailsList items={detailItems} />
		</div>
	);
};
export default TrainingdataDetailsList;
