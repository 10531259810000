import { useModelContext } from '@contexts/Model.context';
import { getTrainingDetailsExplanations } from '../../utils/trainingDetailsExplanations';

import DetailsList from './DetailsList';
import { MODEL_TYPE } from '@utils/constants';

const parseValue = value => {
	// If value is float make it max 2 decimal places
	if (typeof value === 'number') {
		return value.toFixed(2);
	} else if (!value) {
		return '-';
	}
	return value;
};

const EvaluationDetailsList = ({ evaluation_result: evaluationResult }) => {
	return (
		<>
			<small className="text-muted">Model Performance</small>
			<PerformanceDetails evaluationResult={evaluationResult} />
		</>
	);
};
export default EvaluationDetailsList;

const PerformanceDetails = ({ evaluationResult }) => {
	const { modelType } = useModelContext();

	if (modelType === MODEL_TYPE.SEGMENTATION) {
		return <SegmentationInfo />;
	}

	const { f1_score, precision, recall } = evaluationResult || {};

	const {
		precision: precisionExp,
		recall: recallExp,
		f1_score: f1_scoreExp,
	} = getTrainingDetailsExplanations() || {};

	const detailItems = [
		{
			key: 'precision',
			label: precisionExp?.label,
			helperText: precisionExp?.content,
			value: parseValue(precision),
		},
		{
			key: 'recall',
			label: recallExp?.label,
			helperText: recallExp?.content,
			value: parseValue(recall),
		},
		{
			key: 'f1_score',
			label: f1_scoreExp?.label,
			helperText: f1_scoreExp?.content,
			value: parseValue(f1_score),
		},
	];

	if (!detailItems) return null;

	return <DetailsList items={detailItems} />;
};

const SegmentationInfo = () => {
	return (
		<div className="alert alert-dark">
			<p className="small text-muted mb-0">
				Model performance is not yet supported for segmentation models
			</p>
		</div>
	);
};
