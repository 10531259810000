import { Outlet } from 'react-router';
import styled from 'styled-components';

import Navigation from './navigation/Navigation';
import Footer from './Footer';
import MaintenanceAlert from './MaintenanceAlert';
import { useAuth } from '@contexts/User.context';

const Container = styled.div`
	padding-top: ${props => props.theme.utils.navHeight};
	background-color: ${props => props.theme.colors.background};
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Root = () => {
	const { currentUser } = useAuth();
	const showFooter = !currentUser?.loggedIn; // Footer is shown in navigation for logged in users

	return (
		<>
			<Navigation />
			<Container>
				<main>
					<MaintenanceAlert />
					<Outlet />
				</main>

				{showFooter && <Footer />}
			</Container>
		</>
	);
};
export default Root;
