// Constants as in the backend. Is used to set the project mode
export const IMAGE_MODE = {
	GENERATE_ORTHOPHOTO: 'generate_orthophoto',
	UPLOAD_ORTHOPHOTO: 'upload_orthophoto',
	SINGLE_IMAGE: 'single_image',
};

// Constants as in the frontend for the project mode
export const PROJECT_MODE = {
	ORTHOPHOTO: 'orthophoto',
	SINGLE_IMAGE: 'single_image',
};

// Constants for the model mode
export const MODEL_TYPE = {
	OBJECT_DETECTION: 'object_detection',
	SEGMENTATION: 'segmentation',
};
