import { createContext, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
	getModel,
	getAnnotationInfoForModel,
	getAllTrainingsForModel,
} from '@api';
import useOngoingModelJobs from '@components/singleModel/hooks/useOngoingModelJobs';

const ModelContext = createContext();

export const useModelContext = () => useContext(ModelContext);

export const ModelProvider = ({ children }) => {
	const [modelUuid, setModelUuid] = useState(null);
	// General info about the model
	const { data: modelInfo, isLoading: modelInfoLoading } = useQuery({
		queryKey: ['model', modelUuid],
		queryFn: () => getModel(modelUuid),
		enabled: !!modelUuid,
	});

	const {
		description: modelName,
		hover_description: modelDescription,
		is_trained: isTrained,
		project_uuid: projectUuid,
		classes: modelClasses,
		image_mode: compatibleProjectType,
		object_size: modelObjectSize,
	} = modelInfo || {
		description: '',
		is_trained: false,
	};
	const modelType =
		modelClasses?.length > 0 ? modelClasses[0].task_type : null;

	// Total annotations info
	const { data: annotationInfo, isLoading: annotationInfoLoading } = useQuery(
		{
			queryKey: ['annotationInfo', modelUuid],
			queryFn: () => getAnnotationInfoForModel(modelUuid),
			enabled: !!modelUuid,
		}
	);

	const { can_be_trained: canBeTrained, annotations: modelAnnotations } =
		annotationInfo || {};

	// All trainings for the model
	const { data: allTrainings, isLoading: loadingTrainings } = useQuery({
		queryKey: ['allTrainings', modelUuid],
		queryFn: () => getAllTrainingsForModel({ model_uuid: modelUuid }),
		enabled: !!modelUuid,
	});
	const { trainings } = allTrainings ? allTrainings[0] : {};

	// Ongoing training and evaluation
	const { trainingModel, evaluatingModel } = useOngoingModelJobs(modelUuid);

	const value = {
		modelUuid,
		setModelUuid,

		// Model info
		modelInfoLoading,
		modelName,
		modelDescription,
		modelType,
		modelObjectSize,
		projectUuid,
		isTrained,
		compatibleProjectType,

		// Annotations
		canBeTrained,
		modelAnnotations,
		annotationInfoLoading,

		// Trainings
		trainings,
		loadingTrainings,

		// Ongoing jobs
		trainingModel,
		evaluatingModel,
		hasOngoingJob: trainingModel || evaluatingModel,
	};

	return (
		<ModelContext.Provider value={value}>{children}</ModelContext.Provider>
	);
};
