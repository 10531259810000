import 'ol/ol.css';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { useParams, Link } from 'react-router';
import { Spinner } from 'react-bootstrap';

import MapView from './components/Map';
import Dialogue from '@components/dialogue/Dialogue';
import LayersSideBar from './components/sidebars/LayerView';
import MapToolbar from './components/toolBar/MapToolbar';
import Annotate from './components/utilityButtons/Annotate';
import DetectInArea from './components/utilityButtons/DetectInArea';
import Export from './components/utilityButtons/Export';
import ConfirmModal from '@components/modal/ConfirmModal';

import { useProject } from '@contexts/Project.context';
import { PROJECT_MODE } from '@utils/constants';
import { useAuth } from '@contexts/User.context';
import { getProject } from '@api';
import AnnotationSidebar from './components/sidebars/AnnotationSidebar';
import MapLegend from './components/MapLegend';
import CarouselSlider from './components/singleImageSlider/CarouselSlider';
import MeasurementTools from './components/utilityButtons/measurementTools/MeasurementTools';

const MapEditorWrapper = styled.div`
	position: relative;
	overflow: hidden;
	height: ${props => props.theme.utils.fullHeight};
`;

const InfoText = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70vh;
	color: #fff;
`;

const Loader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80vh;
	width: 100%;
	color: ${props => props.theme.colors.textColor};
`;

const IsDemoBanner = styled.div`
	position: fixed;
	left: 356px;
	top: 69px;
	max-width: calc(100% - 500px);
	width: auto;
	background-color: ${props => props.theme.colors.background};
	color: ${props => props.theme.colors.textColor};
	z-index: 2;
	padding: 10px;
	opacity: 0.9;
	p {
		margin-bottom: 0;
	}
`;

const StyledMeasurementTools = styled(MeasurementTools)`
	${props =>
		props.$hasSibling &&
		`
	border-right: 1px solid hsl(0, 0%, 30%);
	margin-right: 1rem;
	padding-right: 1rem;
	border-radius: 0;`}

	/* Hovered Radio Button */
	.btn:hover {
		background: var(--bs-btn-hover-bg);
	}
`;

/**
 * MapEditor is the main component for the map editor page. It is a central hub for all the other components in the map editor page
 * and is responsible for:
 *
 * setting the context project context
 * getting data from the backend
 * contains all state variables for the map editor page
 */

const MapEditor = () => {
	const {
		pickedTask,
		isDemo,
		projectMode,
		annotationMode,
		measurementLayerAdded,
		dispatch,
	} = useProject();
	const { roleAdmin, tierPro, subscriptionActive } = useAuth();

	const isSingleImageMode = projectMode === PROJECT_MODE.SINGLE_IMAGE;
	const annotationAllowed = roleAdmin
		? pickedTask
		: pickedTask && !pickedTask.basemodel;

	const { project_uuid } = useParams();

	const [projectLoading, setProjectLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);
	const [infoMessage, setInfoMessage] = useState(null);
	const [hexagonColor, setHexagonColor] = useState({
		color: '0, 0, 128',
	});

	const [center, setCenter] = useState(null);
	const [maxZoomlevel, setMaxZoomLevel] = useState(23);

	const projectQuery = useQuery({
		queryKey: ['project', project_uuid],
		queryFn: async () => {
			return await getProject(project_uuid);
		},
		enabled: !!project_uuid,
		retry: false,
	});

	useEffect(() => {
		if (projectQuery?.data) {
			const { deleted: isDeleted } = projectQuery.data;
			if (isDeleted && !roleAdmin) {
				setInfoMessage({
					header: 'Deleted project',
					body: `Project with ID ${project_uuid} is deleted and can't be accessed.`,
				});
				setProjectLoading(false);
				return;
			}

			dispatch({ type: 'setProject', payload: projectQuery.data });
			dispatch({
				type: 'setIsDemo',
				payload: projectQuery.data.demo_project && !roleAdmin,
			});

			// If tierPro and not subscriptionactive and not demo then redirect to home
			if (
				tierPro &&
				!subscriptionActive &&
				!projectQuery.data.demo_project
			) {
				setErrorMessage(
					'You need an active subscription to view this project'
				);
				setProjectLoading(false);
				dispatch({ type: 'setProject', payload: null });
				return;
			}

			const center = projectQuery.data.center_coordinate;
			const e = projectQuery.data.extent;
			if (!!projectQuery.data.orthophoto_progress?.error) {
				setInfoMessage({
					header: 'Something seems to have gone wrong',
					body: (
						<>
							<a
								href={`mailto:post@biodrone.no?subject=Failing project with id ${projectQuery.data.uuid}`}>
								Please contact us at post@biodrone.no
							</a>{' '}
							and we will help you
						</>
					),
				});
			} else if (!center || (center[0] === -1 && center[1] === -1)) {
				// if projectQuery.data.created_at is more than 12 hours ago, then the project is not ready
				const created_at = new Date(projectQuery.data.created_at);
				const now = new Date();
				const diff = now - created_at;
				const hours = Math.floor(diff / 1000 / 60 / 60);

				if (hours > 12) {
					setInfoMessage({
						header: 'Creating a project sometimes take a while',
						body: (
							<>
								Especially when dealing with large datasets or
								if an issue arises. <br />
								<a
									href={`mailto:post@biodrone.no?subject=Question about project with id ${projectQuery.data.uuid}`}>
									Please contact us at post@biodrone.no
								</a>{' '}
								if you have any questions.
							</>
						),
					});
				} else {
					setInfoMessage({
						header: 'Project not ready',
						body: 'The map is not ready yet. This can take some time. Check again later.',
					});
				}
			} else {
				setCenter(center);
				dispatch({ type: 'setExtent', payload: e });
				setMaxZoomLevel(projectQuery.data.max_zoom_level ?? 21);
			}

			setProjectLoading(false);

			return;
		}

		if (projectQuery?.isError) {
			console.warn(
				'Could not fetch and set project.',
				projectQuery.error
			);
			if (projectQuery?.error?.response?.status === 401) {
				setErrorMessage(
					`You are not authorized to view project ID ${project_uuid}`
				);
			} else {
				setErrorMessage(`Can't find project with ID ${project_uuid}`);
			}
			dispatch({ type: 'setProject', payload: null });
			dispatch({ type: 'setIsDemo', payload: false });

			setProjectLoading(false);
		}
	}, [projectQuery?.data, projectQuery?.isError]);

	useEffect(() => {
		return () => {
			// Reset states when unmounting
			dispatch({ type: 'resetState' });
		};
	}, []);

	if (projectLoading) {
		return (
			<Loader>
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Loading page</span>
				</Spinner>
			</Loader>
		);
	}

	if (errorMessage) {
		return (
			<InfoText>
				<div>
					<h1>Error</h1>
					<p>{errorMessage}</p>
				</div>
			</InfoText>
		);
	}

	return (
		<MapEditorWrapper>
			{infoMessage ? (
				<InfoText>
					<div>
						<h1>{infoMessage.header}</h1>
						<p>{infoMessage.body}</p>
					</div>
				</InfoText>
			) : (
				<>
					{isDemo && (
						<IsDemoBanner>
							<p>
								This is a demo project showcasing the results of
								an analysis. Other features are disabled.{' '}
								<Link to="/" className="inherit">
									Subscribe to create and analyze your own
									projects.
								</Link>
							</p>
						</IsDemoBanner>
					)}

					<Dialogue />
					<ConfirmModal />

					<Export />

					<MapToolbar annotationMode={annotationMode}>
						{!annotationMode && (
							<>
								{measurementLayerAdded && (
									<StyledMeasurementTools
										$hasSibling={!!pickedTask}
									/>
								)}
								<DetectInArea
									isTrained={pickedTask?.is_trained}
								/>
							</>
						)}

						{annotationAllowed && <Annotate />}
					</MapToolbar>

					{!annotationMode ? (
						<>
							<LayersSideBar
								maxZoomLevel={maxZoomlevel}
								hexagonColor={hexagonColor}
								setHexagonColor={setHexagonColor}
								annotationMode={annotationMode}
							/>
							<MapLegend />

							{isSingleImageMode && <CarouselSlider />}
						</>
					) : (
						<AnnotationSidebar />
					)}

					<MapView center={center} />
				</>
			)}
		</MapEditorWrapper>
	);
};

export default MapEditor;
