import { api } from './index.js';

const route = '/models';

export const getAvailableModels = async () => {
	const { data } = await api.get(`${route}/available_models`);
	return data;
};

export const getAvailableClasses = async ({
	image_mode,
	search,
	limit,
	offset,
}) => {
	const params = new URLSearchParams();

	if (typeof image_mode === 'string') {
		params.append('image_mode', image_mode);
	}

	if (typeof search === 'string') {
		params.append('search', search);
	}

	if (typeof limit === 'number') {
		params.append('limit', limit);
	}

	if (typeof offset === 'number') {
		params.append('offset', offset);
	}

	const url = `${route}/available_classes?${params.toString()}`;

	const { data } = await api.get(url);
	return data;
};

export const getModel = async model_uuid => {
	const { data } = await api.get(`${route}/get_model/${model_uuid}`);
	return data;
};

export const getAnnotationInfoForModel = async model_uuid => {
	const { data } = await api.get(
		`${route}/get_annotation_info_for_model/${model_uuid}`
	);
	return data;
};

export const getProjectsDetectedOn = async model_uuid => {
	const { data } = await api.get(
		`${route}/get_projects_detected_on/${model_uuid}`
	);
	return data;
};

export const getModelsFromAllUsers = async ({
	limit,
	offset,
	search,
	image_mode,
}) => {
	const params = new URLSearchParams();

	if (typeof image_mode === 'string') {
		params.append('image_mode', image_mode);
	}

	if (typeof search === 'string') {
		params.append('search', search);
	}

	if (typeof limit === 'number') {
		params.append('limit', limit);
	}

	if (typeof offset === 'number') {
		params.append('offset', offset);
	}

	const url = `${route}/get_models_from_all_users?${params.toString()}`;

	const { data } = await api.get(url);
	return data;
};

export const revertModelToVersion = async ({ model_uuid, training_id }) => {
	if (!model_uuid || !training_id) {
		throw new Error('model_uuid and training_id are required');
	}

	const { data } = await api.post(`${route}/revert_model_to_version`, {
		model_uuid,
		target_training_id: training_id,
	});
	return data;
};
