import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { StyledModal } from '@components/modal/ModalStyle';
import Input from '@components/form/Input';

import { setDemoProject } from '@api';

const DemoProjectModal = ({ project, show, setShow }) => {
	const navigate = useNavigate();
	const alreadyDemo = project.demo_project;

	const setDemoProjectMutation = useMutation({
		mutationFn: ({ projectId, demoModel }) =>
			setDemoProject({ projectId, demoModel }),
		onSuccess: () => {
			if (alreadyDemo) {
				window.alert('Demo project updated');
			} else {
				window.alert('Project set as demo project');
			}
			navigate('/admin/demo-projects');
			setShow(false);
		},
		onError: err => {
			if (alreadyDemo) {
				console.error('Error updating demo project', err);

				window.alert('Failed to update demo project');
			} else {
				console.error('Error setting demo project', err);

				window.alert('Failed to set project as demo project');
			}
		},
	});

	return (
		<StyledModal
			variant="dark"
			show={show}
			centered={true}
			onHide={() => {
				setShow(false);
			}}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>
					{alreadyDemo
						? 'Update demo project'
						: 'Set as demo project'}
				</Modal.Title>
			</Modal.Header>

			<Form
				onSubmit={async e => {
					e.preventDefault();
					const demoModel = e.target.elements[0].value;

					setDemoProjectMutation.mutate({
						projectId: project?.uuid,
						demoModel,
					});
				}}>
				<Modal.Body>
					{!alreadyDemo && (
						<p>
							Make <strong>{project?.title}</strong> a demo
							project
						</p>
					)}

					<Input
						label="Demo model uuid"
						defaultValue={project?.default_demo_model || ''}
						id="demoModelUuid"
						helperText="Enter the uuid of a model that has been trained on this project."
						type="text"
						required
					/>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Nah
					</Button>
					<Button
						variant="success"
						type="submit"
						disabled={setDemoProjectMutation.isLoading}>
						Do it!
					</Button>
				</Modal.Footer>
			</Form>
		</StyledModal>
	);
};
export default DemoProjectModal;
