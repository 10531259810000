import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import styled, { keyframes } from 'styled-components';

import { useNewProject } from '@contexts/NewProject.context';
import { getUserTags } from '@utils/api';
import Loader from '@components/loading/Loader';
import ProjectTagsSelect from '@components/projectComponents/projectTags/ProjectTagsSelect';
import Input from '@components/form/Input';

const fadeIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
	row-gap: 1rem;

	animation: ${fadeIn} 1s;

	@media screen and (max-width: 991.98px) {
		grid-template-columns: 1fr;
	}

	& > * {
		margin: 0;
	}
`;

const AddProjectDetails = ({}) => {
	const { submitting } = useNewProject();
	const { control } = useFormContext();

	const { data: userTags, isLoading } = useQuery({
		queryKey: ['user_tags'],
		queryFn: getUserTags,
		refetchOnWindowFocus: false,
	});

	if (isLoading) return <Loader />;

	return (
		<Wrapper>
			<Controller
				name="projectName"
				control={control}
				rules={{
					required: 'Project name is required',
				}}
				render={({ field }) => (
					<Input
						{...field}
						label="Project Name *"
						type="text"
						disabled={submitting}
						required
					/>
				)}
			/>

			<Controller
				name="projectDescription"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						label="Project Description"
						type="text"
						disabled={submitting}
					/>
				)}
			/>

			<Controller
				name="projectTags"
				control={control}
				render={({ field }) => (
					<ProjectTagsSelect userTags={userTags} field={field} />
				)}
			/>
		</Wrapper>
	);
};

export default AddProjectDetails;
