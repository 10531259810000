import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiFillPlusCircle } from 'react-icons/ai';

import CreateModelModal from '@components/modal/createModelModal/CreateModelModal';

const CreateModel = ({ projectMode }) => {
	const [show, setShow] = useState(false);

	if (!projectMode) {
		return null;
	}

	return (
		<>
			<Button
				variant="success"
				size="sm"
				onClick={() => setShow(true)}
				className="d-flex align-items-center gap-2">
				<AiFillPlusCircle />
				<span>Create New Model</span>
			</Button>
			{show && (
				<CreateModelModal
					setShowModal={() => {
						setShow(false);
					}}
					projectMode={projectMode}
				/>
			)}
		</>
	);
};
export default CreateModel;
