import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';

import { Button, Modal } from 'react-bootstrap';

import { StyledModal } from '@components/modal/ModalStyle';

import { useModelContext } from '@contexts/Model.context';
import { useToast } from '@contexts/Toast.context';

import { deleteModel } from '@api';

const DeleteModel = () => {
	const [show, setShow] = useState(false);

	return (
		<>
			<div className="mt-5 d-flex justify-content-end">
				<Button
					variant="outline-light"
					onClick={() => {
						setShow(true);
					}}>
					Delete Model
				</Button>
			</div>
			{show && (
				<DeleteModelModal
					onHide={() => {
						setShow(false);
					}}
				/>
			)}
		</>
	);
};

export default DeleteModel;

const DeleteModelModal = ({ onHide }) => {
	const { modelUuid, modelName } = useModelContext();
	const { addToast } = useToast();
	const navigate = useNavigate();

	const { mutate: handleDelete } = useMutation({
		mutationFn: () => deleteModel(modelUuid),
		onSuccess: res => {
			console.log('Model successfully deleted', res);
			addToast({
				id: `delete_model_success-${new Date().getTime()}`,
				title: `Model ${modelName} successfully deleted`,
				bg: 'success',
			});

			navigate('/models');
		},
		onError: err => {
			console.error('Could not delete model', err);

			addToast({
				id: `delete_model_error-${new Date().getTime()}`,
				title: `Model could not be deleted`,
				bg: 'danger',
			});

			onHide();
		},
	});

	return (
		<StyledModal show={true} centered onHide={onHide}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>Delete model</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					Are you sure you want to delete the model{' '}
					<strong>{modelName}</strong>?
				</p>
				<p>This action cannot be undone.</p>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={onHide}>
					Cancel
				</Button>

				<Button variant="danger" onClick={handleDelete}>
					Delete
				</Button>
			</Modal.Footer>
		</StyledModal>
	);
};
