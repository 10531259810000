import styled from 'styled-components';
import Popover from '@components/popover/Popover';

import { IoHelp } from 'react-icons/io5';

const Wrapper = styled.div`
	display: inline-flex;
	position: relative;
	cursor: pointer;
`;

const Icon = styled(IoHelp)`
	opacity: 0.7;
	display: inline-block;
	align-self: baseline;
	font-size: 0.8em;
`;

const LabelWithPopover = ({
	label,
	title,
	content,
	popoverProps,
	...props
}) => {
	return (
		<Popover title={title} content={content} {...popoverProps}>
			<Wrapper {...props}>
				<span>{label}&nbsp;</span>
				<Icon />
			</Wrapper>
		</Popover>
	);
};
export default LabelWithPopover;
