import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { BiChevronDown } from 'react-icons/bi';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

import { useNewProject } from '@contexts/NewProject.context';
import { PROJECT_MODE } from '@utils/constants';
import Checkbox from '@components/form/Toggle';

const AdvancedButton = styled(Button)`
	margin: 0 1rem;
	padding: 0;
`;

const StyledChevron = styled(BiChevronDown)`
	rotate: ${({ $showAdvanced }) => ($showAdvanced ? -180 : 0)}deg;
	transition: all 0.3s;
`;

const AdvancedWrapper = styled.div`
	margin-top: 1rem;
	border: 1px solid rgba(${({ theme }) => theme.colors.borderLightRgb}, 0.2);
	padding: 1em 1em 0;
	border-radius: 0.375rem;
`;

const AdvancedActions = ({ ...props }) => {
	const { isTif, mode } = useNewProject();
	const { control } = useFormContext();

	const { SINGLE_IMAGE } = PROJECT_MODE;

	const [showAdvanced, setShowAdvanced] = useState(false);
	return (
		<div {...props}>
			{!isTif && mode != SINGLE_IMAGE && (
				<AdvancedButton
					type="button"
					variant="link-light"
					onClick={() => setShowAdvanced(prevState => !prevState)}>
					<span className="text-decoration-underline">
						Advanced settings
						<StyledChevron $showAdvanced={showAdvanced} />
					</span>
				</AdvancedButton>
			)}

			{showAdvanced && (
				<AdvancedWrapper>
					<Controller
						name="fastOrtophoto"
						control={control}
						render={({ field }) => (
							<Checkbox
								{...field}
								defaultChecked={false}
								id="new-project-fast-ortophoto"
								label="Fast Orthophoto"
								helperText="Faster project creation, but without height calculations"
							/>
						)}
					/>

					{/* Kept hidden for now */}
					{/*
					<Checkbox
						defaultChecked={true}
						name="dsm"
						id="new-project-dsm"
						label="DSM"
					/>

					<Checkbox
						defaultChecked={false}
						id="new-project-ignore-gsd"
						label="Ignore GSD"
						name="ignoreGsd"
					/>

					<Checkbox
						defaultChecked={true}
						id="new-project-tiles"
						label="Tiles"
						name="tiles"
					/>

					<Input
						label="Resolution"
						step="0.1"
						min="0.01"
						max="10"
						tooltip="Ground resolution surface model and ortophoto"
						type="text"
						defaultValue="0.1"
						name="resolution"
					/>

					<Input
						label="Mesh Size"
						type="number"
						placeholder="Mesh Size"
						name="meshSize"
						defaultValue="200000"
					/> */}
				</AdvancedWrapper>
			)}
		</div>
	);
};

export default AdvancedActions;
