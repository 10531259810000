import { FiHelpCircle } from 'react-icons/fi';

// This component needs to be used with an instance of the Tooltip component from react-tooltip
const InfoTooltip = ({
	tooltipContainerId,
	placement = 'left',
	content,
	...props
}) => {
	if (!tooltipContainerId) {
		console.warn('HelperTooltip: tooltipContainerId is required');
		return null;
	}

	return (
		<span
			data-tooltip-id={tooltipContainerId}
			data-tooltip-content={content}
			data-tooltip-place={placement}
			{...props}>
			<FiHelpCircle />
		</span>
	);
};
export default InfoTooltip;
