import { Link } from 'react-router';

/**
 * Component to display a link to a project or model.
 *
 * @param {string} project_uuid - The UUID of the project.
 * @param {string} project_title - The title of the project. Defaults to 'Unknown Project' if not provided.
 * @param {string} model_uuid - The UUID of the model. If provided, it will be included in the URL as a query parameter.
 * @param {string} jobType - The type of job. If 'modelvaluation', the URL will point to the model page.
 * @returns {JSX.Element|null} The project link or null if no project UUID is provided.
 */
const NotificationLink = ({
	project_uuid,
	project_title,
	model_uuid,
	model_info,
	jobType,
	disabled,
}) => {
	const modelEvaluationJob = jobType === 'model_evaluation';

	const createTitle = () => {
		if (modelEvaluationJob) {
			return model_info?.[0]?.class_name || 'Model Evaluation';
		}

		return project_title || 'Unknown Project';
	};
	const title = createTitle();

	if (disabled && !modelEvaluationJob) {
		return <p className="mb-0">{title}</p>;
	}

	const createUrl = () => {
		if (modelEvaluationJob) {
			return `/models/${model_uuid}`;
		}

		return model_uuid
			? `/projects/${project_uuid}/?model=${model_uuid}`
			: `/projects/${project_uuid}`;
	};
	const url = createUrl();

	return (
		<Link to={url} reloadDocument className="inherit">
			{title}
		</Link>
	);
};
export default NotificationLink;
