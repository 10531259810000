import { useMemo } from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Modal } from 'react-bootstrap';
import { components } from 'react-select';
import { Tooltip } from 'react-tooltip';

// Model Icons
import { VscRecord } from 'react-icons/vsc';
import { BiShapePolygon } from 'react-icons/bi';
import { GrMultiple } from 'react-icons/gr';

import LimitInfoComponent from '@components/subscription/LimitInfo';
import Select from '@components/form/Select';
import { StyledModal } from '@components/modal/ModalStyle';
import Loader from '@components/loading/Loader';

import { useAuth } from '@contexts/User.context';
import { useNewProject } from '@contexts/NewProject.context';

import { getMonthlyDetectionsLimit, getAvailableModels } from '@utils/api';

const OptionWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.625rem;
	text-align: left;

	padding: 0.25rem 0.5rem;
	margin-left: ${({ $hasParent }) => ($hasParent ? '2rem' : '0')};
`;

const ModalBody = styled(Modal.Body)`
	display: grid;

	& button[type='submit'] {
		justify-self: end;
	}
`;

const pruneModels = models => {
	return models.map(
		({ uuid, description, classes, multimodel, basemodel }) => ({
			uuid,
			description,
			classes,
			multimodel,
			basemodel,
		})
	);
};

const groupModels = models => {
	const prunedModels = pruneModels(models);

	// Separate multimodels and single models
	const multimodels = prunedModels.filter(model => model.multimodel);
	const singleModels = prunedModels.filter(model => !model.multimodel);

	// Only include single models that are not part of a multimodel
	const ungroupedModels = singleModels.filter(
		singleModel =>
			!multimodels.some(group =>
				group.classes.some(
					child => child.model_uuid === singleModel.uuid
				)
			)
	);

	return [...multimodels, ...ungroupedModels];
};

const CreateProjectWithModelModal = ({ setShowModal }) => {
	const { subscription, tierPro } = useAuth();
	const { onSubmit, mode } = useNewProject();
	const { handleSubmit, resetField, watch } = useFormContext();
	const projectModels = watch('projectModels');

	const {
		data: modelsData,
		error: modelsError,
		isLoading: modelsLoading,
	} = useQuery({
		queryKey: ['models'],
		queryFn: getAvailableModels,
		refetchOnWindowFocus: false,
	});

	const models = useMemo(() => {
		if (!modelsData) return null;

		const baseModels = groupModels(
			modelsData.filter(
				task => task.basemodel && task.image_mode === mode
			)
		);
		const customModels = groupModels(
			modelsData.filter(
				task => !task.basemodel && task.image_mode === mode
			)
		);
		return [
			{ label: 'Base Models', options: prepForOptions(baseModels) },
			{ label: 'Custom models', options: prepForOptions(customModels) },
		];
	}, [modelsData]);

	const {
		data: monthlyDetectionsLimit,
		isLoading: monthlyDetectionsLimitLoading,
	} = useQuery({
		queryKey: ['monthlyDetectionsLimit', subscription?.id],
		queryFn: getMonthlyDetectionsLimit,
		enabled: !!subscription?.id && tierPro,
	});

	// If a user has a subscription, check if they have used their monthly limit for detections
	const detectionLimitUsed = monthlyDetectionsLimit
		? monthlyDetectionsLimit.monthly_detections >=
			monthlyDetectionsLimit.limit
		: false;

	const checkDisabled = () => {
		// If detection limit is used,
		// the user should be allowed to create a project without a model
		if (detectionLimitUsed) return false;

		// Disable if no model is selected
		if (!projectModels.value) return true;

		// Disable if monthly detections limit is loading
		if (monthlyDetectionsLimitLoading) return true;

		return false;
	};
	const disabled = checkDisabled();

	return (
		<StyledModal
			show
			centered
			size="lg"
			onHide={() => {
				resetField('projectModels');
				setShowModal(false);
			}}>
			<Modal.Header closeButton>
				<Modal.Title>Create project and run detection</Modal.Title>
			</Modal.Header>
			<ModalBody>
				{modelsError && <LoadingError error={modelsError} />}

				{modelsLoading && <Loader message="Loading models" />}

				{!modelsLoading && models && (
					<ModalContent
						models={models}
						detectionLimitUsed={detectionLimitUsed}
						projectModels={projectModels}
						monthlyDetectionsLimit={monthlyDetectionsLimit}
					/>
				)}

				<Button
					type="submit"
					variant="success"
					disabled={disabled}
					onClick={handleSubmit(onSubmit)}>
					Create Project
				</Button>
			</ModalBody>
		</StyledModal>
	);
};
export default CreateProjectWithModelModal;

const ModalContent = ({
	models,
	detectionLimitUsed,
	projectModels,
	monthlyDetectionsLimit,
}) => {
	return (
		<>
			{!detectionLimitUsed && (
				<>
					<p>
						Select an AI model to automatically detect objects
						across your entire project once it's created.
					</p>
					<p className="small text-muted">
						You also have the option to run detection later when
						viewing the finished project.
					</p>

					<ModelSelect models={models} />
					<Tooltip variant="light" id="tooltip-wrap" />
				</>
			)}

			<LimitInfo
				projectModels={projectModels}
				monthlyDetectionsLimit={monthlyDetectionsLimit}
			/>
		</>
	);
};

const TooltipWrap = ({ tooltip, children }) => (
	<span
		data-tooltip-id="tooltip-wrap"
		data-tooltip-content={tooltip}
		data-tooltip-place="left">
		{children}
	</span>
);

const ModelIcon = ({ type }) => {
	let icon = null;
	let tooltip = '';

	switch (type) {
		case 'object_detection':
			tooltip = 'Object detection model';
			icon = <VscRecord />;
			break;

		case 'segmentation':
			tooltip = 'Segmentation model';
			icon = <BiShapePolygon />;
			break;

		case 'multi':
			tooltip = 'Multi model';
			icon = <GrMultiple />;
			break;

		default:
			console.warn('Unknown model type:', type);
			break;
	}

	return <TooltipWrap tooltip={tooltip}>{icon}</TooltipWrap>;
};

const prepForOptions = models => {
	let mods = [];

	models.forEach(model => {
		const {
			uuid,
			description,
			hover_description,
			multimodel,
			classes,
			basemodel,
		} = model;

		const type = multimodel ? 'multi' : classes[0]?.task_type;

		mods.push({
			label: description,
			value: uuid,
			details: {
				multimodel: multimodel,
				type,
				description: hover_description,
				basemodel,
				classes,
			},
		});

		if (multimodel) {
			classes.forEach(cls => {
				const {
					model_uuid,
					class: modelClass,
					task_type,
					description,
				} = cls;
				const label = basemodel ? description : modelClass;
				mods.push({
					label: label,
					value: model_uuid,
					details: {
						hasParent: true,
						type: task_type,
						description,
						basemodel,
					},
				});
			});
		}
	});

	return mods;
};

const Option = props => {
	const {
		data: { details },
	} = props ?? {};

	const { type, hasParent } = details ?? {};
	return (
		<OptionWrapper $hasParent={hasParent}>
			<ModelIcon type={type} />
			<components.Option {...props} />
		</OptionWrapper>
	);
};

const ModelSelect = ({ models }) => {
	const { control } = useFormContext();

	const filterOptions = (candidate, input) => {
		if (input) {
			const {
				label,
				data: { details },
			} = candidate ?? {};
			const { classes } = details ?? {};

			const lowerInput = input.toLowerCase();
			const lowerLabel = label.toLowerCase();

			if (lowerLabel.includes(lowerInput)) {
				return true;
			}

			if (classes) {
				for (let i = 0; i < classes.length; i++) {
					const { description } = classes[i];
					if (description?.toLowerCase().includes(lowerInput)) {
						return true;
					}
				}
			}

			return false;
		}

		return true;
	};

	return (
		<>
			<Controller
				name="projectModels"
				control={control}
				render={({ field }) => (
					<Select
						{...field}
						id="model-select"
						label="Select a model *"
						options={models}
						noOptionsMessage={() => 'No models found'}
						components={{ Option }}
						filterOption={filterOptions}
						required
					/>
				)}
			/>
		</>
	);
};

const LimitInfo = ({ monthlyDetectionsLimit }) => {
	if (!monthlyDetectionsLimit) return null;

	const { monthly_detections, limit } = monthlyDetectionsLimit ?? {
		monthly_detections: 0,
		limit: 0,
	};

	const limitUsed = monthly_detections >= limit;

	if (limitUsed) {
		return (
			<div className="alert alert-warning mt-4">
				<p>
					You have used your monthly subscription limit of{' '}
					<strong>{limit} detections</strong> and can not start this
					project with a model.
				</p>
				<p className="small mb-0">
					You can still create the project. <br />
					Upgrade your subscription or wait until the subscription is
					renewed to run detections.
				</p>
			</div>
		);
	}

	return (
		<LimitInfoComponent
			className="text-end mt-4"
			limit={monthlyDetectionsLimit.limit}
			used={monthlyDetectionsLimit.monthly_detections}
			singularName="detection"
			pluralName="detections"
			actionPastTense="used"
			actionPresentTense="Running"
		/>
	);
};

const LoadingError = ({ error }) => {
	console.error('error loading models', error);

	return (
		<p className="alert alert-danger">
			Models could not be loaded. Please try again later.
		</p>
	);
};
