import * as Sentry from '@sentry/react';

const ignoredDomainsAndPaths = [
	'facebook.com',
	'fbevents',
	'google-analytics.com',
	'analytics.google',
	'analytics',
	'api.hubapi.com',
	'hscollectedforms',
	'/api/auth/',
	'/admin/',
];

const ignoredErrors = ['g.readyState'];

const sensitiveRoutes = ['/login', '/register'];

// Regular expression for matching email addresses
const EMAIL_REGEX = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

// Function to recursively scrub emails from objects
const scrubEmails = obj => {
	try {
		if (!obj) return obj;
		if (typeof obj === 'string') {
			return obj.replace(EMAIL_REGEX, '[EMAIL REDACTED]');
		}
		if (typeof obj !== 'object') return obj;

		const newObj = Array.isArray(obj) ? [] : {};
		for (const key in obj) {
			newObj[key] = scrubEmails(obj[key]);
		}
		return newObj;
	} catch (error) {
		console.warn('Error scrubbing emails:', error);
		return obj; // Return original object if scrubbing fails
	}
};

const initializeSentry = () => {
	const dsn = import.meta.env.VITE_SENTRY_DSN;
	if (!dsn) {
		console.warn('Sentry DSN not found in environment variables');
		return;
	}

	try {
		Sentry.init({
			dsn: import.meta.env.VITE_SENTRY_DSN,
			environment: process.env.NODE_ENV,
			ignoreTransactions: ignoredDomainsAndPaths.map(
				domainOrPath => new RegExp(domainOrPath)
			),
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.breadcrumbsIntegration({
					console: false, // Disable console in breadcrumbs
				}),
				Sentry.captureConsoleIntegration({
					levels: ['error'], // Only capture error level
				}),
				Sentry.replayIntegration({
					captureConsole: false, // Disable console capture in replay
					beforeAddRecordingEvent: event => {
						// Filter out console.log events
						if (
							event.data.payload.category === 'console' &&
							event.data.payload.level === 'log'
						) {
							return null;
						}
						return event;
					},
				}),
			],
			ignoreErrors: ignoredErrors,
			beforeSend(event) {
				// Early return if no request data
				if (!event.request) return event;

				const { request } = event;
				const fieldsToScrub = ['data', 'query_string', 'url', 'body'];

				// Scrub emails from all fields
				fieldsToScrub.forEach(field => {
					if (request[field]) {
						request[field] = scrubEmails(request[field]);
					}
				});

				// Filter sensitive route data
				if (
					request.url &&
					sensitiveRoutes.some(route => request.url.includes(route))
				) {
					request.data = '[Filtered]';
				}

				return event;
			},
			beforeBreadcrumb(breadcrumb, hint) {
				if (!breadcrumb) return null;
				// Filter out XHR breadcrumbs for specific domains
				if (breadcrumb.category === 'xhr') {
					const url = hint?.xhr?.url || breadcrumb?.data?.url;
					if (!url) return breadcrumb;

					if (
						ignoredDomainsAndPaths.some(domainOrPath =>
							url.includes(domainOrPath)
						)
					) {
						return null;
					}
				}
				return breadcrumb;
			},
			// Tracing
			tracesSampleRate: 0.1,
			// Session Replay
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		});
	} catch (error) {
		console.warn('Error initializing Sentry:', error);
	}
};

// Only initialize Sentry if we're not in development
if (process.env.NODE_ENV !== 'development') {
	initializeSentry();
}

export { Sentry };
