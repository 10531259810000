import AdminView from '@components/layout/views/AdminView';
import Models from './components/Models';

const ModelsView = () => {
	return (
		<AdminView>
			<Models />
		</AdminView>
	);
}
export default ModelsView;
