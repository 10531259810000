import { useEffect } from 'react';
import Intercom, { boot, shutdown } from '@intercom/messenger-js-sdk';

import { useAuth } from '@contexts/User.context';
const INTERCOM_APP_ID = import.meta.env.VITE_APP_INTERCOM_APP_ID;

// Possible settings -> https://developers.intercom.com/installing-intercom/web/customization

const Chatbot = () => {
	const { currentUser, userCookieConsent } = useAuth();

	const hasIntercomConsent = userCookieConsent?.includes('preferences:true');

	// Set Intercom settings
	let intercomSettings = {
		app_id: INTERCOM_APP_ID,
		hide_default_launcher: true,
		horizontal_padding: -25,
		disabled: !hasIntercomConsent,
	};

	// Initialize Intercom
	Intercom(intercomSettings);

	useEffect(() => {
		if (currentUser?.chatbot_hmac && hasIntercomConsent) {
			const logedInSettings = {
				user_id: currentUser.uuid,
				name: currentUser.name,
				email: currentUser.email,
				created_at: Math.floor(
					new Date(currentUser?.created_at).getTime() / 1000
				), // Convert totimestamp
				user_hash: currentUser.chatbot_hmac,
				disabled: false,
			};
			intercomSettings = { ...intercomSettings, ...logedInSettings };

			boot(intercomSettings);
		} else if (hasIntercomConsent) {
			intercomSettings = { ...intercomSettings, disabled: false };
			boot(intercomSettings);
		} else {
			shutdown();
		}
	}, [hasIntercomConsent, currentUser]);
};

export default Chatbot;
