import { useEffect, useMemo, useState, useRef } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-bootstrap/Spinner';

import { LoadingDots } from '@components/loading/LoadingDots';

import { getProjectNotifications } from '@utils/api';
import {
	getNotificationInterval,
	extractLatestNotificationJobProgress,
} from '@utils/notifications';
import Badge from '@components/Badge';

const AbsoluteContainer = styled.div`
	position: absolute;
	left: 0.5rem;
	top: 0.5rem;
	background-color: ${props =>
		`rgba(${props.theme.colors.backgroundRgb}, 0.9)`};
	width: calc(100% - 1rem);
	z-index: 2;
`;

const Wrapper = styled.div`
	padding: 6px 10px 10px;
	border: 1px solid ${p => p.theme.colors.border};
	border-radius: 5px;
`;

const ProjectProgress = ({ project }) => {
	const { uuid, image_mode, demo_project } = project;

	const queryClient = useQueryClient();

	const notificationIntervalMs = useRef(false);
	const [progress, setProgress] = useState(null);

	// Get all notifications for the project
	const { data: notificationsData } = useQuery({
		queryKey: ['project-notifications', uuid],
		queryFn: () => getProjectNotifications(uuid),
		enabled: !!uuid && !demo_project,
		refetchInterval: notificationIntervalMs.current,
		retry: false,
	});
	const notifications = notificationsData?.notifications;

	// Find the newest notification that is still running
	const activeNotification = useMemo(() => {
		if (!notifications) return null;

		const running = notifications.find(
			n => n.job_status === 'running' || n.job_status === 'pending'
		);

		return running || null;
	}, [notifications]);

	const setActiveNotificationInterval = () => {
		if (!notificationIntervalMs.current) {
			notificationIntervalMs.current =
				getNotificationInterval(image_mode);
		}
	};

	const refetchProjectThumbnail = () => {
		// When a project is finished, we need to refetch the project thumbnail
		queryClient.invalidateQueries({
			queryKey: ['project_thumbnail', uuid],
		});
		queryClient.invalidateQueries({
			queryKey: ['user_projects'],
		});
	};

	useEffect(() => {
		if (!activeNotification) {
			return;
		}

		const { message, job_status } = activeNotification || {};

		if (job_status) {
			const {
				progress: prog,
				text,
				classification,
			} = extractLatestNotificationJobProgress(message);

			let newProgress = {
				label: null,
				value: 0,
				classification,
			};

			switch (job_status) {
				case 'running':
					newProgress.label = (
						<>
							{text} <LoadingDots />{' '}
						</>
					);
					newProgress.value = Math.ceil(prog);

					setActiveNotificationInterval();

					break;
				case 'pending':
					newProgress.label = (
						<>
							Pending startup <LoadingDots />{' '}
						</>
					);
					newProgress.value = 0;

					setActiveNotificationInterval();

					break;
				default:
					newProgress = null;
			}

			setProgress(newProgress);
		}

		return () => {
			notificationIntervalMs.current = false;
			setProgress(null);
			refetchProjectThumbnail();
		};
	}, [activeNotification]);

	if (!activeNotification || !progress?.label) return null;

	return (
		<AbsoluteContainer>
			<Wrapper>
				{progress.classification && (
					<div>
						<Badge>{progress.classification}</Badge>
					</div>
				)}
				{!progress ||
					(progress.value === 0 && (
						<div className="d-flex align-items-center justify-content-between mt-1">
							<span className="small text-muted">
								{progress.label}
							</span>
							<Spinner
								animation="border"
								variant="success"
								size="sm"
								role="status"
							/>
						</div>
					))}
				{progress?.value > 0 && (
					<>
						<span className="small text-muted d-inline-block mb-1">
							{progress.label}
						</span>
						<ProgressBar
							now={progress.value}
							label={`${progress.value} %`}
							variant="success"
						/>
					</>
				)}
			</Wrapper>
		</AbsoluteContainer>
	);
};

export default ProjectProgress;
