import { Link } from 'react-router';
import { useMutation } from '@tanstack/react-query';

import Button from 'react-bootstrap/Button';
import Alert from '@components/alert/Alert';

import { useModelContext } from '@contexts/Model.context';
import { useToast } from '@contexts/Toast.context';

import { initTraining } from '@api';
import { useState } from 'react';

const Train = () => {
	const {
		modelUuid,
		canBeTrained,
		isTrained,
		hasOngoingJob,
		annotationInfoLoading,
		modelInfoLoading,
	} = useModelContext();
	const { addToast } = useToast();

	const [show, setShow] = useState(true);

	const { mutate: startTraining } = useMutation({
		mutationFn: () => initTraining({ modelId: modelUuid }),
		onError: err => {
			console.error(
				'Error starting training from single model page',
				err
			);
			addToast({
				id: `start_training_error-${new Date().getTime()}`,
				title: `Failed to start training: ${
					err.response?.data?.detail || err.message || 'Unknown error'
				}`,
				bg: 'danger',
			});
		},
		onSuccess: () => {
			addToast({
				id: `start_training_success-${new Date().getTime()}`,
				title: 'Training started successfully',
				bg: 'success',
			});
			setShow(false);
		},
	});

	const isLoading = modelInfoLoading || annotationInfoLoading;

	// Return nothing if not show or if model is trained and can't be trained, or has ongoing job
	if (!show || (isTrained && !canBeTrained) || hasOngoingJob || isLoading) {
		return null;
	}

	// Alert for not ready for training and has not enough training data
	if (!isTrained && canBeTrained === false) {
		return (
			<Alert
				variant="warning"
				heading="Not ready for AI training"
				className="my-4">
				<p>
					This model is not ready for training. At least{' '}
					<strong>10</strong> annotations over <strong>6</strong>{' '}
					tiles is needed to enable AI training.
				</p>
				<p>
					<Link className="inherit" to="/projects">
						Go to projects
					</Link>
				</p>
			</Alert>
		);
	}

	// Alert for when training is available
	return (
		<Alert
			variant="dark"
			heading="AI training available"
			className="my-4"
			dismissible={true}>
			<p>
				This model contains training data that has not been sent for AI
				training yet.
				<br />
				You can start the training process from here.
			</p>
			<div>
				<Button onClick={startTraining} variant="success" size="md">
					Start AI training
				</Button>
			</div>
		</Alert>
	);
};

export default Train;
