import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import Table from 'react-bootstrap/Table';
import { Tooltip } from 'react-tooltip';

import BooleanValue from '@components/booleanValue/BooleanValue';
import TaskTypeIcon from '@components/taskTypeIcon/TaskTypeIcon';
import DateFormatted from '@components/dateFormatted/DateFormatted';

import { TitleLink } from './CellComponents';
import { IsTrainedHeader } from './HeaderComponents';

const tooltipContainerId = 'tooltip-models-table';

const defaultData = [];
const columnHelper = createColumnHelper();

const columns = [
	columnHelper.accessor('description', {
		header: () => <span>Name</span>,
		cell: info => <NameHeader info={info} />,
	}),
	columnHelper.accessor('updated_at', {
		header: () => <span>Last updated</span>,
		cell: info => (
			<span className="text-muted">
				<DateFormatted inputDate={info.getValue()} />
			</span>
		),
	}),
	columnHelper.accessor('is_trained', {
		header: () => (
			<IsTrainedHeader tooltipContainerId={tooltipContainerId} />
		),
		cell: info => (
			<div className="ps-1">
				<BooleanValue value={info.getValue()} />
			</div>
		),
	}),
];

const ModelsTable = ({ customModels }) => {
	if (!customModels || customModels.length === 0) {
		return <p>No models found</p>;
	}

	const table = useReactTable({
		data: customModels || defaultData,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div>
			<Table responsive hover>
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<TableHeaderGroup
							key={headerGroup.id}
							headerGroup={headerGroup}
						/>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => (
						<TableRow key={row.id} row={row} />
					))}
				</tbody>
			</Table>
			<Tooltip id={tooltipContainerId} />
		</div>
	);
};
export default ModelsTable;

const TableHeaderGroup = ({ headerGroup }) => {
	return (
		<tr>
			{headerGroup.headers.map(header => (
				<TableHeader key={header.id} header={header} />
			))}
		</tr>
	);
};

const TableHeader = ({ header }) => {
	return (
		<th>
			{!header.isPlaceholder &&
				flexRender(header.column.columnDef.header, header.getContext())}
		</th>
	);
};

const TableRow = ({ row }) => {
	return (
		<tr>
			{row.getVisibleCells().map(cell => (
				<TableCell key={cell.id} cell={cell} />
			))}
		</tr>
	);
};

const TableCell = ({ cell }) => {
	return <td>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
};

const NameHeader = ({ info }) => {
	return (
		<div className="d-flex align-items-center gap-3">
			<TaskTypeIcon
				task_type={info?.row?.original?.classes?.[0]?.task_type}
				tooltipContainerId={tooltipContainerId}
				tooltipVariant="light"
			/>
			<TitleLink
				title={info.getValue()}
				modelUuid={info?.row?.original?.uuid}
				description={info?.row?.original?.hover_description}
			/>
		</div>
	);
};
