// React imports
import { useEffect, useState, useRef, useCallback } from 'react';

// Third-party library imports
import { useQuery, useMutation } from '@tanstack/react-query';
import { Tooltip } from 'react-tooltip';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { BiSave } from 'react-icons/bi';
import { VscWand } from 'react-icons/vsc';
import { BsSkipEnd } from 'react-icons/bs';

// Contexts
import { useProject } from '@contexts/Project.context';
import { useAuth } from '@contexts/User.context';
import { useToast } from '@contexts/Toast.context';

// Hooks
import useNotify from '@hooks/useNotify';
import useKeyboardShortcut from '@hooks/useKeyboardShortcut.hook';

// Utils
import {
	setSquareBoundary,
	addAnnotationId,
} from '@utils/map/annotation/annotation.interaction';
import {
	initAnnotationSquare,
	updateAnnotationSquare,
	removeAnnotationSquare,
} from '@utils/map/square.draw';
import {
	startObjectAnnotation,
	stopDrawingObjects,
	getObjectsInSquare,
	removeAllObjectsInSquare,
	setDrawInteraction as setObjectDrawInteraction,
} from '@utils/map/object.draw';
import {
	startPolygonAnnotation,
	getPolygonsInSquare,
	stopDrawingPolygons,
	removeAllPolygonsInSquare,
	setDrawInteraction as setPolygonDrawInteraction,
} from '@utils/map/polygon.draw';
import {
	addErasorInteraction,
	removeErasorInteraction,
	startErasor,
	stopErasor,
} from '@utils/map/erasor.interaction';
import { setLayersVisibilityByCustomIds } from '@utils/map/helpers';
import { MODEL_TYPE } from '@utils/constants';

// Components
import {
	AnnotationToolBar,
	KeyboardShortcut,
} from '@routes/userRoutes/projects/singleProject/components/SharedStyles';
import LimitInfo from '@components/subscription/LimitInfo';
import UtilityButton from './utilityButtons/UtilityButton';
import AnnotationDrawTools from './utilityButtons/AnnotationDrawTools';

// API
import {
	postTileData,
	initTraining,
	getMonthlyAITrainingLimit,
	getAnnotationInfoForModel,
} from '@api';

const getTileAnnotations = ({
	modelType,
	squaresInAreaOfInterest,
	activeSquareIndex,
	taskId,
}) => {
	// Ensure activeSquareIndex is within bounds
	if (
		activeSquareIndex < 0 ||
		activeSquareIndex >= squaresInAreaOfInterest.length
	) {
		throw new Error('Invalid activeSquareIndex');
	}

	const activeSquare = squaresInAreaOfInterest[activeSquareIndex];
	let data;

	switch (modelType) {
		case MODEL_TYPE.OBJECT_DETECTION:
			data = getObjectsInSquare(activeSquare, taskId);
			break;
		case MODEL_TYPE.SEGMENTATION:
			data = getPolygonsInSquare(activeSquare);
			break;
		default:
			throw new Error('Invalid modelType');
	}

	return data;
};

const removeAnnotationsInSquare = ({
	modelType,
	currentTileName,
	annotations,
}) => {
	switch (modelType) {
		case MODEL_TYPE.OBJECT_DETECTION:
			removeAllObjectsInSquare(currentTileName);
			break;
		case MODEL_TYPE.SEGMENTATION:
			removeAllPolygonsInSquare(annotations);
			break;
		default:
			throw new Error('Invalid modelType');
	}
};

export default function AnnotateOrthophoto({
	layersToShow,
	hideAnnotationSidebar,
}) {
	const {
		features,
		mapObject,
		polygonLayer,
		project,
		pickedTask,
		modelType,
		annotationMode,
		annotationDrawTool,
		toolBarVisible,
		colorOptions,
		confirmModalContent,
		annotationSidebarData,
		dispatch,
	} = useProject();

	const { model_uuid: taskId, description: taskDescription } = pickedTask;

	const { gridFeatures: squaresInAreaOfInterest } = annotationMode;

	const { checkForNotifications } = useNotify();
	const { addToast } = useToast();

	const [loading, setLoading] = useState(false);

	const [activeSquareIndex, setActiveSquareIndex] = useState(0);

	const [drawing, setDrawing] = useState(true);

	const isTraining = useRef(false); // To prevent re-rendering of the annotation type

	const { mutate: startTraining } = useMutation({
		mutationFn: () =>
			initTraining({
				projectUuid: project.uuid,
				modelId: taskId,
			}),
		onError: err => {
			console.error(
				'Error starting training from orthophoto annotate',
				err
			);
			addToast({
				id: `training_error-${new Date().getTime()}`,
				className: 'bg-danger',
				title: `Training model '${pickedTask.description}' failed:`,
				message:
					err.response?.data?.detail ||
					err.message ||
					'Unknown error starting training.',
				autohide: false,
			});
		},
		onSuccess: res => {
			if (res?.warning) {
				addToast({
					id: `training_warning-${new Date().getTime()}`,
					className: 'bg-warning',
					title: `Training model '${pickedTask.description}' started with warnings:`,
					message: res.warning,
					autohide: true,
				});
			} else if (res?.error) {
				addToast({
					id: `training_error-${new Date().getTime()}`,
					className: 'bg-danger',
					title: `Training model '${pickedTask.description}' failed:`,
					message: res.error,
					autohide: false,
				});
			} else {
				addToast({
					id: `training_success-${new Date().getTime()}`,
					className: 'bg-success',
					title: `Training model '${pickedTask.description}' started successfully`,
					message: 'The process will take some time to complete.',
					autohide: true,
				});
			}
		},

		onSettled: () => {
			stopAnnotation();
			setTimeout(() => {
				checkForNotifications();
			}, 5000);
		},
	});

	const saveAndExit = async () => {
		const confirmContinue = await saveTileData();

		if (confirmContinue) {
			stopAnnotation();
		}
	};

	// End annotation session and start training
	const endAnnotation = async () => {
		const confirmContinue = await saveTileData();

		if (confirmContinue) {
			startTraining();
		}
	};

	// Stop annotation session
	const stopAnnotation = () => {
		console.log('stopping annotation');

		if (mapObject) {
			const layers = mapObject.getLayers();
			layers?.forEach(layer => {
				const name = layer.get('name');
				if (layersToShow.includes(name)) {
					layer.setVisible(true);
				}
			});
		}

		setActiveSquareIndex(null);
		removeAnnotationSquare(mapObject);
		removeErasorInteraction(mapObject);

		mapObject.on('pointermove', () => {
			mapObject.getTargetElement().style.cursor = 'auto';
		});

		let confirmStop = false;

		if (modelType === MODEL_TYPE.OBJECT_DETECTION) {
			confirmStop = stopDrawingObjects(mapObject);
		} else if (modelType === MODEL_TYPE.SEGMENTATION) {
			confirmStop = stopDrawingPolygons(mapObject);
		}

		if (confirmStop) {
			dispatch({
				type: 'setDialogue',
				payload: null,
			});
			dispatch({
				type: 'setToolBarVisible',
				payload: true,
			});
			dispatch({
				type: 'setAnnotationMode',
				payload: null,
			});
			dispatch({
				type: 'setAnnotationDrawTool',
				payload: null,
			});

			setLayersVisibilityByCustomIds(mapObject, layersToShow, true);
			hideAnnotationSidebar();
		}
	};

	// Start drawing
	const startDraw = () => {
		if (drawing) return;

		setDrawing(true);
		stopErasor(mapObject);
	};

	// Start erasing
	const startErase = () => {
		if (!drawing) return;

		setDrawing(false);
		startErasor(mapObject);
	};

	// Check if there are annotations in the current square before skipping
	const checkTileBeforeSkip = () => {
		setLoading(true);

		const { annotations } = getTileAnnotations({
			modelType,
			squaresInAreaOfInterest,
			activeSquareIndex,
			taskId,
		});

		if (!annotations) {
			// Annotations should exist
			setLoading(false);
			return false;
		}

		let confirmContinue = true;
		// We need to check if there are any annotations that are not machine annotations
		const addedAnnotations = annotations.filter(
			annotation => !annotation.machine_annotation
		);
		if (addedAnnotations?.length > 0) {
			if (
				window.confirm(
					'You have made new annotations in this square. Skipping will discard them. Still good to go?'
				)
			) {
				// Remove the annotations
				const currentTileName =
					squaresInAreaOfInterest[activeSquareIndex]?.properties
						.tile_name;
				removeAnnotationsInSquare({
					modelType,
					currentTileName,
					annotations: addedAnnotations,
				});
			} else {
				confirmContinue = false;
			}
		}

		setLoading(false);
		return confirmContinue;
	};

	// Save the annotations in the current square
	const saveTileData = async () => {
		setLoading(true);

		const data = getTileAnnotations({
			modelType,
			squaresInAreaOfInterest,
			activeSquareIndex,
			taskId,
		});

		if (!data) {
			setLoading(false);
			return false;
		}

		if (!data?.annotations || data?.annotations?.length === 0) {
			if (
				!window.confirm(
					"No new annotations were made in this square. It could affect the model if something's missed. Still good to go?"
				)
			) {
				// Cancel saving data to annotate more
				setLoading(false);
				return false;
			}

			// Backend does not want an empty tile to be posted if there are overlapping annotations
			if (data.hasOverlappedAnnotations) {
				console.log(
					'Skipping saving empty tile with overlapping annotations'
				);
				setLoading(false);
				return true;
			}
		}

		const currentTileName =
			squaresInAreaOfInterest[activeSquareIndex]?.properties.tile_name;

		const res = await postTileData(
			project?.uuid,
			taskId,
			currentTileName,
			data.annotations
		)
			.then(() => {
				return true;
			})
			.catch(error => {
				console.error('Error saving orthophoto annotation data', error);
				return false;
			})
			.finally(() => {
				setLoading(false);
			});

		return res;
	};

	// change the square to annotate
	const onChangeSquare = index => {
		setActiveSquareIndex(index);
		setSquareBoundary(squaresInAreaOfInterest[index]);
		updateAnnotationSquare(squaresInAreaOfInterest[index], mapObject);
	};

	// Move to the next square
	const nextSquare = async ({ skip = false }) => {
		if (loading) return;

		if (activeSquareIndex < squaresInAreaOfInterest.length - 1) {
			let confirmContinue = true;
			if (skip) {
				confirmContinue = checkTileBeforeSkip();
				console.log('skipping tile');
			} else {
				confirmContinue = await saveTileData();
				console.log('saving tile');
			}

			if (confirmContinue) {
				onChangeSquare(activeSquareIndex + 1);
			}
		} else if (activeSquareIndex === squaresInAreaOfInterest.length - 1) {
			endAnnotation();
		}
	};

	// Creates a confirm modal to cancel the annotation session
	const cancel = () => {
		dispatch({
			type: 'setConfirmModalContent',
			payload: {
				title: 'Cancel annotation session',
				message:
					'Are you sure you want to cancel the annotation mode and exit?',
				onConfirm: stopAnnotation,
				onCancel: () => {},
			},
		});
	};

	// Keyboard shortcuts
	useKeyboardShortcut(
		'Enter',
		!!annotationMode && confirmModalContent === null,
		() => nextSquare({ skip: false })
	);
	useKeyboardShortcut(
		'Escape',
		!toolBarVisible && confirmModalContent === null,
		() => cancel()
	);

	useEffect(() => {
		// change the relevant draw interaction to use the correct draw tool

		if (modelType === MODEL_TYPE.SEGMENTATION) {
			setPolygonDrawInteraction({
				map: mapObject,
				freehand: annotationDrawTool === 'Freehand',
				colorOptions,
			});
		} else if (modelType === MODEL_TYPE.OBJECT_DETECTION) {
			setObjectDrawInteraction({
				mapReference: mapObject,
				drawTool: annotationDrawTool,
			});
		}
	}, [annotationDrawTool]);

	useEffect(() => {
		const { activeClassId } = annotationSidebarData || {
			activeClassId: 0,
		};
		addAnnotationId(activeClassId);

		if (!drawing) {
			// if erasor is active when changing the annotation type
			// we need to start drawing again
			startDraw();
		}
	}, [annotationSidebarData]);

	// Starts the right annotation type
	useEffect(() => {
		if (activeSquareIndex === null || isTraining.current) return;

		isTraining.current = true;
		console.log('Starting annotation mode');

		if (modelType === MODEL_TYPE.OBJECT_DETECTION) {
			console.log('Starting object annotation');
			startObjectAnnotation({
				colorOptionsInput: colorOptions,
				existingObjects: features,
				squareList: squaresInAreaOfInterest,
				mapReference: mapObject,
			});
		} else if (modelType === MODEL_TYPE.SEGMENTATION) {
			console.log('Starting polygon annotation');
			startPolygonAnnotation({
				colorOptions,
				existingPolygonLayer: polygonLayer,
				squareList: squaresInAreaOfInterest,
				mapReference: mapObject,
			});
		}

		dispatch({
			type: 'setDialogue',
			payload: {
				header: 'Annotating',
				body: (
					<>
						<p>
							Annotate all relevant items in the square before
							clicking "Continue".
						</p>
						<p className="small text-muted">
							Also use "Continue" on tiles without target items to
							help the model learn to recognize empty areas. Only
							use "Skip" if you don't want to train the model on
							this tile.
						</p>
					</>
				),
				fullWidth: true,
				dismissible: true,
			},
		});

		initAnnotationSquare(
			squaresInAreaOfInterest[activeSquareIndex],
			mapObject
		); // draws the first square

		setSquareBoundary(squaresInAreaOfInterest[activeSquareIndex]);

		addErasorInteraction(modelType, mapObject);
	}, []);

	return (
		<>
			<AnnotationToolBar aria-label="Annotation tools">
				<Tooltip
					id="annotate-tip"
					variant="light"
					render={({ content, activeAnchor }) => (
						<span>
							{content}{' '}
							<KeyboardShortcut>
								{activeAnchor?.getAttribute(
									'data-tooltip-keyboardshortcut'
								)}
							</KeyboardShortcut>
						</span>
					)}
				/>

				<div>
					<UtilityButton
						label="Cancel"
						tooltip={{
							id: 'annotate-tip',
							content: 'Cancel the annotation mode and exit',
							place: 'top',
						}}
						onClick={cancel}
						variant="danger"
						keyboardShortcutLabel="ESC"
					/>
					<UtilityButton
						label="Save and exit"
						tooltip={{
							id: 'annotate-tip',
							content:
								'Save your annotations and exit the annotation mode',
							place: 'top',
						}}
						onClick={saveAndExit}
						variant="dark"
						icon={() => <BiSave />}
					/>
				</div>

				<AnnotationDrawTools
					startDraw={startDraw}
					startErase={startErase}
					drawing={drawing}
				/>

				<div className="d-flex gap-3 align-items-center">
					<div>
						Annotating {activeSquareIndex + 1} /{' '}
						{squaresInAreaOfInterest.length}
					</div>
					<ButtonGroup>
						<StartAITrainingButton
							taskId={taskId}
							squaresInAreaOfInterest={squaresInAreaOfInterest}
							activeSquareIndex={activeSquareIndex}
							saveAndExit={saveAndExit}
							endAnnotation={endAnnotation}
							loading={loading}
						/>

						{activeSquareIndex <
							squaresInAreaOfInterest.length - 1 && (
							<>
								<UtilityButton
									label="Skip"
									tooltip={{
										id: 'annotate-tip',
										content:
											'Ignore the current tile and move to the next tile',
										place: 'top',
									}}
									disabled={loading}
									loading={loading}
									onClick={() => nextSquare({ skip: true })}
									variant="dark"
									icon={() => <BsSkipEnd />}
								/>
								<UtilityButton
									label="Continue"
									tooltip={{
										id: 'annotate-tip',
										content:
											'Save the annotations in the current tile and move to the next tile',
										place: 'top',
									}}
									disabled={loading}
									loading={loading}
									onClick={nextSquare}
									variant="success"
									keyboardShortcutLabel="↵"
								/>
							</>
						)}
					</ButtonGroup>
				</div>
			</AnnotationToolBar>
		</>
	);
}

const StartAITrainingButton = ({
	taskId,
	squaresInAreaOfInterest,
	activeSquareIndex,
	saveAndExit,
	endAnnotation,
	loading,
}) => {
	const { tierPro, subscription } = useAuth();
	const { modelType, dispatch } = useProject();
	const { addToast } = useToast();

	const {
		data: monthlyTrainingsLimit,
		isLoading: monthlyTrainingsLimitLoading,
	} = useQuery({
		queryKey: ['monthlyTrainingsLimit', subscription?.id],
		queryFn: getMonthlyAITrainingLimit,
		enabled: !!subscription?.id && tierPro,
	});

	const { data: annotationInfo } = useQuery({
		queryKey: ['annotationInfo', taskId, activeSquareIndex],
		queryFn: () => getAnnotationInfoForModel(taskId),
		enabled: !!taskId,
		retry: 0,
	});
	const {
		can_be_trained: canBeTrained,
		total_number_of_annotations: existingAnnotations,
		total_number_of_tiles: annotatedTiles,
	} = annotationInfo || { can_be_trained: true };

	const disabled = loading || (tierPro && monthlyTrainingsLimitLoading);

	const startAiMessage = useCallback(() => {
		const checkTrainingData = () => {
			try {
				const { annotations } = getTileAnnotations({
					modelType,
					squaresInAreaOfInterest,
					activeSquareIndex,
					taskId,
				});

				const totalAnnotations =
					(annotations?.length ?? 0) + existingAnnotations;
				const totalTiles = annotatedTiles + 1;

				if (!totalAnnotations || !totalTiles) {
					// No data to check so let the backend decide
					return true;
				}

				if (totalAnnotations < 10 && totalTiles < 6) {
					addToast({
						id: `training_warning-${new Date().getTime()}`,
						className: 'bg-warning',
						autohide: false,
						title: `Not enough data to start training`,
						message: (
							<>
								You need to have at least{' '}
								<strong>10 annotations</strong> and{' '}
								<strong>6 annotated</strong> tiles to start
								training.
							</>
						),
					});
					return false;
				}

				return true;
			} catch (error) {
				console.warn(
					'Could not check if data can be trained. Letting the backend decide.',
					error
				);
				return true;
			}
		};

		const showTrainingConfirmation = () => {
			const message = () => (
				<>
					<p>
						Are you sure you want to end the annotation session?
						Your data will be saved and AI training will start.
					</p>
					{monthlyTrainingsLimit && (
						<LimitInfo
							limit={monthlyTrainingsLimit.limit}
							used={monthlyTrainingsLimit.monthly_ai_training}
							singularName="training"
							pluralName="trainings"
							actionPastTense="used"
							actionPresentTense="Starting"
						/>
					)}
				</>
			);

			dispatch({
				type: 'setConfirmModalContent',
				payload: {
					title: 'Start AI training',
					message: message(),
					onConfirm: endAnnotation,
					onCancel: () => {},
				},
			});
		};

		if (!canBeTrained && !checkTrainingData()) {
			return;
		}

		showTrainingConfirmation();
	}, [annotationInfo, dispatch, endAnnotation, monthlyTrainingsLimit]);

	const saveAndTrain = useCallback(async () => {
		if (tierPro) {
			try {
				const { limit, monthly_ai_training } = monthlyTrainingsLimit;

				if (!limit || monthly_ai_training === undefined) {
					throw new Error('No limit or monthly training data');
				}

				if (monthly_ai_training >= limit) {
					addToast({
						id: `training_warning-${new Date().getTime()}`,
						className: 'bg-danger',
						autohide: false,
						title: `You have reached your subscription limit of ${limit} AI training sessions`,
						message:
							'Annotations are saved but you cannot start a new training session within this subscription period.',
					});

					saveAndExit();
					return;
				}
			} catch (error) {
				console.error(
					'Error fetching subscription data when sending model to training',
					error
				);
				addToast({
					id: `training_warning-${new Date().getTime()}`,
					className: 'bg-danger',
					title: `Training not started`,
					message: `Annotations are saved but we are having trouble fetching your subscription training limit. Please try again later.`,
					autohide: false,
				});

				saveAndExit();
				return;
			}
		}

		startAiMessage();
	}, [tierPro, monthlyTrainingsLimit, addToast, saveAndExit, startAiMessage]);

	return (
		<UtilityButton
			label="Start AI training"
			tooltip={{
				id: 'annotate-tip',
				content: 'End annotation session and start AI training',
				place: 'top',
			}}
			onClick={saveAndTrain}
			disabled={disabled}
			variant="dark"
			icon={() => <VscWand />}
		/>
	);
};
