import { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';

import { useModelContext } from '@contexts/Model.context';
import { PROJECT_MODE, MODEL_TYPE } from '@utils/constants';

import View from '@components/layout/views/View';
import ModelTrainings from './components/modelTrainings/ModelTrainings';

import Train from './components/modelTrainings/Train';
import Loader from '@components/loading/Loader';
import Alert from '@components/alert/Alert';
import Annotations from './components/Annotations';
import Chart from './components/Chart';
import Detections from './components/Detections';
import TaskTypeIcon from '@components/taskTypeIcon/TaskTypeIcon';
import DeleteModel from './components/DeleteModel';

const SingleModel = ({
	ViewComponent = View,
	breadcrumbsRootLink = '/models',
}) => {
	const { model_uuid } = useParams();
	const { setModelUuid, modelName, modelInfoLoading, compatibleProjectType } =
		useModelContext();

	useEffect(() => {
		setModelUuid(model_uuid);
	}, [model_uuid, setModelUuid]);

	const breadcrumbs = [
		{
			title: 'Models',
			link: breadcrumbsRootLink + '?tab=' + compatibleProjectType,
		},
		{
			title: modelName || '',
			link: '/models/' + model_uuid,
		},
	];

	return (
		<ViewComponent
			title={modelName ? <Name /> : ''}
			breadcrumbs={breadcrumbs}>
			{modelInfoLoading ? <Loader /> : <ModelContent />}
			<Tooltip id="tooltip-container" />
		</ViewComponent>
	);
};
export default SingleModel;

const Name = () => {
	const { modelName, modelType } = useModelContext();
	return (
		<span className="d-flex align-items-center gap-2">
			<TaskTypeIcon
				task_type={modelType}
				tooltipContainerId="tooltip-container"
			/>
			<span>{modelName}</span>
		</span>
	);
};

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(12, minmax(0, 1fr));
	grid-gap: 1rem;
	margin-top: 1rem;

	.card {
		background-color: ${props => props.theme.colors.modal};
		border-color: ${props => props.theme.colors.border};
		min-height: 100%;
	}
`;

const Widget = styled.div`
	grid-column: span ${props => props.span || 12};
`;

const ModelContent = () => {
	const { isTrained } = useModelContext();

	return (
		<>
			<ModelDetails />

			<RunningJobInfo />

			<Train />

			{isTrained ? <IsTrainedContent /> : <NotTrainedContent />}

			<DeleteModel />
		</>
	);
};

const Detail = styled.p`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 0.5rem;
	margin: 0 0 0.125rem;
	span:first-child {
		color: ${props => props.theme.colors.textAccent};
	}
`;

const ModelDetails = () => {
	const { modelDescription, modelObjectSize, compatibleProjectType } =
		useModelContext();

	const compatibleProjectTypeText = () => {
		switch (compatibleProjectType) {
			case PROJECT_MODE.ORTHOPHOTO:
				return 'Orthophoto';
			case PROJECT_MODE.SINGLE_IMAGE:
				return 'Single image';
			default:
				return '';
		}
	};
	return (
		<>
			<div className="mb-4">
				{modelDescription && (
					<Detail>
						<span>Description:</span>{' '}
						<span>{modelDescription}</span>
					</Detail>
				)}
				{modelObjectSize && (
					<Detail>
						<span>Approx. size:</span>{' '}
						<span>~ {modelObjectSize}m</span>
					</Detail>
				)}
				<Detail>
					<span>For project type:</span>{' '}
					<span>{compatibleProjectTypeText()}</span>
				</Detail>
			</div>
		</>
	);
};

const IsTrainedContent = () => {
	return (
		<Grid>
			<Widget span={12}>
				<Chart />
			</Widget>

			<Widget span={12}>
				<ModelTrainings />
			</Widget>

			<Widget span={6}>
				<Annotations />
			</Widget>

			<Widget span={6}>
				<Detections />
			</Widget>
		</Grid>
	);
};

const NotTrainedContent = () => {
	return (
		<Grid>
			<Widget span={6}>
				<Annotations />
			</Widget>
		</Grid>
	);
};

const RunningJobInfo = () => {
	const { hasOngoingJob } = useModelContext();

	if (!hasOngoingJob) return null;

	const title = 'Training and evaluating model';
	return (
		<Alert heading={title} variant="warning" dismissible>
			This model is currently beeing trained and evaluated. New data will
			be available soon.
		</Alert>
	);
};
