import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Modal, Image, Button } from 'react-bootstrap';
import { FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const ImageGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 1rem;
`;

const ImageButton = styled.button`
	border: none;
	background: none;
	padding: 0;
	cursor: pointer;
`;

const ImageLightbox = ({ images, thumbnails }) => {
	const [show, setShow] = useState(false);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const handleClose = useCallback(() => setShow(false), []);
	const handleShow = index => {
		setCurrentImageIndex(index);
		setShow(true);
	};

	const handlePrev = useCallback(() => {
		setCurrentImageIndex(prevIndex =>
			prevIndex > 0 ? prevIndex - 1 : images.length - 1
		);
	}, [images?.length]);

	const handleNext = useCallback(() => {
		setCurrentImageIndex(prevIndex =>
			prevIndex < images.length - 1 ? prevIndex + 1 : 0
		);
	}, [images?.length]);

	const handleKeyDown = useCallback(
		event => {
			if (event.key === 'ArrowLeft') {
				handlePrev();
			} else if (event.key === 'ArrowRight') {
				handleNext();
			} else if (event.key === 'Escape') {
				handleClose();
			}
		},
		[handlePrev, handleNext, handleClose]
	);

	useEffect(() => {
		if (show) {
			window.addEventListener('keydown', handleKeyDown);
		}
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [show, handleKeyDown]);

	if (!images?.length || !thumbnails?.length) return null;

	return (
		<>
			<ImageGrid>
				{thumbnails.map((image, index) => (
					<ImageButton key={index} onClick={() => handleShow(index)}>
						<Image
							src={image}
							alt={`Thumbnail ${index + 1}`}
							className="w-100 h-100 object-fit-cover"
						/>
					</ImageButton>
				))}
			</ImageGrid>

			<Modal show={show} onHide={handleClose} centered size="xl">
				<Modal.Body className="m-3 p-5 d-flex justify-content-center align-items-center">
					<Image
						src={images[currentImageIndex]}
						alt={`Image ${currentImageIndex + 1}`}
					/>
				</Modal.Body>
				<Button
					variant="outline-light"
					onClick={handleClose}
					className="position-absolute top-0 end-0 m-1 rounded-5 border-0"
					aria-label="Close">
					<FaTimes />
				</Button>
				<Button
					variant="outline-light"
					onClick={handlePrev}
					className="position-absolute top-50 start-0 translate-middle-y m-2"
					aria-label="Previous image">
					<FaChevronLeft />
				</Button>
				<Button
					variant="outline-light"
					onClick={handleNext}
					className="position-absolute top-50 end-0 translate-middle-y m-2"
					aria-label="Next image">
					<FaChevronRight />
				</Button>
			</Modal>
		</>
	);
};

export default ImageLightbox;
