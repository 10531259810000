import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import { TfiUpload } from 'react-icons/tfi';
import styled from 'styled-components';

import { useNewProject } from '@contexts/NewProject.context';
import { PROJECT_MODE } from '@utils/constants';
import { useAuth } from '@contexts/User.context';
import { uniqueValuesBy } from '@utils/helpers';
import useDragActive from '@newProject/utils/hooks/useDragActive';
import {
	getTotalFilesSize,
	mimeSplit,
	convertToMegabytes,
} from '@newProject/utils/helpers';

// JSX
import FileItem from './components/FileItem';
import UploadButton from './components/UploadButton';

const UploadFieldWrap = styled.div`
	border: 1px solid rgba(${({ theme }) => theme.colors.borderLightRgb}, 0.2);
	padding: 1rem;
	border-radius: 0.375rem;
`;

const UploadFieldContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem 1rem;
	text-align: center;
	transition: background-image 0.2s ease-out;
	--border-size: 3px;
	--border-angle: 0turn;
	border-radius: 0.375rem;
	background-image: conic-gradient(
			from var(--border-angle),
			#171717,
			#171717 50%,
			#171717
		),
		conic-gradient(from var(--border-angle), transparent 20%, #fff, #198754);
	background-size:
		calc(100% - (var(--border-size) * 2))
			calc(100% - (var(--border-size) * 2)),
		cover;
	background-position: center center;
	background-repeat: ${({ $animationActive }) =>
		$animationActive ? 'no-repeat' : 'initial'};
	background-color: #171717;
	animation: bg-spin 3s linear infinite;
	animation-play-state: ${({ $animationActive }) =>
		$animationActive ? 'running' : 'paused'};

	@keyframes bg-spin {
		to {
			--border-angle: 1turn;
		}
	}
	@property --border-angle {
		syntax: '<angle>';
		inherits: true;
		initial-value: 0turn;
	}
`;

const FileList = styled.div`
	background-color: #171717;
	border-radius: 0.375rem;
	height: 300px;
	max-height: 300px;
	overflow-y: auto;
`;

const UploadIcon = styled(TfiUpload)`
	margin-inline: auto;
	margin-block: 0.5rem;
	font-size: 1.5rem;
`;

const FileListHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: end;

	margin-top: 1rem;
	margin-bottom: 0.5rem;

	& > p {
		margin: 0;
	}

	& > button {
		padding-block: 0.125rem;
		padding-inline: 0.35rem;
		text-decoration: none;

		color: rgba(225, 225, 225, 0.5);
		border: 1px solid rgba(225, 225, 225, 0.5);
		&:hover {
			background: rgba(225, 225, 225, 0.2);
			color: rgba(225, 225, 225, 1);
			border: 1px solid rgba(225, 225, 225, 1);
		}

		&:focus-visible {
			box-shadow: 0 0 0 4px rgba(225, 225, 225, 0.2);
			color: rgba(225, 225, 225, 0.5);
			border: 1px solid rgba(225, 225, 225, 0.5);
		}

		&:active {
			background: rgba(225, 225, 225, 0.1) !important;
			color: rgba(225, 225, 225, 0.75) !important;
			border: 1px solid rgba(225, 225, 225, 0.75) !important;
		}
	}
`;

const NoFilesText = styled.small`
	display: grid;
	place-items: center;
	color: #b0b0b0;
	height: 100%;
	width: 100%;
	margin: 0;
`;

const UploadField = ({ ...props }) => {
	const { mode, files, setFiles, monthlyProjectsLimit, isSubmitting } =
		useNewProject();

	const { imageLimit } = monthlyProjectsLimit;
	const { ORTHOPHOTO, SINGLE_IMAGE } = PROJECT_MODE;
	const { tierTrial: isTrial, roleAdmin: isAdmin } = useAuth();

	const getMaxFiles = () => {
		if (isAdmin) return 0;
		if (isTrial) return 100;

		switch (mode) {
			case ORTHOPHOTO:
				return imageLimit ?? 0;

			case SINGLE_IMAGE:
				return 1000;

			default:
				return 0;
		}
	};

	const getLimitText = maxFiles => {
		if (isAdmin || !maxFiles) return '';
		if (isTrial)
			return `Limit of ${maxFiles} images or 200MB orthophoto on trial account.`;

		return `Limit of ${maxFiles} images for your subscription type.`;
	};

	const getMimeTypeInfo = () => {
		if (mode === ORTHOPHOTO) return '(jpg or orthophoto tiff)';
		if (mode === SINGLE_IMAGE) return '(jpg)';

		return '';
	};

	const getAcceptableFileTypes = () => {
		if (mode === ORTHOPHOTO)
			return {
				'image/jpeg': ['.jpg', '.jpeg'],
				'image/tiff': ['.tif', '.tiff'],
			};

		if (mode === SINGLE_IMAGE)
			return {
				'image/jpeg': ['.jpg', '.jpeg'],
			};

		return {};
	};

	const maxFiles = getMaxFiles();
	const limitText = getLimitText(maxFiles);
	const mimeTypeInfo = getMimeTypeInfo();
	const acceptableFileTypes = getAcceptableFileTypes();

	const [updateFileHandles, setUpdateFileHandles] = useState(0);
	useEffect(() => {
		// Update file handles to ensure user can upload same file after removing it.
		setUpdateFileHandles(prev => prev + 1);

		// Clear files if switching from orthophoto to single image with tiff file
		if (mode == SINGLE_IMAGE && files[0]?.type == 'image/tiff')
			setFiles([]);

		// Limit files to maxFiles
		if (files.length > maxFiles && maxFiles != 0)
			setFiles(files.slice(0, maxFiles));
	}, [files, mode]);

	// Handles rejected files and displays an error message
	const handleFileRejection = useCallback(
		fileRejection => {
			const errorCode = fileRejection?.[0]?.errors?.[0]?.code;
			if (!errorCode)
				return window.alert('Unknown error uploading files.');

			const errorMessages = {
				'too-many-files': `Too many files. Maximum number of files is ${maxFiles}.`,
				'file-invalid-type': `Invalid file type. Only .jpg/jpeg${
					mode === ORTHOPHOTO ? ' and .tif/tiff ' : ''
				}files are allowed.`,
			};

			window.alert(errorMessages[errorCode] ?? 'Error uploading files.');
		},
		[mode, maxFiles]
	);

	// Handles accepted files and dispatches them to context
	const handleAcceptedFiles = useCallback(
		acceptedFiles => {
			const allFiles = [...files, ...acceptedFiles];
			const uniqueMimeTypes = uniqueValuesBy(allFiles, file => file.type);
			const acceptedMimeType = uniqueMimeTypes[0];
			const nonAcceptedMimeType = uniqueMimeTypes.find(
				fileType => fileType !== acceptedMimeType
			);

			// Filters files by accepted mime type
			const filteredFiles = allFiles.filter(
				file => file.type === acceptedMimeType
			);

			// Deny upload of multiple tiff files
			if (acceptedMimeType == 'image/tiff' && filteredFiles.length > 1)
				return window.alert(
					'You can only upload one .tif/tiff file at a time.'
				);

			// Get total size of filtered files
			const totalFilteredFilesSize = getTotalFilesSize(filteredFiles);

			// Deny trial users from uploading files larger than 200MB
			if (isTrial && convertToMegabytes(totalFilteredFilesSize) > 200)
				return window.alert(
					'You have reached the 200MB limit for orthophoto on trial account.'
				);

			// Warn if mixing file types, but accept files of accepted mime type
			if (nonAcceptedMimeType)
				window.alert(
					`You can not mix ${mimeSplit(
						acceptedMimeType
					)} and ${mimeSplit(
						nonAcceptedMimeType
					)} files. Please upload only one type of files.`
				);

			// Set files to context
			setFiles(filteredFiles);
		},
		[files, mode]
	);

	// Dropzone hook
	const dragActive = useDragActive(['Files']);
	const { getRootProps, getInputProps } = useDropzone({
		onDropAccepted: handleAcceptedFiles,
		onDropRejected: handleFileRejection,
		maxFiles: maxFiles, // Only limits max files uploaded at once
		accept: acceptableFileTypes,
		disabled: isSubmitting,
		noClick: true,
		preventDropOnDocument: false,
		noKeyboard: true,
	});

	const handleRemoveAllFiles = () => {
		if (window.confirm('Are you sure you want to remove all files?'))
			setFiles([]);
	};
	return (
		<UploadFieldWrap {...props}>
			<p className="mb-1">Project files {mimeTypeInfo}</p>
			<UploadFieldContainer
				key={updateFileHandles}
				{...getRootProps()}
				$animationActive={dragActive}>
				<input {...getInputProps()} />
				<UploadIcon />
				<p>
					{dragActive
						? 'Drop the files here'
						: 'Drag and drop some files here.'}
					<br />
					<small className="text-muted">{limitText}</small>
				</p>

				<UploadButton inputProps={getInputProps()} />
			</UploadFieldContainer>

			<FileListHeader>
				<p>Files:</p>

				{files.length > 1 && (
					<Button variant="link" onClick={handleRemoveAllFiles}>
						Remove All
					</Button>
				)}
			</FileListHeader>
			<FileList>
				{files.length ? (
					files.map((file, index) => (
						<FileItem
							key={`${file.name}-${index}`}
							file={file}
							index={index}
						/>
					))
				) : (
					<NoFilesText>No files uploaded</NoFilesText>
				)}
			</FileList>
		</UploadFieldWrap>
	);
};

export default UploadField;
