import { Link } from 'react-router';
import { openCookieConsent } from '@utils/helpers';

const linkClass = 'text-muted small';

const Footer = ({ linkColSize = 'col-auto' }) => {
	const linkClassName = `${linkColSize} ${linkClass}`;

	return (
		<footer className="pt-5 pb-3 container px-0">
			<nav className="row justify-content-center">
				<Link to="/contact" className={linkClassName}>
					Contact Us
				</Link>
				<Link to="/privacy-policy" className={linkClassName}>
					Privacy Policy
				</Link>
				<Link to="/eula" className={linkClassName}>
					EULA
				</Link>
				<a
					href="#"
					onClick={openCookieConsent}
					className={linkClassName}>
					Cookie Preferences
				</a>
			</nav>
		</footer>
	);
};

export default Footer;
