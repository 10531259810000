import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router';

function Breadcrumbs({ items }) {
	return (
		<Breadcrumb className="mb-4">
			{items?.map((item, index) => (
				<Breadcrumb.Item
					key={`crumbs-${index}`}
					//to={item.link}
					linkAs={Link}
					linkProps={{
						to: item.link,
						className: 'text-muted small',
					}}>
					{item.title}
				</Breadcrumb.Item>
			))}
		</Breadcrumb>
	);
}

export default Breadcrumbs;
