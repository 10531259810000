import { getToken } from '@contexts/Token.context';
import { fileApiUrl } from '@utils/api';
import { Tile as TileLayer } from 'ol/layer';
import XYZ from 'ol/source/XYZ';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

/**
 * Loads a tile image with retry mechanism
 * @param {import('ol/ImageTile').default} imageTile - The OpenLayers image tile object
 * @param {string} src - The source URL for the tile
 * @returns {Promise<void>}
 */
const loadTile = async (imageTile, src) => {
	if (!src.includes(fileApiUrl)) {
		return (imageTile.getImage().src = src);
	}

	let attempts = 0;
	let lastError;

	while (attempts < MAX_RETRIES) {
		try {
			const token = getToken();
			const response = await fetch(src, {
				method: 'GET',
				headers: {
					authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok || response.status === 204) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const signedUrl = await response.json();
			imageTile.getImage().src = signedUrl.url;
			return;
		} catch (error) {
			lastError = error;
			attempts++;

			if (attempts === MAX_RETRIES) {
				console.error(
					'Failed to load tile after all retries:',
					lastError
				);
				return;
			}

			console.warn(`Tile load attempt ${attempts} failed, retrying...`);
			await new Promise(resolve =>
				setTimeout(resolve, RETRY_DELAY * attempts)
			);
		}
	}
};

export const makePhotoLayer = ({
	name,
	zIndex,
	defaultProjection,
	url,
	extent,
	maxZoomLevel = 22,
	visible,
	layerId,
}) => {
	return new TileLayer({
		preload: Infinity,
		name: name,
		defaultProjection: defaultProjection,
		extent: extent,
		visible: visible,
		zIndex: zIndex,
		properties: {
			customLayerId: layerId,
		},
		source: new XYZ({
			tileLoadFunction: loadTile,
			url: url,
			tileSize: [256, 256],
			title: 'ortho',
			attributions: '',
			minZoom: 10,
			maxZoom: maxZoomLevel,
			crossOrigin: 'anonymous',
		}),
	});
};
