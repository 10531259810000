import LabelWithPopover from '@components/popover/LabelWithPopover';

const DetailsList = ({ items }) => {
	if (!items || !items.length) {
		return null;
	}

	return (
		<ul className="list-group">
			{items.map(({ key, label, value, helperText }) => {
				if (!value && value !== 0) {
					return null; // Do not render if value is null
				}
				return (
					<DetailListItem
						key={key}
						label={label}
						value={value}
						helperText={helperText}
					/>
				);
			})}
		</ul>
	);
};
export default DetailsList;

const DetailListItem = ({ label, value, helperText }) => {
	return (
		<li className="list-group-item d-flex justify-content-between align-items-center">
			<LabelWithPopover
				label={label}
				title={label}
				content={helperText}
				className="text-capitalize"
			/>
			<span>{value}</span>
		</li>
	);
};
