import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const PopoverComponent = ({
	title,
	content,
	placement = 'right',
	children,
	...props
}) => {
	return (
		<OverlayTrigger
			placement={placement}
			overlay={
				<Popover>
					<Popover.Header as="h3">{title}</Popover.Header>
					<Popover.Body>{content}</Popover.Body>
				</Popover>
			}
			{...props}>
			{children}
		</OverlayTrigger>
	);
};
export default PopoverComponent;
