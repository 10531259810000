import Select from '@components/form/Select';

/**
 * ProjectTagsSelect component renders a select input for project tags.
 *
 * @param {Object} props The component props.
 * @param {string} [props.label='Project Tags'] The label for the select input.
 * @param {Object} props.field The field object from react-hook-form <Controller /> to spread into the select input.
 * @param {Object} props.userTags The user tags object containing tags and allowCreate flag.
 * @param {Array} [props.defaultTags=[]] The default tags to exclude from the options.
 *
 * @returns {JSX.Element} The rendered select input component.
 */
const ProjectTagsSelect = ({
	label = 'Project Tags',
	field,
	userTags,
	defaultTags = [],
}) => {
	const { tags, allowCreate } = userTags;

	// Filter out default tags as an option because they are added as a value.
	const options = tags
		?.filter(tag => !defaultTags.includes(tag))
		.map(tag => ({
			value: tag,
			label: tag,
		}));

	return (
		<Select
			{...field}
			id="project-tags-modal-select"
			label={label}
			options={options}
			noOptionsMessage={() =>
				allowCreate ? 'Start typing to add a tag' : 'No tags found'
			}
			formatCreateLabel={inputValue =>
				`Click to create new tag "${inputValue}"`
			}
			isMulti
			creatable={allowCreate}
		/>
	);
};

export default ProjectTagsSelect;
