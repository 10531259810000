import { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';

import { useNewProject } from '@contexts/NewProject.context';
import { humanFileSize } from '@utils/files';

const FileItem = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	column-gap: 1rem;

	padding-left: 1rem;
	border-radius: 0.175rem;
	margin-bottom: 0.35rem;

	border-bottom: 1px solid #414141;
	background-color: ${({ $removingFile, theme }) =>
		$removingFile
			? 'rgba(230, 57, 70, 0.5)'
			: `${theme.colors.backgroundAccent}`};
	transition: all 0.2s ease-out;
	&:last-child {
		border-bottom: none;
	}

	& > div {
		display: flex;
		justify-content: space-between;

		& > p {
			margin: 0;
		}
	}

	& > button {
		appearance: none;
		background-color: transparent;
		border: 0;

		display: inline-flex;
		justify-content: center;
		align-items: center;

		border-top-right-radius: 0.175rem;
		border-bottom-right-radius: 0.175rem;
		padding: 0.75rem !important;

		transition: all 0.2s ease-out;

		&:hover {
			background-color: rgba(230, 57, 70, 0.5);
			cursor: pointer;
		}

		&:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}

		& > svg {
			font-size: 1.25rem;
		}
	}
`;
/**
 * Component for displaying a file in the file list
 *
 * @param {*} file - file object
 * @param {number} index - index of the file in the list
 */
const FileItemComponent = ({ file, index }) => {
	const { files, setFiles, isSubmitting } = useNewProject();
	const [removingFile, setRemovingFile] = useState(false);

	const removeFile = fileToRemove => {
		if (removingFile) return;
		setRemovingFile(true);

		setTimeout(() => {
			setFiles(files.filter(file => file !== fileToRemove));
		}, 300);
	};

	return (
		<FileItem $removingFile={removingFile}>
			<div>
				<p>{`${index + 1}. ${file.name}`}</p>
				<p>{humanFileSize(file.size)}</p>
			</div>
			<button
				type="button"
				onClick={() => removeFile(file)}
				title="Remove this file"
				disabled={isSubmitting}>
				<IoClose />
			</button>
		</FileItem>
	);
};
export default FileItemComponent;
