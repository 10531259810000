import styled from 'styled-components';

const Section = styled.section`
	&:not(&:last-of-type) {
		border-bottom: 1px solid #494949;
		margin-bottom: 3rem;
		padding-bottom: 3rem;
	}
`;

const StyledStep = styled.h4`
	transition: 1s;
	color: ${p => p.theme.colors.green};
	margin-block: 1.5rem;

	${({ $active }) => !$active && `opacity: 0.5; filter: grayscale(.1);`}
`;

const Header = styled.span`
	color: ${p => p.theme.colors.textAccent};
	font-weight: 300;
`;

const Step = ({ step, header, children, active = true }) => (
	<Section>
		<StyledStep $active={active}>
			Step {step}: <Header>{header}</Header>
		</StyledStep>

		{active && children}
	</Section>
);

export default Step;
