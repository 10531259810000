import GeoJSON from 'ol/format/GeoJSON';
import { Point } from 'ol/geom';
import { Feature } from 'ol/index';
import VectorSource from 'ol/source/Vector';
import WebGLVectorLayer from 'ol/layer/WebGLVector';

import {
	trimInvisibleFeatures,
	getWebGLFillColor,
	getWebGLRadius,
	deleteLayerByCustomId,
} from '@utils/map/helpers';

const MAX_RADIUS = 5;
const MIN_RADIUS = 1.5;
const FALLBACK_COLOR = 'rgba(255, 0, 0, 1)';

const getFeatures = features =>
	features.map(({ geometry, properties }) => {
		const { classid, classname, confidence } = properties ?? {};
		const { coordinates } = geometry ?? {};

		return new Feature({
			featureType: 'detection',
			data: properties,
			classId: `${classid}`,
			className: classname,
			confidence: confidence,
			geometry: new Point(coordinates),
		});
	});

const getFormat = defaultProjection =>
	new GeoJSON({
		defaultDataProjection: defaultProjection,
		defaultFeatureProjection: defaultProjection,
		dataProjection: defaultProjection,
		featureProjection: defaultProjection,
	});

const getSource = (features, colorScheme, defaultProjection) =>
	new VectorSource({
		features: getFeatures(features, colorScheme),
		format: getFormat(defaultProjection),
	});

const createStyle = colorScheme => ({
	'circle-radius': getWebGLRadius(MIN_RADIUS, MAX_RADIUS),
	'circle-opacity': 1,
	'circle-displacement': [0, 0],
	'circle-fill-color': getWebGLFillColor(colorScheme, FALLBACK_COLOR),
});

const getLayer = (source, style, layerId) =>
	new WebGLVectorLayer({
		source,
		style,

		name: 'Detection results',
		renderBuffer: MAX_RADIUS * 2,
		zIndex: 11,

		properties: {
			customLayerId: layerId,
		},
	});

export const makePointLayer = ({
	mapObject,
	defaultProjection,
	features,
	colorScheme,
	layerId,
}) => {
	if (!features?.length) return null;

	deleteLayerByCustomId(mapObject, layerId);

	const visibleFeatures = trimInvisibleFeatures(features, colorScheme);
	const source = getSource(visibleFeatures, colorScheme, defaultProjection);
	const style = createStyle(colorScheme);
	const pointLayer = getLayer(source, style, layerId);
	mapObject.addLayer(pointLayer);

	return pointLayer;
};
