import Overlay from 'ol/Overlay.js';

/**
 * Creates a new tooltip overlay
 */
export const createTooltip = ({
	mapRef,
	tooltipRef,
	offset = [0, -15],
	positioning = 'bottom-center',
	id,
}) => {
	if (!mapRef || !tooltipRef || !id) {
		console.warn('Map, tooltip reference and id is required');
		return;
	}

	const tooltip = new Overlay({
		element: tooltipRef,
		offset: [...offset],
		positioning: positioning,
		stopEvent: false,
		insertFirst: false,
		id,
	});
	mapRef.addOverlay(tooltip);

	return tooltip;
};

export const clearTooltip = ({ tooltip, tooltipRef }) => {
	if (!tooltip) {
		console.warn('Tooltip reference is required');
		return;
	}

	tooltip.setPosition(undefined);
	tooltipRef.innerHTML = '';
};
