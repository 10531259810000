import { useMemo } from 'react';

import { useModelContext } from '@contexts/Model.context';
import { Card } from 'react-bootstrap';
import { Chart as ChartJs } from 'react-chartjs-2';

const colors = {
	red: 'rgb(255, 99, 132)',
	orange: 'rgb(255, 159, 64)',
	yellow: 'rgb(255, 205, 86)',
	gray: 'rgba(205, 208, 205,0.3)',
	blue: 'rgb(53, 162, 235)',
	purple: 'rgb(153, 102, 255)',
	white: 'rgb(207, 207, 207)',
	grid: 'rgba(255, 255, 255, 0.1)',
};

const options = {
	responsive: true,
	interaction: {
		mode: 'index',
		intersect: false,
	},
	stacked: false,
	scales: {
		yResults: {
			type: 'linear',
			display: true,
			position: 'left',
			beginAtZero: false,
			grid: {
				color: colors.grid,
			},
			ticks: {
				color: colors.white,
			},
		},
		yAnnotations: {
			type: 'linear',
			display: false,
		},
		x: {
			grid: {
				color: colors.grid,
			},
			ticks: {
				color: colors.white,
			},
		},
	},
	plugins: {
		legend: {
			position: 'top',
			labels: {
				boxWidth: 20,
				color: colors.white,
			},
		},
	},
};

export default function Chart() {
	const { trainings } = useModelContext();

	const [labels, number_of_annotations, evaluation_results] = useMemo(() => {
		if (!trainings || trainings?.length < 2) {
			return [[], [], []];
		}
		return trainings?.reduce(
			([labels, number_of_annotations, evaluation_results], training) => {
				const {
					evaluation_result,
					training_id,
					number_of_annotations: annotations,
				} = training || {};

				if (evaluation_result) {
					labels.push(`Model v. ${training_id.toString()}`);
					number_of_annotations.push(annotations);
					evaluation_results.push(evaluation_result);
				}
				return [labels, number_of_annotations, evaluation_results];
			},
			[[], [], []]
		);
	}, [trainings]);

	const [precision, recall, f1_score] = useMemo(() => {
		if (!evaluation_results || evaluation_results.length < 2) {
			[[], [], []];
		}

		return evaluation_results?.reduce(
			([precisions, recalls, f1_scores], res) => {
				if (res) {
					precisions.push(res.precision);
					recalls.push(res.recall);
					f1_scores.push(res.f1_score);
				}
				return [precisions, recalls, f1_scores];
			},
			[[], [], []]
		);
	}, [evaluation_results]);

	// If there is no data to show, return null
	if (labels.length < 2) {
		return null;
	}

	const data = {
		labels,
		datasets: [
			{
				type: 'line',
				label: 'Precision',
				data: precision,
				borderColor: colors.red,
				backgroundColor: colors.red,
				yAxisID: 'yResults',
			},
			{
				type: 'line',
				label: 'Recall',
				data: recall,
				borderColor: colors.blue,
				backgroundColor: colors.blue,
				yAxisID: 'yResults',
			},
			{
				type: 'line',
				label: 'F1 Score',
				data: f1_score,
				borderColor: colors.orange,
				backgroundColor: colors.orange,
				yAxisID: 'yResults',
			},
			{
				type: 'bar',
				label: 'Number of annotations',
				data: number_of_annotations,
				borderColor: colors.gray,
				backgroundColor: colors.gray,
				yAxisID: 'yAnnotations',
				maxBarThickness: 20,
			},
		],
	};

	return (
		<Card body>
			<Card.Title>Evaluations</Card.Title>

			<ChartJs type="bar" data={data} options={options} />
			<p className="small text-muted mt-4 mb-0">
				Only evaluated versions of the model is visualized here.
			</p>
		</Card>
	);
}

// const mockData = {
// 	labels: Array.from({ length: 35 }, (_, i) => `Model v. ${i + 1}`),
// 	precision: Array.from({ length: 35 }, (_, i) => 0.5 + i * 0.03),
// 	recall: Array.from({ length: 35 }, (_, i) => 0.6 + i * 0.02),
// 	f1_score: Array.from({ length: 35 }, (_, i) => 0.7 + i * 0.02),
// 	number_of_annotations: Array.from({ length: 35 }, (_, i) => (i + 1) * 100),
// };

// const mock = {
// 	labels: mockData.labels,
// 	datasets: [
// 		{
// 			type: 'line',
// 			label: 'Precision',
// 			data: mockData.precision,
// 			borderColor: colors.red,
// 			backgroundColor: colors.red,
// 			yAxisID: 'yResults',
// 		},
// 		{
// 			type: 'line',
// 			label: 'Recall',
// 			data: mockData.recall,
// 			borderColor: colors.blue,
// 			backgroundColor: colors.blue,
// 			yAxisID: 'yResults',
// 		},
// 		{
// 			type: 'line',
// 			label: 'F1 Score',
// 			data: mockData.f1_score,
// 			borderColor: colors.orange,
// 			backgroundColor: colors.orange,
// 			yAxisID: 'yResults',
// 		},
// 		{
// 			type: 'bar',
// 			label: 'Number of annotations',
// 			data: mockData.number_of_annotations,
// 			borderColor: colors.gray,
// 			backgroundColor: colors.gray,
// 			yAxisID: 'yAnnotations',
// 			maxBarThickness: 20,
// 		},
// 	],
// };
