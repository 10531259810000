import styled from 'styled-components';

const Badge = styled.span`
	display: inline-block;
	font-size: 0.7rem;
	opacity: 0.8;
	text-transform: uppercase;
	background-color: ${p => p.theme.colors.backgroundAccent};
	padding: 0.1em 0.5em;
	margin-bottom: 1em;
`;

export default Badge;
