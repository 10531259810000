import { useQueries } from '@tanstack/react-query';

import LabelWithPopover from '@components/popover/LabelWithPopover';
import ImageLightbox from '@components/imageLightbox/ImageLightbox';

import { fetchUrl } from '@api';

// Utility function to add a delay
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

// Function to fetch URL with a delay
const fetchWithDelay = async (path, index) => {
	await delay(index * 100); // delay each request by 100ms to prevent server overload
	return fetchUrl(`filelink?key=${path}`);
};

const TrainingImages = ({ evaluation_result }) => {
	const {
		list_of_example_tile_names_thumbnails: thumbnailPaths,
		list_of_example_tile_names: imagePaths,
	} = evaluation_result || [];

	if (!thumbnailPaths?.length || !imagePaths?.length) return null;

	// Fetch thumbnail data
	const thumbnailQueries = thumbnailPaths.map((path, index) => ({
		queryKey: ['evaluation-thumbnail', path],
		queryFn: () => fetchWithDelay(path, index),
	}));

	const { data: thumbnailsData, pending: thumbnailsPending } = useQueries({
		queries: thumbnailQueries,
		combine: results => {
			return {
				data: results.map(result => result.data),
				pending: results.some(result => result.isPending),
			};
		},
	});

	// Fetch image data
	const imageQueries = imagePaths.map((path, index) => ({
		queryKey: ['evaluation-image', path],
		queryFn: () => fetchWithDelay(path, index),
	}));

	const { data: imagesData, pending: imagesPending } = useQueries({
		queries: imageQueries,
		combine: results => {
			return {
				data: results.map(result => result.data),
				pending: results.some(result => result.isPending),
			};
		},
	});

	// Check if data is still loading
	if (thumbnailsPending || imagesPending) {
		return null;
	}

	// Filter and map thumbnail URLs
	const thumbnails = thumbnailsData
		?.filter(image => image?.code === 200)
		.map(image => image?.url);

	// Filter and map image URLs
	const images = imagesData
		?.filter(image => image?.code === 200)
		.map(image => image?.url);

	// Check if there are valid thumbnails and images
	if (!thumbnails?.length || !images?.length) {
		return null;
	}

	return (
		<div>
			<LabelWithPopover
				label="Sample training images"
				className="text-muted small"
				content="Randomly selected images from the training dataset."
			/>
			<ImageLightbox images={images} thumbnails={thumbnails} />
		</div>
	);
};
export default TrainingImages;
