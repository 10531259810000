import { Map, View } from 'ol/index';
import { get as getProjection } from 'ol/proj.js';
import { Tile as TileLayer } from 'ol/layer';
import { OSM } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Vector as VectorLayer } from 'ol/layer';
import { defaults as interactionDefaults } from 'ol/interaction';

/**
 * Creates and returns a new OpenLayers View object.
 *
 * @returns {View} A new View instance with the specified center, zoom, maxZoom, and projection.
 */
const getView = () =>
	new View({
		center: [0, 0],
		zoom: 0,
		maxZoom: 50,
		projection: getProjection('EPSG:3857'),
	});

/**
 * Creates a new map layer with the specified name and zIndex.
 *
 * @param {string} name - The name of the map layer.
 * @param {number} zIndex - The zIndex of the map layer.
 * @returns {TileLayer} A new TileLayer instance.
 */
const getMapLayer = (name, zIndex) =>
	new TileLayer({
		source: new OSM(),
		name: name,
		zIndex: zIndex,
	});

/**
 * Creates a new vector layer with the specified name and zIndex.
 *
 * @param {string} name - The name of the vector layer.
 * @param {number} zIndex - The zIndex of the vector layer.
 * @returns {VectorLayer} A new vector layer instance.
 */
const getVectorLayer = (name, zIndex) =>
	new VectorLayer({
		source: new VectorSource(),
		name: name,
		zIndex: zIndex,
		style: feature => feature.get('style'),
	});

/**
 * Creates and returns a map object with specified layers and configurations.
 *
 * @param {HTMLElement} mapElement - The HTML element to which the map will be rendered.
 * @returns {Map} - A new map instance with the specified view, layers, and configurations.
 */
export const getMap = mapElement => {
	const view = getView();
	const mapLayer = getMapLayer('OSM Layer', 0);
	const vectorLayer = getVectorLayer('Footprint Polygon Layer', 2);

	return new Map({
		target: mapElement,
		view: view,
		layers: [mapLayer, vectorLayer],
		interactions: interactionDefaults({
			doubleClickZoom: true,
			keyboardZoom: false,
			mouseWheelZoom: false,
		}),
		loadTilesWhileAnimating: true,
		loadTilesWhileInteracting: true,
	});
};
